import React, { useState } from 'react'
import { ContainerTags } from './styled'
import { Inline } from '../inline'
import { Render } from '../render'
import { Tooltip } from 'antd'
import Icons from '../../Icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

interface PropsTagsAdd {
  tags: string[]
  onTagsChange: (tags: string[]) => void
  label: string
  placeholder: string
  descrição?: boolean
  textoDescrição?: string
}

const TagInput: React.FC<PropsTagsAdd> = ({
  tags,
  onTagsChange,
  label,
  placeholder,
  descrição,
  textoDescrição
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  const addTag = (tag: string) => {
    if (tag && !tags.includes(tag)) {
      onTagsChange([...tags, tag])
    }
  }

  const removeTag = (tagToRemove: string) => {
    onTagsChange(tags.filter((tag) => tag !== tagToRemove))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key
    const tag = inputValue.trim()

    if (key === 'Enter' || key === ',' || key === ';') {
      event.preventDefault()
      if (tag) {
        addTag(tag)
        setInputValue('')
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <ContainerTags>
     <Inline>
     <label>{label}</label>
      <Render condition={descrição}>
        <Tooltip trigger='hover' title={textoDescrição}>
          <div style={{ cursor: 'pointer' }}>
            <Icons color='#507fff' icon={faCircleInfo} />
          </div>
        </Tooltip>
      </Render>
     </Inline>
      <div className='box__tags'>
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          {tags.map((tag, index) => (
            <div
              key={index}
              className='tags__adicionadas'
              onClick={() => removeTag(tag)}
            >
              {tag}
              <span style={{ marginLeft: '5px', cursor: 'pointer' }}>×</span>
            </div>
          ))}
          <input
          className='input__add__tags'
            type='text'
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        </div>
      </div>
    </ContainerTags>
  )
}

export default TagInput
