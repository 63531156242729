import { orderBy } from "lodash";
import { TypeListaEngrenagem } from "../../../../../../..";
import Item from "antd/es/list/Item";

function codPosicao(titulo: 'Recâmbio' | 'Lateral de entrada' | 'Lateral de Saída (1)' | 'Lateral de Saída (2)') {
    switch (titulo) {
        case 'Lateral de entrada':
            return 1
        case 'Lateral de Saída (1)':
            return 2
        case 'Lateral de Saída (2)':
            return 3
        case 'Recâmbio':
            return 4
        default:
            return 0;
    }
};
function codCategoria(categoria: 'Semente' | 'Fertilizante' | 'Geral',) {
    if (categoria === 'Geral') return 1;
    return categoria==='Semente' ? 2 : 3;
}

export function AddEngrenagem(
    titulo: 'Recâmbio' | 'Lateral de entrada' | 'Lateral de Saída (1)' | 'Lateral de Saída (2)',
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    categoria: 'Semente' | 'Fertilizante' | 'Geral',
    tipo: 'Motriz' | 'Movida',
    qtd_dentes: number,
    disabled?: boolean
) {
    if (disabled) {
        return;
    }
    setLista_engrenagens(lista_engrenagens=> [...lista_engrenagens, {
        cod: Date.now(),
        posicao: codPosicao(titulo),
        tipo: tipo==='Motriz' ? 1 : 2,
        categoria: codCategoria(categoria),
        quantidade_dentes: qtd_dentes,
        nome_posicao: titulo,
        nome_tipo: tipo,
        nome_categoria: categoria,
        is_new: true
    }])
}

export function editarEngrenagem(
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    cod_tem: number,
    nova_quantidade_dentes: number | string
) {
    let editado = lista_engrenagens.map(item=> {
        return {
            ...item,
            quantidade_dentes: item.cod === cod_tem ? Number(nova_quantidade_dentes) : item.quantidade_dentes
        }
    });

    setLista_engrenagens(editado);
}

export function removerEngrenagem(
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    cod_tem: number,
) {
    
    let remover = lista_engrenagens.filter(item=> item.cod !== cod_tem);

    setLista_engrenagens(remover);
}

export function ordenar(lista_engrenagens: TypeListaEngrenagem[]): TypeListaEngrenagem[]  {

    // const remover_repetidos = lista_engrenagens.filter( (item, index, self) =>
    //       index === self.findIndex((sub_item) => sub_item.quantidade_dentes === item.quantidade_dentes)
    // );

    return orderBy(lista_engrenagens, 'quantidade_dentes', ['desc'])
}

export function verificaSeEngrenagemJaExiste(
    lista_engrenagens: TypeListaEngrenagem[],
    tipo: 'Motriz' | 'Movida',
    qtd_dentes: number,
    setEngrenagem_ja_existe: Function
) {
    let filtrar_por_tipo = lista_engrenagens.filter(item=>item.nome_tipo === tipo);
    let encontrado = filtrar_por_tipo.find(item=> item.quantidade_dentes === qtd_dentes);

    setEngrenagem_ja_existe(encontrado !== undefined);
}