import styled from 'styled-components'

export const BodyDrawerCentro = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .button__footer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-top: thin solid var(--border-color);
    position: absolute;
    bottom: 0;
  }
`
