import styled from "styled-components";
import { leadZero } from "../../../../../utils";
import { Tooltip } from "antd";
import { TalhaoItens } from "../../../../../services/Talhao";
import { paleta_cores } from "../../meta";

//STYLES
const TabelaProp = styled.div`
    background: white;
    z-index: 2;
    border-radius: 8px;
    box-sizing: border-box;

    div.TabelaProp__header {
        display: flex;
        height: 25px;

        div.TabelaProp__header__item {
            width: 33%;
            height: 100%;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            color: var(--text-color);
        }
        div.TabelaProp__header__item.prop {
                width: 50%;
        }
        div.TabelaProp__header__item.area {
            width: 30%;
        }
        div.TabelaProp__header__item.talhao {
            width: 20%;
        }
    }

    div.TabelaProp__container {
        width: 100%;
        max-height: calc(100vh - 485px);
        overflow-y: auto;

        div.TabelaProp__container__row {
            width: 100%;
            height: 35px;
            display: flex;
            font-weight: 500;
            font-size: 12px;
            color: var(--text-color);

            .TabelaProp__container__row__item {
                height: 100%;
                align-items: center;
                box-sizing: border-box;
                padding: 10px 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .TabelaProp__container__row__item__color {
                    width: 10px;
                    min-width: 10px;
                    height: 10px;
                    min-height: 10px;
                    border-radius: 50%;
                    display: inline-block; /* Alinha os círculos horizontalmente */
                    vertical-align: middle;
                }
            }
            /* :hover {
                background-color: var(--hover-color);
            } */
            .TabelaProp__container__row__item.prop {
                width: 50%;
                text-transform: capitalize;
                padding-left: 0px;
            }
            .TabelaProp__container__row__item.area {
                width: 30%;
                padding-left: 0px;
            }
            .TabelaProp__container__row__item.area:is([data-padding = true]) {
                padding-left: 3px;
            }
            .TabelaProp__container__row__item.talhao {
                width: 20%;
                padding-left: 0px;
            }
            .TabelaProp__container__row__item.talhao:is([data-padding = true]) {
                padding-left: 6px;
            }
        }
    }
`;

//PROPS
interface TypesVisuPropri {
    name?: string
    cod?: number
    cor?: string
    areaPropriedade?: number
    talhoes?: TalhaoItens[]
}

interface Props {
    dados_propriedades: TypesVisuPropri[],
    distinguir_cor?: boolean;
}

export const TabelaTodasPropiedades: React.FC<Props> = ({ dados_propriedades, distinguir_cor }) => {
    return (
        <TabelaProp>
            <div className="TabelaProp__header">
                <div className="TabelaProp__header__item prop">
                    Propriedade
                </div>
                <div className="TabelaProp__header__item area">
                    Área
                </div>
                <div className="TabelaProp__header__item talhao">
                    Talhões
                </div>
            </div>
            <div className="TabelaProp__container">
                {(dados_propriedades ?? []).map(({ name, areaPropriedade, talhoes, cod }, index)=> {
                    return (
                        <div className="TabelaProp__container__row" key={cod}>

                            <Tooltip title={name} mouseEnterDelay={1}>
                                <div className="TabelaProp__container__row__item prop" data-padding={dados_propriedades?.length > 11}>
                                    {distinguir_cor &&(
                                        <div className="TabelaProp__container__row__item__color" style={{background: paleta_cores?.[index]}}/>
                                    )}
                                    <span style={{marginLeft: 3}}>{String(name)?.toLowerCase()}</span>
                                </div>
                            </Tooltip>

                            <Tooltip title={`${areaPropriedade} ha`} mouseEnterDelay={1}>
                                <div className="TabelaProp__container__row__item area" data-padding={dados_propriedades?.length > 11}>
                                {areaPropriedade} ha
                                </div>
                            </Tooltip>

                            <Tooltip title={String(leadZero(talhoes?.length ?? 0))} mouseEnterDelay={1}>
                                <div className="TabelaProp__container__row__item talhao" data-padding={dados_propriedades?.length > 11}>
                                    {String(leadZero(talhoes?.length ?? 0))}
                                </div>
                            </Tooltip>
                                    
                        </div>
                    )
                })}
            </div>
        </TabelaProp>
    )
}