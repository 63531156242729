import { useEffect } from "react";
import styled from "styled-components";
import Svg from "../../../../talhasvg";

//STYLES
const Item = styled.button`
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
    gap: 10px;
    border: none;
    text-align: left;

    :hover {
        background-color: var(--hover-color);
    }
    :focus {
        background-color: var(--hover-color);
    }
    :is([data-focused = true]) {
        background-color: var(--hover-color);
    }
    :disabled {
        background-color: var(--disabled-color);
        color: var(--disabled-text-color);
        cursor: not-allowed;
    }

    div.Item__img_placeholder {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

//PROPS
interface Props {
    children?: React.ReactNode;
    index?: number;
    focused: boolean;
    onClick: Function;
    disabled?: boolean;
    iconSVGSize?: number;
    iconSVG?: [string | number, string | number];
}

export const ItemList: React.FC<Props> = ({ children, focused, index, onClick, disabled, 
    iconSVGSize, iconSVG
 }) => {

    useEffect(() => {
        if (focused) {
            let elemento = document.getElementById(`item_${index}`);
            elemento.focus()
        }
    }, [focused])
    

    return (
        <Item data-focused={focused} id={`item_${index}`} onClick={()=>onClick()} disabled={disabled} >

            {!!iconSVG &&(
                <div className="Item__img_placeholder" style={{width:iconSVGSize,height:iconSVGSize}}>
                    {/* {icon} */}
                    <Svg fill='var(--primary-color, green)' coordinates={iconSVG} />
                </div>
            )}
            {children}
        </Item>
    )
}