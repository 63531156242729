import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm } from "antd";
import { faTrash, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { removerEngrenagem } from "../../meta";
import { TypeListaEngrenagem } from "../../../../../../../..";
import { useDeleteEngrenagem } from "../../../../../../../../../../../services/Patrimonio/DELETE";

//COMPONENTS
import ButtonCustom from "../../../../../../../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../../../../../../../Components/CustomUi/inline";

//PROPS
interface Props {
    cod_item: number;
    open_deletar: boolean;
    setOpen_deletar: React.Dispatch<React.SetStateAction<boolean>>;
    item_selecionado: number,
    setItem_selecionado: React.Dispatch<React.SetStateAction<number>>;
    lista_engrenagens_completa: TypeListaEngrenagem[],
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    quantidade_atual: string;
    is_new: boolean;
}

export const PopOverDeletar: React.FC<Props> = ({ cod_item, open_deletar, setOpen_deletar, is_new,
    item_selecionado, setItem_selecionado, lista_engrenagens_completa, setLista_engrenagens, quantidade_atual }) => {

    const { carregando_deletar_engrenagem, mudateDeletarEngrenagem } = useDeleteEngrenagem();

    return (
        <Popconfirm
            destroyTooltipOnHide
            placement="topRight"
            title={`Deletar`}
            open={open_deletar && (cod_item === item_selecionado)}
            description={
                <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

                    <span>Deletar engrenagem de <b>{quantidade_atual}</b> dentes?</span>

                    <Inline justifyContent="flex-end">

                        <ButtonCustom
                            onClick={() => { setOpen_deletar(false) }}
                            type="tertiary"
                            icon={<FontAwesomeIcon icon={faXmark} />}
                            danger
                            disabled={carregando_deletar_engrenagem}
                        >
                            Cancelar
                        </ButtonCustom>

                        <ButtonCustom
                            value="Deletar"
                            onClick={is_new ? () => {
                                removerEngrenagem(
                                    lista_engrenagens_completa,
                                    setLista_engrenagens,
                                    cod_item
                                );
                                setOpen_deletar(false);
                            }
                            : ()=> {
                                mudateDeletarEngrenagem({
                                    cod_engrenagem: cod_item,
                                    removerDaLista: removerEngrenagem,
                                    lista_engrenagens: lista_engrenagens_completa,
                                    setLista_engrenagens: setLista_engrenagens,
                                    setOpen_deletar: setOpen_deletar
                                });
                            }}
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            danger
                            loading={carregando_deletar_engrenagem}
                            disabled={carregando_deletar_engrenagem}
                        >
                            Deletar
                        </ButtonCustom>

                    </Inline>
                </div>}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Button style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}>
                <ButtonCustom
                    danger
                    type="tertiary"
                    onClick={() => { setItem_selecionado(cod_item); setOpen_deletar(true) }}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                />
            </Button>
        </Popconfirm>
    )
}