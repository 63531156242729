import { message } from 'antd'
import axios from 'axios'
import { useQuery } from 'react-query'

interface TypeEndereco {
  tipo_logradouro: string
  logradouro: string
  numero: string
  complemento: string
  bairro: string
  cep: string
  uf: string
  municipio: string
}

interface TypeCnae {
  codigo: number
  nome: string
}

interface TypeCnpj {
  cnpj: string
  razao_social: string
  natureza_juridica: string
  qualificacao_responsavel: string
  capital_social: number
  porte: string
  ente_federativo_responsavel: null
  nome_fantasia: null
  situacao_cadastral: string
  data_situacao_cadastral: string
  motivo_situacao_cadastral: string
  nome_da_cidade_no_exterior: null
  pais: null
  endereco: TypeEndereco
  data_inicio_atividade: string
  cnae_fiscal_principal: TypeCnae
  cnae_fiscal_secundaria: []
  telefone1: string
  telefone2: string
  telefone_fax: string
  email: string
  situacao_especial: null
  data_situacao_especial: null
  socios: []
}

interface TypeCnpjSecundario {
  data: [TypeCnpj]
}

const useGetCnpjSecundario = (cnpj: string, triggerError?: Function) => {
  const queryKey: any = [`dadosCnpjSecundario${cnpj}`]

  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Error>(
    queryKey,
    async () => {
      return axios
        .get(`https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`)
        .then((response): any => {
         if (response.data.hasOwnProperty('error')) {
          console.log('response', response)
          throw new Error('error');
         }else{
          triggerError?.(false)
          message.destroy()
          return response.data
         }
          
        })
        .catch(() => {
          message.destroy()
          setTimeout(() => {
            message.error({ content: 'Erro ao buscar CNPJ', duration: 5 })
          }, 500)
          triggerError?.(true)
        })
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_cnpj: data ?? undefined,
    carregando: isFetching,
    refetchSecundario: refetch,
    isFetched: isFetchedAfterMount,
  }
}

export const useGetCnpj = (cnpj: string, triggerError?: Function) => {
  const queryKey: any = [`dadosCnpj${cnpj}`]
  const { refetchSecundario } = useGetCnpjSecundario(cnpj)
  const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<TypeCnpjSecundario, Error>(
    queryKey,
    async () => {
      return axios
        .get(`https://api.cnpjs.dev/v1/${cnpj}`)
        .then((response): any => {
          triggerError?.(false)
          message.destroy()
          return response.data
        })
        .catch(() => {
          triggerError?.(true)
          return refetchSecundario()
        })
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  return {
    data_cnpj: data ?? undefined,
    carregando: isFetching,
    refetch: refetch,
    isFetched: isFetchedAfterMount,
  }
}