import { api } from '../../api';
import { message } from 'antd';
import { localConfig } from '../../../Configs/localConfig';
import { UseMutationResult, useMutation } from 'react-query';
import React from 'react';
import { TypeListaEngrenagem } from '../../../Views/app/patrimonioNovo';

//=================================================================================>
// DELETAR ENGRENAGEM DA PLANTADEIRA (PATRIMONIO) 
//<=================================================================================

interface ParamsDeleteEngrenagem {
    cod_engrenagem: number,
    removerDaLista: Function;
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    setOpen_deletar: React.Dispatch<React.SetStateAction<boolean>>,
}

export const useDeleteEngrenagem = (): {
    carregando_deletar_engrenagem: boolean;
    error_deletar_engrenagem: any,
    mudateDeletarEngrenagem: (params: ParamsDeleteEngrenagem) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteEngrenagem> = useMutation(
        async (params: ParamsDeleteEngrenagem) => {

            const { cod_engrenagem, removerDaLista, lista_engrenagens, setLista_engrenagens, setOpen_deletar } = params;
            
            const config = localConfig();

            // message.loading({ content: 'Excluindo...', key: 'loading' });

            return api.delete(`/patrimonio-engrenagem/${cod_engrenagem}?codConta=${config.conta}&codCliente=${config.codCliente}`)
                .then((response: any) => {
                    // message.success({ content: 'Talhão excluído com sucesso!', key: 'loading' });
                    removerDaLista(lista_engrenagens, setLista_engrenagens, cod_engrenagem);
                    setOpen_deletar(false);
                    return response;
                })
                .catch((error: Error) => {
                    //@ts-ignore
                    let error_response = error?.response?.data;
                    // message.destroy('loading');
                    message.error({ content: error_response?.error ?? 'Falha ao excluir engrenagem', key: 'loading' });
                    setOpen_deletar(false);

                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_engrenagem: mutation.isLoading, 
        //@ts-ignore
        error_deletar_engrenagem: mutation.error?.response?.data,
        mudateDeletarEngrenagem: mutation.mutate 
    };
};
