import { SelectMultiWrapper } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { gerarKey, removerAcentos, removerEspacos } from "../../../utils";
import { useEffect, useState, useRef } from "react";
import { ItemList } from "./components/item";
import { Empty } from "antd";
import Carregando from "../loading";

//PROPS
interface Props {
    label?: string;
    placeholder?: string;
    icon?: any;
    open: boolean;
    trigger: React.Dispatch<React.SetStateAction<boolean>>;
    children?: React.ReactNode;
    maxHeight?: number | string;
    items: any[];
    descricaoItem: string;
    idItem: string;
    selecteds: {descr: string, id: number, key?: number}[];
    removeItens?: any[];
    onChange: React.Dispatch<React.SetStateAction<{descr: string, id: number, key?: number}[]>>;
    disabled?: boolean;
    refProp?: any;
    keyProp?: string | number;
    loading?: boolean;
    disabledItens?: any[];
    obrigatorio?: boolean;
    opcional?: boolean;
    hasTalhaoSVG?: boolean;
    /**Tamanho do SVG em `px` ([default: `30`]) */
    iconSVGSize?: number;
    talhaoSvgPath?: string[];
}

export const SelectMulti: React.FC<Props> = ({ label, placeholder, open, trigger, onChange, maxHeight, 
    items, descricaoItem, icon, selecteds, idItem, removeItens, disabled, refProp, keyProp, loading, 
    disabledItens, opcional, hasTalhaoSVG, talhaoSvgPath, iconSVGSize, obrigatorio }) => {

    //REF
    const refSelect = useRef<any>(null);

    //STATES
    const [indexSelecionado, setIndexSelecionado] = useState<number>(-1);
    const [digitando_estadio, setDigitando_estadio] = useState<string>('');

    const handleKeyDown = (e) => {
        if (open) {
          if (e.key === 'ArrowDown') {
            setIndexSelecionado((prevIndex) => (prevIndex + 1) % items?.length);
          } else if (e.key === 'ArrowUp') {
            setIndexSelecionado((prevIndex) =>
              prevIndex === 0 ? items.length - 1 : prevIndex - 1
            );
          } else if (e.key === 'Enter') {
            trigger(false);
          }
        }
    };

    const handleClickOutside:any = (event: React.MouseEvent<HTMLButtonElement>) => {
        const refComponent = refProp ?? refSelect;

        if (refComponent.current && !refComponent.current.contains(event.target)) {
            trigger(false);
        }
    };

    function obterCaminhoSvg(item: any[]) {
        let caminho = !!talhaoSvgPath ? talhaoSvgPath : ['kml', 'coordenadas'];

        if (caminho.length === 1) {
            return item?.[caminho?.[0]]
        }
        if (caminho.length === 2) {
            return item?.[caminho?.[0]]?.[caminho?.[1]]
        }
        if (caminho.length === 3) {
            return item?.[caminho?.[0]]?.[caminho?.[1]]?.[caminho?.[2]]
        }
        if (caminho.length === 4) {
            return item?.[caminho?.[0]]?.[caminho?.[1]]?.[caminho?.[2]]?.[caminho?.[3]]
        }
        if (caminho.length === 5) {
            return item?.[caminho?.[0]]?.[caminho?.[1]]?.[caminho?.[2]]?.[caminho?.[3]]?.[caminho?.[4]]
        }

        return item?.['kml']?.['coordenadas']
    }
  
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }); 
    
    
    function encontrarAtivo(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome);
        return function(item) {
            const nomeSemAcentos = removerAcentos(String(item?.[descricaoItem]));
            return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome;
        }
    }
      

    function selecionarItem(descr: string, id: number, key: number) {
        onChange(selecteds=>[...selecteds, {
            descr: descr,
            id: id,
            key: key
        }]);
        setDigitando_estadio('');
    };

    function removerItem(id: number) {
        let remover = selecteds.filter((item)=> item.id !== id);
        onChange(remover)
    }

    function handleFocus() {
        let multi = document.getElementById(`selectMulti_${removerEspacos(placeholder)}`);
        multi?.focus();
    };

    useEffect(() => {
        if (open) {
            handleFocus()
        }
    }, [open, selecteds]);


   function ItemJaFoiSelecionado(idItem: number) {
        let itemJaFoiSelec = (selecteds ?? []).find(item => item?.id === idItem);
        let itemJafoiAdd = (removeItens  ?? []).find(item => item?.id === idItem);
        
        if (itemJafoiAdd) {
            return false;
        }

        return !itemJaFoiSelec;
   } 

    return (
        <SelectMultiWrapper ref={refProp ?? refSelect} data-disabled={disabled} key={keyProp ?? 'multi'}>

            {!!label && (
                <label htmlFor={`select_${removerEspacos(placeholder)}`}>{label} {obrigatorio ? <span className="SelectMultiWrapper__obrigatorio">(obrigatório)</span> : (opcional ? <span className="SelectMultiWrapper__obrigatorio">(opicional)</span> : null)}</label>
            )}

            <div className="SelectMultiWrapper__campo" role="none" tabIndex={undefined} onClick={()=>{handleFocus()}} onKeyUp={()=>{}} onKeyDown={()=>{}}>

                <FontAwesomeIcon icon={icon ?? faMagnifyingGlass} className="SelectMultiWrapper__campo__icon" />

                {(selecteds ?? []).map(({ descr, id }, index)=> {
                    return (
                        <div className="SelectMultiWrapper__campo__selecionados__item" key={gerarKey(index)}>
                            {String(descr)?.toLocaleLowerCase()}
                            <button className="__item__remover" onClick={()=>{removerItem(id)}}>
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    )
                })}

                <input  
                    id={`selectMulti_${removerEspacos(placeholder)}`}
                    className="SelectMultiWrapper__campo__input"
                    placeholder={placeholder}
                    onFocus={()=>{trigger(true)}}
                    onKeyDown={handleKeyDown}
                    value={digitando_estadio}
                    onChange={(e)=>{setDigitando_estadio(e.currentTarget.value)}}
                    autoComplete="off"
                    disabled={disabled}
                />

                <button disabled={disabled} className="SelectMultiWrapper__campo__arrow"><FontAwesomeIcon icon={faAngleDown} /></button>
            </div>

            <div className={`SelectMultiWrapper__lista`} data-open={open} style={{maxHeight: maxHeight}}>

                {(items.length===0 && !loading) &&(
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}  />
                )}

                {loading &&(
                    <div style={{boxSizing:'border-box',padding:'10px 20px'}}>
                        <Carregando 
                            legenda="buscando registros"
                            direction="row"
                            animation
                            size="small"
                        />
                    </div>
                )} 

                {(items?? []).filter(encontrarAtivo(digitando_estadio)).map((item, index)=> {
                    if (ItemJaFoiSelecionado(item?.[idItem])) {
                        return (
                            <ItemList 
                                key={item?.[idItem]} 
                                index={index} 
                                focused={index === indexSelecionado}
                                onClick={()=>{selecionarItem(item?.[descricaoItem], item?.[idItem], index)}}
                                disabled={disabledItens?.indexOf(Number(item?.[idItem])) > -1}
                                iconSVG={hasTalhaoSVG && obterCaminhoSvg(item)}
                                iconSVGSize={iconSVGSize}
                            >
                                {(item?.[descricaoItem])}
                            </ItemList>
                        )  
                    }
                })}
            </div>

        </SelectMultiWrapper>
    )
};