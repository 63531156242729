import { Collapse } from "antd";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

//STYLES
import { ContainerColunas, ContainerPlantadeira, Label } from "./style";

//APP
import { TypeListaEngrenagem } from "../../../..";

//COMPONENTS
import { Box } from "../../../../../../../Components/CustomUi/box";
import { TitleH3 } from "../../../../../../../Components/CustomUi/titleH3";
import { ColunaEngrenagens } from "./components/coluna";
import { listaLateralEntrada_01_Fertilizante, listaLateralEntrada_01_Semente, 
    listaLateralEntrada_02_Fertilizante, listaLateralEntrada_02_Semente, listaLateralEntradaPadrao, 
    listaRecambio_Fertilizante, listaRecambio_Semente } from "./meta";


//PROPS
interface Props {
  lista_engrenagens: TypeListaEngrenagem[];
  setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>;
  cod_patrimonio: number;
  setValidarEngrenagem: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SecaoPlantadeira: React.FC<Props> = ({ lista_engrenagens, setLista_engrenagens, cod_patrimonio, setValidarEngrenagem }) => {

    const [msg_movida_lat_entrada, setMsg_movida_lat_entrada] = useState<boolean>(false);
    const [msg_movida_semente_lat_saida_01, setMsg_movida_semente_lat_saida_01] = useState<boolean>(false);
    const [msg_movida_semente_lat_saida_02, setMsg_movida_semente_lat_saida_02] = useState<boolean>(false);
    const [msg_movida_semente_recambio, setMsg_movida_semente_recambio] = useState<boolean>(false);

    const [msg_movida_fertilizante_lat_saida_01, setMsg_movida_fertilizante_lat_saida_01] = useState<boolean>(false);
    const [msg_movida_fertilizante_lat_saida_02, setMsg_movida_fertilizante_lat_saida_02] = useState<boolean>(false);
    const [msg_movida_fertilizante_recambio, setMsg_movida_fertilizante_recambio] = useState<boolean>(false);

    function exibirAlerta(title: string) {
        if (title==='Geral') {
            return msg_movida_lat_entrada;
        }
        if (title==='Sementes') {
            return msg_movida_semente_lat_saida_01 || msg_movida_semente_lat_saida_02 || msg_movida_semente_recambio;
        }
        if (title==='Fertilizante') {
            return msg_movida_fertilizante_lat_saida_01 || msg_movida_fertilizante_lat_saida_02 || msg_movida_fertilizante_recambio;
        }
    }

    useEffect(() => {
        
        let tem_alerta = msg_movida_lat_entrada || msg_movida_semente_lat_saida_01 || msg_movida_semente_lat_saida_02 || 
        msg_movida_semente_recambio || msg_movida_fertilizante_lat_saida_01 || msg_movida_fertilizante_lat_saida_02 || 
        msg_movida_fertilizante_recambio;

        setValidarEngrenagem(tem_alerta);

    }, [msg_movida_lat_entrada, msg_movida_semente_lat_saida_01, msg_movida_semente_lat_saida_02, 
        msg_movida_semente_recambio, msg_movida_fertilizante_lat_saida_01, msg_movida_fertilizante_lat_saida_02, 
        msg_movida_fertilizante_recambio
    ])
    

    let lista_collapse = [
        {
            key: 'geral',
            label: <Label><FontAwesomeIcon icon={faWarning} data-hidden={exibirAlerta('Geral')} /> Geral </Label>,
            children: <ContainerColunas>
                <ColunaEngrenagens 
                    titulo="Lateral de entrada"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaLateralEntradaPadrao(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Geral"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_lat_entrada}
                    setNessecario_add_Movida={setMsg_movida_lat_entrada}
                />
            </ContainerColunas>,
            destroyInactivePanel: false,
            collapsible: undefined
        },
        {
            key: 'sementes',
            // label: <p style={{margin:0, display:'flex',justifyContent:'space-between'}}>Sementes</p>,
            label: <Label><FontAwesomeIcon icon={faWarning} data-hidden={exibirAlerta('Sementes')} /> Sementes </Label>,
            children: <ContainerColunas>
                <ColunaEngrenagens 
                    titulo="Recâmbio"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaRecambio_Semente(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Semente"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_semente_lat_saida_01}
                    setNessecario_add_Movida={setMsg_movida_semente_lat_saida_01}
                />
                <ColunaEngrenagens 
                    titulo="Lateral de Saída (1)"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaLateralEntrada_01_Semente(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Semente"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_semente_lat_saida_02}
                    setNessecario_add_Movida={setMsg_movida_semente_lat_saida_02}
                />
                <ColunaEngrenagens 
                    titulo="Lateral de Saída (2)"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaLateralEntrada_02_Semente(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Semente"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_semente_recambio}
                    setNessecario_add_Movida={setMsg_movida_semente_recambio}
                />
            </ContainerColunas>,
            destroyInactivePanel: false,
            collapsible: undefined
        },
        {
            key: 'fertilizantes',
            // label: <p style={{margin:0, display:'flex',justifyContent:'space-between'}}>Fertilizante</p>,
            label: <Label><FontAwesomeIcon icon={faWarning} data-hidden={exibirAlerta('Fertilizante')} /> Fertilizante </Label>,
            children: <ContainerColunas>
                <ColunaEngrenagens 
                    titulo="Recâmbio"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaRecambio_Fertilizante(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Fertilizante"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_fertilizante_lat_saida_01}
                    setNessecario_add_Movida={setMsg_movida_fertilizante_lat_saida_01}
                />
                <ColunaEngrenagens 
                    titulo="Lateral de Saída (1)"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaLateralEntrada_01_Fertilizante(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Fertilizante"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_fertilizante_lat_saida_02}
                    setNessecario_add_Movida={setMsg_movida_fertilizante_lat_saida_02}
                />
                <ColunaEngrenagens 
                    titulo="Lateral de Saída (2)"
                    lista_engrenagens_completa={lista_engrenagens}
                    lista_engrenagens={listaLateralEntrada_02_Fertilizante(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Fertilizante"
                    cod_patrimonio={cod_patrimonio}
                    necessario_add_movida={msg_movida_fertilizante_recambio}
                    setNessecario_add_Movida={setMsg_movida_fertilizante_recambio}
                />
            </ContainerColunas>,
            destroyInactivePanel: false,
            collapsible: undefined
        },
    ];

    return (
        <ContainerPlantadeira>

            <Box>
                <TitleH3>
                    Engrenagens
                </TitleH3>
               <Collapse 
                    accordion 
                    items={lista_collapse}    
                /> 
            </Box>
            
            
            {/* <ContainerColunas>
                <ColunaEngrenagens 
                    titulo="Lateral de entrada"
                    lista_engrenagens={listaLateralEntradaPadrao(lista_engrenagens)}
                    setLista_engrenagens={setLista_engrenagens}
                    categoria="Geral"
                    cod_patrimonio={cod_patrimonio}
                />
            </ContainerColunas> */}
            
            {/* <Box> */}
                {/* <TitleH3>
                    Sementes
                </TitleH3>

                <ContainerColunas>
                    <ColunaEngrenagens 
                        titulo="Recâmbio"
                        lista_engrenagens={listaRecambio_Semente(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Semente"
                    cod_patrimonio={cod_patrimonio}
                    />
                    <ColunaEngrenagens 
                        titulo="Lateral de Saída (1)"
                        lista_engrenagens={listaLateralEntrada_01_Semente(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Semente"
                    cod_patrimonio={cod_patrimonio}
                    />
                    <ColunaEngrenagens 
                        titulo="Lateral de Saída (2)"
                        lista_engrenagens={listaLateralEntrada_02_Semente(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Semente"
                        cod_patrimonio={cod_patrimonio}
                    />
                </ContainerColunas>
            </Box>
            
            <Box>
                <TitleH3>
                    Fertilizante
                </TitleH3>

                <ContainerColunas>
                    <ColunaEngrenagens 
                        titulo="Recâmbio"
                        lista_engrenagens={listaRecambio_Fertilizante(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Fertilizante"
                        cod_patrimonio={cod_patrimonio}
                    />
                    <ColunaEngrenagens 
                        titulo="Lateral de Saída (1)"
                        lista_engrenagens={listaLateralEntrada_01_Fertilizante(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Fertilizante"
                        cod_patrimonio={cod_patrimonio}
                    />
                    <ColunaEngrenagens 
                        titulo="Lateral de Saída (2)"
                        lista_engrenagens={listaLateralEntrada_02_Fertilizante(lista_engrenagens)}
                        setLista_engrenagens={setLista_engrenagens}
                        categoria="Fertilizante"
                        cod_patrimonio={cod_patrimonio}
                    />
                </ContainerColunas>
            </Box> */}
            
            
        </ContainerPlantadeira>
    );
}