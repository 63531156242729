import React, { useEffect, useState } from 'react'
import { ContainerCusto } from './style'
import ViewPage from '../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { TabelaCentroDeCusto } from './components/tabela'
import {
  deleteCentroDeCusto,
  getCentroDeCusto,
  postCentroDeCusto,
  putCentroDeCusto,
} from '../../../services/centroDeCusto'
import FormCentroDeCusto from '../../../Components/centroDeCusto'
import { message, Modal } from 'antd'
import { IconAlert } from '../../../Components/iconsAlert'

const CentroDeCusto: React.FC = () => {
  //STATES
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [update, setUpdate] = useState(null)
  const [open, setOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingEditar, setEditarLoading] = useState(true)
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)
  const [dataCentroDeCusto, setDataCentroDeCusto] = useState([])
  const [nome, setNome] = useState('')

  async function listarCentroDeCusto() {
    setLoadingTable(true)
    const response = await getCentroDeCusto({ pagination: pagination, page: page })
    setDataCentroDeCusto(response.data.itens)
    setLoadingTable(false)
  }

  async function cadastrarCentroDeCusto() {
    setLoading(true)
    if (update <= 0) {
      const response = await postCentroDeCusto({ nome }, fecharGaveta, setLoading)
    } else {
      const response = await putCentroDeCusto(update, { nome }, fecharGaveta, setLoading)
    }
  }

  async function deletarCentroDeCusto(cod, nome) {
    setFake_loading(true)
    const response = await deleteCentroDeCusto(cod)
    setTimeout(() => {
      setFake_loading(false)
    }, 3000)
    listarCentroDeCusto()
  }

  async function editarCentroDeCusto(cod) {
    setUpdate(cod)
    setOpen(true)
    const response = await getCentroDeCusto({ cod: cod, pagination: pagination, page: page })
    setNome(response.data.itens[0].nome)
    setEditarLoading(false)
  }

  function fecharGaveta() {
    setOpen(false)
    setUpdate(null)
    setLoading(false)
    setEditarLoading(true)
    setNome('')
    listarCentroDeCusto()
  }

  useEffect(() => {
    listarCentroDeCusto()
  }, [])

  return (
    <ContainerCusto>
      <ViewPage
        title='Centro de custo'
        btnClick={null}
        newHide
        rotina={101}
        onPermission={setPermissions}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        content={
          <FormCentroDeCusto
            nome={nome}
            setNome={setNome}
            open={open}
            onClose={fecharGaveta}
            update={update}
            onFinish={cadastrarCentroDeCusto}
            loading={loading}
            permissions={permissions}
            loadingEditar={loadingEditar}
          />
        }
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Centro de custos</TitleH1>
        </div>

        <TabelaCentroDeCusto
          setOpen={setOpen}
          listar_centro={dataCentroDeCusto}
          loading={loadingTable}
          setPagination={setPagination}
          page={page}
          setPage={setPage}
          total={total}
          permissao={permissions}
          editarCadastro={editarCentroDeCusto}
          deletar={deletarCentroDeCusto}
          confirmar_exclusao={confirmar_exclusao}
          setConfirmar_exclusao={setConfirmar_exclusao}
          id_item_atual={id_item_atual}
          setId_item_atual={setId_item_atual}
          nome_item_atual={nome_item_atual}
          setNome_item_atual={setNome_item_atual}
          fake_loading={fake_loading}
          setFake_loading={setFake_loading}
        />
      </ViewPage>
    </ContainerCusto>
  )
}

export default CentroDeCusto
