import React from "react";
import { Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faXmark } from "@fortawesome/free-solid-svg-icons";

//COMPONENTS
import ButtonCustom from "../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../Components/CustomUi/inline";

//PROPS
interface Props {
    openPopover: boolean;
    setOpenPopover: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: Function;
    title: string;
    message: string;
}

export const ConfirmarFecharGaveta: React.FC<Props> = ({ openPopover, setOpenPopover, onClose, title, message }) => {
    return (
        <Popconfirm
            placement='left'
            title={title}
            open={openPopover}
            description={
                <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                    <span>
                        {message}
                    </span>
                    <Inline justifyContent='flex-end'>
                        <ButtonCustom
                            onClick={onClose}
                            icon={<FontAwesomeIcon icon={faXmark} />}
                            danger
                            type='tertiary'
                        >
                            Fechar
                        </ButtonCustom>

                        <ButtonCustom
                            onClick={() => {
                                setOpenPopover(false)
                            }}
                            // type="tertiary"
                            icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                        >
                            Voltar para o cadastro
                        </ButtonCustom>
                    </Inline>
                </div>
            }
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        />
    )
}