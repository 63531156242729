import { BoxContainer } from "./styled"

//PROPS
interface Props {
    children?: React.ReactNode;
    borderRadius?: number;
    padding?: number | string;
    gap?: number;
    style?: React.CSSProperties;
    borderless?: boolean;
}

export const Box: React.FC<Props> = ({ children, padding, borderRadius, gap, style, borderless }) => {
    return (
        <BoxContainer style={{borderRadius: borderRadius, border: borderless ? 'none':undefined , padding: padding, gap: gap, ...style}}>
            {children}
        </BoxContainer>
    )
}