import dayjs from 'dayjs'
import dayJsPT from 'dayjs/locale/pt-br'
import localizeFormat from 'dayjs/plugin/localizedFormat'
import Sun from '../../assets/svg/sun.svg'
import Nublado from '../../assets/svg/nublado.svg'
import Chuva from '../../assets/svg/chuvoso.svg'
import NuvensDispersas from '../../assets/svg/nuvensDesipadas.svg'
import AlgumasNuvens from '../../assets/svg/parcialnublado.svg'
import ChuvasLeves from '../../assets/svg/chuvaFraca.svg'
import Tempestade from '../../assets/svg/chuvaTrovoada.svg'
import Neblina from '../../assets/svg/neblina.svg'

import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon, FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { useLogic } from '../../context/useLogic'
import { getClimaCodCity, TypesForecastList } from '../../services/apiClima'

import { Container } from './styled'
import {
  faArrowDown,
  faArrowUp,
  faCloud,
  faDroplet,
  faHome,
  faLocationDot,
  faSearch,
  faWater,
  faWind,
} from '@fortawesome/free-solid-svg-icons'
import { getCliente } from '../../services/Cliente'
import { Select } from '../CustomUi/Select'
import Carregando from '../CustomUi/loading'

const WeatherForecast: React.FC = () => {
  const { dataClima, responsive } = useLogic()
  dayjs.locale(dayJsPT)
  dayjs.extend(localizeFormat)

  const [previView, setpreviView] = useState<TypesForecastList[]>([])
  const [tempAtual, setTempAtual] = useState(0)
  const [condicao, setCondicao] = useState('')
  const [nomeAdress, setNomeAdress] = useState('')
  const [dataPropri, setData] = useState([])
  const [propriSelect, setPropriSelect] = useState('')
  const [propriSelectOpen, setPropriSelectOpen] = useState(false)
  const [loadingTemp, setLoadingTemp] = useState(true)

  const handlePrevisao = async () => {
    setPropriSelect('')
    const codCliente = localStorage.getItem('cod-cliente')
    const tipoAcesso = localStorage.getItem('tipo-acesso')
    if (codCliente !== undefined || codCliente !== '') {
      if (tipoAcesso === 'cliente') {
        const resp = await getCliente(codCliente, '', '', 'propriedades')
        const propriedades = resp.data.itens[0].propriedades
          .map((item) => item)
          .filter((item) => item.status > 0)
        setData(propriedades)
      } else {
        const resp = await getCliente(codCliente, '', '', 'propriedades')

        const datapropriedades = resp.data.itens
          //@ts-ignore
          .flatMap((item) => item.propriedades)
          .filter((item) => item.status > 0)
        setData(datapropriedades)
      }
    }

    const filtrar = dataClima.days.map((item) => {
      const date = dayjs(item.datetime) // Converte a data em objeto dayjs
      return {
        ...item,
        diaSemana: date.format('dddd'),
        diaDoMes: date.format('DD MMMM'), // Nome completo do dia da semana
      }
    })
    setTempAtual(dataClima.currentConditions.temp)
    setCondicao(dataClima.currentConditions.conditions)
    setNomeAdress(dataClima.resolvedAddress)

    setpreviView(filtrar.slice(0, 5))
    setLoadingTemp(false)
  }

  async function handleClimaPropri(data) {

    if (data==='') {
      setPropriSelect('');
      return;
    }

    setLoadingTemp(true)
    setPropriSelect(data)

    const filter = dataPropri.filter((item) => item.nome === data)
    const clima = await getClimaCodCity(filter[0]?.cidade[0]?.nome)
    if (clima !== undefined) {
      const filtrar = clima?.data?.days.map((item) => {
        const date = dayjs(item.datetime) // Converte a data em objeto dayjs
        return {
          ...item,
          diaSemana: date.format('dddd'),
          diaDoMes: date.format('DD MMMM'), // Nome completo do dia da semana
        }
      })

      setTempAtual(clima?.data?.currentConditions.temp)
      setCondicao(clima?.data?.currentConditions.conditions)
      setNomeAdress(clima?.data?.resolvedAddress)
      setpreviView(filtrar?.slice(0, 5))
      setLoadingTemp(false)
    }
  }

  function getImg(description: string) {
    switch (description) {
      case 'Claro':
        return Sun
      case 'Chuva, Nublado':
        return Nublado
      case 'Chuva':
        return Chuva
      case 'Chuva, Parcialmente nublado':
        return NuvensDispersas
      case 'Parcialmente nublado':
        return NuvensDispersas
      case 'chuva leve':
        return ChuvasLeves
      case 'chuva moderada':
        return ChuvasLeves
      case 'chuva forte':
        return Chuva
      case 'chuva congelante':
        return Chuva
      case 'Tempestade':
        return Tempestade
      case 'Tempestade com chuva leve':
        return Tempestade
      case 'tempestade com chuva':
        return Tempestade
      case 'tempestade com chuva forte':
        return Tempestade
      case 'neblina':
        return Neblina
      default:
        return Sun
    }
  }

  useEffect(() => {
    if (dataClima?.days.length > 0) {
      handlePrevisao()
      setLoadingTemp(true)
    }
  }, [dataClima])

  useEffect(() => {
    if (dataClima?.days.length > 0 && propriSelect==='') {
      handlePrevisao()
      setLoadingTemp(true)
    }
  }, [propriSelect])
  

  return (
    <Container responsive={responsive}>
      {loadingTemp ? (
        <div
          style={{
            width: '500px',
            height: '471px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          <Carregando justifyContent='center' legenda='Buscando clima...' animation />
        </div>
      ) : (
        <div className='main-container'>
          {/* <Select value={''} onChange={function (value: React.SetStateAction<string>): void {
          throw new Error('Function not implemented.')
        } } open={false} trigger={function (value: React.SetStateAction<boolean>): void {
          throw new Error('Function not implemented.')
        } } items={[]} descricaoItem={''} idItem={''} /> */}
          {previView.map((data, index) =>
            index === 0 ? (
              <>
                <div key={uuid()} className='card-temp'>
                  <div key={uuid()} className='card-temp-city'>
                    {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ fontSize: '30px', color: '#3d7054' }}
                    />
                    
                    
                  </div>
                   */}
                    <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                      <Select
                        value={propriSelect}
                        onChange={handleClimaPropri}
                        open={propriSelectOpen}
                        trigger={setPropriSelectOpen}
                        items={dataPropri}
                        descricaoItem={'nome'}
                        idItem={'cod'}
                        icon={faLocationDot}
                        // allowClear={false}
                        placeholder={'Selecione a propriedade'}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        width: '100%',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                    >
                      <div style={{ width: '80%', display: 'flex', gap: '10px' }}>
                        <h4
                          style={{
                            margin: '0',
                            padding: '0',
                            fontSize: '17px',
                            fontWeight: '700',
                            color: '#3d7054',
                          }}
                        >
                          {`${nomeAdress.split(', ')[0]} / ${nomeAdress.split(', ')[1]}`}
                        </h4>
                      </div>
                      <h4
                        style={{
                          margin: '0',
                          padding: '0',
                          fontSize: '13px',
                          fontWeight: '700',
                          color: '#3d7054',
                        }}
                      >{`${data?.diaSemana.substring(0, 3).toLocaleUpperCase()}, ${data?.diaDoMes
                        .substring(0, 6)
                        .toLocaleUpperCase()}`}</h4>
                    </div>
                  </div>
                </div>
                <div className='campo-info-clima' key={uuid()}>
                  <img
                    className='img-nuvem'
                    src={getImg(data?.conditions)}
                    alt={data?.description}
                  />
                  <div className='campo-temp'>
                    <div className='temperatura'>
                      {/* <h2>{`${data?.main.feels_like.toFixed(0)}ºC`}</h2> */}
                      <h2>{Math.floor(tempAtual)}ºC</h2>
                      <div className='maxima__minima'>
                        <b>
                          {Math.ceil(data.tempmax)}º
                          <FontAwesomeIcon
                            style={{ color: 'red', opacity: '0.5' }}
                            icon={faArrowUp}
                          />
                        </b>
                        <b>
                          {Math.ceil(data.tempmin)}º
                          <FontAwesomeIcon
                            style={{ color: 'blue', opacity: '0.5' }}
                            icon={faArrowDown}
                          />{' '}
                        </b>
                      </div>
                    </div>
                    <h4>{data.conditions.toLocaleLowerCase()}</h4>
                  </div>

                  {/* <table key={uuid()} style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: '50px', textAlign: 'center' }}>
                        
                        <h4 className='capitalized'>{data?.weather[0].description}</h4>
                      </td>
                      <td>
                        <b>
                          Umidade <Icon icon='droplet' />
                        </b>
                        <h4>{`${data?.main.humidity}%`}</h4>
                      </td>
                      <td>
                        <b>
                          Chuva <Icon icon='cloud-showers-heavy' />
                        </b>

                        <h4>{`${data?.rain === undefined ? '0.0mm' : `${data?.rain['3h']}mm`}`}</h4>
                      </td>
                      <td>
                        <b>
                          Vento <Icon icon='wind' />
                        </b>
                        <h4>{`${data.wind.speed} km/h`}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
                </div>
                <div className='infos-do-clima'>
                  <div className='campo-infos'>
                    <div className='infos-header'>
                      <h4>Umidade</h4>{' '}
                      <FontAwesomeIcon
                        icon={faDroplet}
                        style={{ fontSize: '12px', color: ' #3d7054' }}
                      />
                    </div>
                    <h4>{`${data?.humidity}%`}</h4>
                  </div>
                  <div className='campo-infos'>
                    <div className='infos-header'>
                      <h4 style={{ margin: '0', padding: '0', color: ' #3d7054' }}>Chuva</h4>{' '}
                      <FontAwesomeIcon
                        icon={faCloud}
                        style={{ fontSize: '12px', color: ' #3d7054' }}
                      />
                    </div>
                    <h4>{`${data?.precip} mm`}</h4>
                  </div>
                  <div className='campo-infos'>
                    <div className='infos-header'>
                      <h4 style={{ margin: '0', padding: '0', color: ' #3d7054' }}>Vento</h4>{' '}
                      <FontAwesomeIcon
                        icon={faWind}
                        style={{ fontSize: '12px', color: ' #3d7054' }}
                      />
                    </div>
                    <h4>{`${data.windspeed} km/h`}</h4>
                  </div>
                </div>
              </>
            ) : null
          )}
          <div className='container-clima-semana'>
            {previView.map((data, index) =>
              index > 0 ? (
                <div className='cards-clima-semana' key={index}>
                  <h5 className='capitalized'>{`${data.diaSemana.substring(0, 3)}.`}</h5>
                  <img
                    style={{ width: '60%' }}
                    title={data?.description}
                    src={getImg(data?.conditions)}
                    alt={data?.description}
                  />
                  <div className='max__min'>
                    <span>
                      {Math.ceil(data.tempmax)}º
                      <FontAwesomeIcon style={{ color: 'red', opacity: '0.5' }} icon={faArrowUp} />
                    </span>
                    {'  '}
                    <span style={{ color: '#d2d2d2' }}>
                      {Math.ceil(data.tempmin)}º
                      <FontAwesomeIcon
                        style={{ color: 'blue', opacity: '0.5' }}
                        icon={faArrowDown}
                      />
                    </span>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
    </Container>
  )
}

export default WeatherForecast
