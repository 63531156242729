/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { faRedo, faSync, faUndo } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import { Button, Col, Drawer, Form, Input, Row, message } from 'antd';

//STYLES
import { Container, Tools } from './styles';

//APP
import { useAccess } from '../../context/useAccess';
import { Notification } from '../notification';
import { geralKmlPolygon } from '../gerarKml';
import { postTalhao, putTalhao } from '../../services/Talhao';
import { calculoAreaCoord, optionsMap, polyGetPath } from '../../services/Afins';
import { postTalhaoCultivavel, putTalhaoCultivavel } from '../../services/TalhaoSafra';

//COMPONENT
import Icons from '../Icons';
import SidePainel from '../SidePainel';
import WaterMarker from '../WaterMarker';

interface DesenhoTalhaoProps {
  isOpen: boolean
  update?: number
  onClose: (data: boolean) => void
  cultivavel?: boolean
  talhao: {
    nome: string
    propriedadeCod: number
    propriedadeNome: string
    fitBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
    polygon: google.maps.LatLngLiteral[] | google.maps.LatLng[]
  }
  onSuccess?: () => void
  atualizarLista?: Function
}

const DesenhoTalhao: React.FC<DesenhoTalhaoProps> = ({
  isOpen,
  talhao,
  onClose,
  update = 0,
  cultivavel,
  onSuccess,
  atualizarLista
}) => {
  const [map, setMap] = useState<google.maps.Map>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [visible, setVisible] = useState(false)

  const [getAreaTalhao, setGetAreaTalhao] = useState<number>(0)

  const [visibleSidePanel, setVisibleSidePanel] = useState(false)

  const [undoPolygon, setUndoPolygon] = useState([])
  const [redoPolygon, setRedoPolygon] = useState([])
  const [salvar, setSalvar] = useState(false);
  const [carregando_salvar, setCarregando_salvar] = useState<boolean>(false);

  const [form] = Form.useForm()
  const { setAtualizarArea, atualizarArea } = useAccess()

  useEffect(() => {
    setVisible(isOpen)
  }, [isOpen])

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...');
      setCarregando_salvar(true);

      const polygonFinish = polyGetPath(polygon)

      const kml = geralKmlPolygon(polygonFinish, data.nome)

      const cripto = btoa(kml)

      const arquivo = `data:@file/xml;base64,${cripto}`

      data = { ...data, arquivo }

      const dados = {
        nome: data.nome,
        cod_propriedade: data.cod_propriedade,
        arquivo: data.arquivo,
        status: data.status,
        area: String(data.area),
      }

      if (update <= 0) {
        const response = cultivavel
          ? await postTalhaoCultivavel(talhao.propriedadeCod, dados)
          : await postTalhao(talhao.propriedadeCod, dados)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Talhão Cadastrado com Sucesso',
            type: 'success',
          })
          onSuccess?.()
          setAtualizarArea(!atualizarArea)
          atualizarLista?.()
          setSalvar(false)
          setCarregando_salvar(false);
        }
      } else {
        const response = cultivavel
          ? await putTalhaoCultivavel(talhao.propriedadeCod, update, dados)
          : await putTalhao(talhao.propriedadeCod, update, dados)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Talhão Atualizado com Sucesso',
            type: 'success',
          })
          onSuccess?.()
          setAtualizarArea(!atualizarArea)
          atualizarLista?.()
          setSalvar(false)
          setCarregando_salvar(false);
        }
        setCarregando_salvar(false);
      }
    } catch (error) {
      message.destroy()
      setCarregando_salvar(false);

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleUndo = () => {
    const old = [...undoPolygon]

    const index = undoPolygon.length - 1
    let newArray = []
    let newCoord = []
    old.forEach((data, i) => {
      if (data !== undefined) {
        if (index !== i) {
          const info = data
          newArray = [...newArray, info]
        } else {
          setRedoPolygon([...redoPolygon, data])
          newCoord = [old[i - 1]]
        }
      }

      return { newArray, newCoord }
    })

    polygon.setPaths(newCoord)

    setGetAreaTalhao(calculoAreaCoord(newCoord))
    form.setFieldsValue({ area: calculoAreaCoord(newCoord) })

    setUndoPolygon(newArray)
  }

  const handleRedo = () => {
    const old = [...redoPolygon]

    const index = redoPolygon.length - 1
    let newArray = []
    let newCoord = []
    old.forEach((data, i) => {
      if (data !== undefined) {
        if (index !== i) {
          const info = data
          newArray = [...newArray, info]
        } else {
          setUndoPolygon([...undoPolygon, data])
          newCoord = [old[i]]
        }
      }

      return { newArray, newCoord }
    })

    polygon.setPaths(newCoord)

    setGetAreaTalhao(calculoAreaCoord(newCoord))
    form.setFieldsValue({ area: calculoAreaCoord(newCoord) })

    setRedoPolygon(newArray)
  }

  const handleResetDesenho = () => {
    setUndoPolygon([])
    setRedoPolygon([])
    polygon.setPath([])
    form.setFieldsValue({ area: calculoAreaCoord([talhao.polygon]) })
    message.destroy()
  }

  const handleMouseUpPolygon = (e: google.maps.MapMouseEvent) => {
    const dados = polygon
      .getPath()
      .getArray()
      .map((item) => {
        return { lat: item.lat(), lng: item.lng() }
      })

    setUndoPolygon([...undoPolygon, dados])

    const ha = calculoAreaCoord([dados])
    setSalvar(true)

    form.setFieldsValue({ area: ha })
  }

  useEffect(() => {
    if (update > 0) {
      form.setFieldsValue({
        nome: talhao.nome,
        area: calculoAreaCoord([talhao.polygon]),
        cod_propriedade: talhao.propriedadeCod,
      })

      if (map && polygon) {
        map.fitBounds(talhao.fitBounds)
        polygon.setPath(talhao.polygon)
      }
    } else {
      form.resetFields()
    }
  }, [talhao])

  useEffect(() => {
    if (map && polygon) {
      if (polygon.getPath().getArray().length <= 0) {
        map.fitBounds(talhao.fitBounds)
        polygon.setPath(talhao.polygon)
      }
    }
  })

  useEffect(() => {
    if (map && polygon) {
      setTimeout(() => {
        handleResetDesenho()
      }, 500)
    }
  }, [talhao])

  const handleRightClick = (event: google.maps.MouseEvent) => {
    if (polygon) {
        const path = polygon.getPath();
        const index = path.getArray().findIndex((latlng) => latlng.equals(event.latLng));
        if (index > -1) {
            path.removeAt(index);
        }
    }
};
useEffect(() => {
    if (polygon) {
        const listener = polygon.addListener("rightclick", handleRightClick);
        return () => {
            google.maps.event.removeListener(listener);
        };
    }
}, [polygon]);


  return (
    <Container>
      <Drawer
        open={visible}
        closeIcon={false}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0 }}
        onClose={() => onClose(false)}
        style={{ position: 'absolute' }}
        placement='bottom'
        height='100%'
      >
        {/* @ts-ignore */}
        <GoogleMap
          onLoad={setMap}
          mapContainerStyle={{ minWidth: '100%', height: '100vh' }}
          options={optionsMap}
          zoom={2}
        >
          <Polygon
            onLoad={(data) => setPolygon(data)}
            onMouseUp={handleMouseUpPolygon}
            options={{
              fillColor: 'cyan',
              strokeColor: 'cyan',
              strokeWeight: 4,
              fillOpacity: 0.45,
              clickable: true,
              draggable: false,
              editable: true,
              geodesic: false,
            }}
          />
          <Tools>
            <SidePainel
              open={visibleSidePanel}
              onClose={() => onClose(false)}
              onSave={() => salvar == true && form.submit()}
              size='small'
              title='Dados talhão'
              hideSize='small'
              loading_save={carregando_salvar}
              atualizarLista={atualizarLista}
              // disable_save={!salvar}
            >
              <Form onFinish={onFinish} size='small' form={form}>
                <Col span={24}>
                  <Row gutter={[8, 0]}>
                    <Col span={24}>
                      <Form.Item required name='nome'>
                        <Input required placeholder='Nome Talhão' />
                      </Form.Item>
                    </Col>
                    <Col span={15}>
                      <Form.Item name='area'>
                        <Input style={{ cursor: 'not-allowed' }} readOnly addonAfter='ha' />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item>
                        <Button
                          disabled={undoPolygon.length <= 1}
                          onClick={handleUndo}
                          style={{ color: 'white' }}
                          title='Desfazer'
                        >
                          <Icons icon={faUndo} />
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item>
                        <Button
                          disabled={redoPolygon.length <= 0}
                          onClick={handleRedo}
                          style={{ color: 'white' }}
                          title='Refazer'
                        >
                          <Icons icon={faRedo} />
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item>
                        <Button
                          onClick={handleResetDesenho}
                          style={{ color: 'white' }}
                          type='primary'
                          danger
                          title='Resetar'
                        >
                          <Icons icon={faSync} />
                        </Button>
                      </Form.Item>
                    </Col>
                    <Form.Item name='cod_propriedade' />
                    <Form.Item name='status' initialValue={1} />
                  </Row>
                </Col>
              </Form>
            </SidePainel>
          </Tools>
          <WaterMarker placement='topLeft' />
        </GoogleMap>
      </Drawer>
    </Container>
  )
}

export default DesenhoTalhao
