import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'
import { message } from 'antd'

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  cod?: number
  pagination?: number | -1
  page?: number | 15
  total?: number
}

interface TypeGetCentroDeCusto {
  itens: [TypeItensCentroDeCusto]
}

interface TypeItensCentroDeCusto {
  cod: number
  nome: string
}

export const getCentroDeCusto = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  params = {
    codConta: config.conta,
    codCliente: config.codCliente,
    ...params,
  }

  const response: AxiosResponse<TypeGetCentroDeCusto> = await api.get('/centro-de-custo', {
    params,
  })

  return response
}

export const postCentroDeCusto = async (data, fecharGaveta, setLoading) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetCentroDeCusto> = await api
    .post('/centro-de-custo', data, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })

    .then((): any => {
      message.success({ content: 'Centro de custo cadastrado com sucesso!', key: 'loading' })
      fecharGaveta()
    })
    .catch(() => {
      message.error({ content: 'Erro ao cadastrar centro de custo', key: 'loading' })
      setLoading(false)
    })

  return response
}

export const putCentroDeCusto = async (cod, data, fecharGaveta, setLoading) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetCentroDeCusto> = await api
    .post(`/centro-de-custo/${cod}`, data, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })

    .then((): any => {
      message.success({ content: 'Centro de custo atualizado com sucesso!', key: 'loading' })
      fecharGaveta()
    })
    .catch(() => {
      message.error({ content: 'Erro ao atualizar centro de custo', key: 'loading' })
      setLoading(false)
    })

  return response
}

export const deleteCentroDeCusto = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetCentroDeCusto> = await api
    .delete(`/centro-de-custo/${cod}`, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })

    .then((): any => {
      message.destroy()
      setTimeout(() => {
        message.success({ content: 'Centro de custo deletado com sucesso!', key: 'loading' })
      }, 800)
    })
    .catch(() => {
      message.destroy()
      setTimeout(() => {
        message.error({ content: 'Erro ao deletar centro de custo', key: 'loading' })
      }, 800)
    })

  return response
}
