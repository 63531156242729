import React, { SetStateAction, useEffect, useState } from 'react'

//STYLES

//APP
import { PropriedadeItens } from '../../../../../services/Propriedades'

//COMPONENTS
import Input from '../../../../../Components/CustomUi/input'
import { Box } from '../../../../../Components/CustomUi/box'
import { TitleH2 } from '../../../../../Components/CustomUi/titleH2'
import { RowTable } from '../../../../../Components/CustomUi/table/rowTable'
import { BodyTable } from '../../../../../Components/CustomUi/table/bodyTable'
import { HeaderTable } from '../../../../../Components/CustomUi/table/headerTable'
import { RowItem } from '../../../../../Components/CustomUi/table/rowItem'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faCopy, faEdit, faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Render } from '../../../../../Components/CustomUi/render'
import Carregando from '../../../../../Components/CustomUi/loading'
import { removerAcentos } from '../../../../../utils'
import { Empty } from 'antd'
import { Switcher } from '../../../../../Components/CustomUi/Switcher'
import { Inline } from '../../../../../Components/CustomUi/inline'
import { DeleteButton } from '../../../../../Components/CustomUi/deleteButton'
import { useAccess } from '../../../../../context/useAccess'
import { ItensPerUserLogged } from '../../../../../services/login/permissaoUsuarioLoado'

//PROPS
interface Props {
  loading: boolean
  setPagination: React.Dispatch<React.SetStateAction<number>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  total: number
  listar_centro: any[]
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  permissao: ItensPerUserLogged
  editarCadastro: Function
  deletar: Function
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
}

export const TabelaCentroDeCusto: React.FC<Props> = ({
  loading,
  page,
  setPage,
  setPagination,
  total,
  listar_centro,
  setOpen,
  permissao,
  editarCadastro,
  deletar,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
}) => {
  const [buscarTabela, setBuscarTabela] = useState('')
  function buscarPatrimonios(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  return (
    <Box style={{ height: 'calc(100vh - 180px)' }} gap={10}>
      <Inline alignItems='center'>
        <Input
          placeholder='Buscar centro de custos'
          value={buscarTabela}
          onChange={setBuscarTabela}
        />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setOpen(true)}>
          Novo
        </ButtonCustom>
      </Inline>
      <HeaderTable
        itens={[
          { valor: 'Nome', width: 'calc(100% - 185.5px)' },
          { valor: 'Ações', width: '180px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: (listar_centro ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(listar_centro ?? []).filter(buscarPatrimonios(buscarTabela)).map(({ cod, nome }) => {
            return (
              <RowTable key={cod} onDoubleClick={() => {}}>
                <RowItem fontSize={13} width={'calc(100% - 180px)'}>
                  {nome}
                </RowItem>

                <RowItem width={'180px'} gap={10}>
                  <DeleteButton
                    open={confirmar_exclusao && id_item_atual === cod}
                    trigger={setConfirmar_exclusao}
                    onTrigger={() => {
                      setId_item_atual(cod)
                      setNome_item_atual(nome)
                    }}
                    loading={fake_loading}
                    message={`o item ${nome} será excluido!`}
                    deleteds={[nome]}
                    onDelete={() => {
                      deletar(cod)
                    }}
                    disabled={fake_loading}
                    disabledTrigger={permissao?.exclusao === 0}
                  />
                  <ButtonCustom
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => editarCadastro(cod)}
                    disabled={permissao?.alteracao === 0 && permissao?.acesso === 0}
                  >
                    Editar
                  </ButtonCustom>
                </RowItem>
              </RowTable>
            )
          })}
        </Render>

        <Render condition={listar_centro.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando centros de custos...'
            justifyContent='center'
            height={200}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
