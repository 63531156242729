import React, { memo, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { useLoadScript } from '@react-google-maps/api'
import { Link, useHistory } from 'react-router-dom'
import { faPowerOff, faSadCry, faSearch } from '@fortawesome/free-solid-svg-icons'
import { BellOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Col,
  Divider,
  Drawer,
  Form,
  Layout,
  Menu,
  message,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd'

//APP
import { useLogic } from '../../context/useLogic'
import { AppViews } from '../../Views/app'
import { useAccess } from '../../context/useAccess'
import { localConfig } from '../../Configs/localConfig'
import { APP_PREFIX_PATH } from '../../Configs/AppConfig'
import { getCliente, getConsultoria } from '../../services/Cliente'
import { getSafra, getSafraEstatisticas } from '../../services/Safra'
import Timeline from './components/timeline/index'

//STYLES
import Container from './styled'

//ASSETS
import Logo from '../../assets/img/logo.png'
import LogoTtivos from '../../assets/img/logo-ttivos-png.png'
import LogoSM from '../../assets/img/logo-sm.png'
import FolhaTtivos from '../../assets/img/folha_ttivos.png'

//COMPONENTS
import Icons from '../Icons'
import DropUser from '../dropUser'
import Carregando from '../CustomUi/loading'
import { LogoSideBar } from './components/logo'
import { isLastSubmenu } from './meta'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalSearch } from './components/globalSearch'
import { getClimaCodCity } from '../../services/apiClima'

const { Header, Sider, Content } = Layout

const { SubMenu } = Menu
const { Option, OptGroup } = Select

const MenuLyout: React.FC = () => {
  const {
    itens,
    setResponsive,
    responsive,
    setTypeAccess,
    setLisSafra,
    setCodCliente,
    dataConta,
    lisSafra,
    dataCliente,
    setDataCliente,
    safraSelecionada,
    setSafraSelecionada,
    rota,
    setDataClima,
    setLocation,
  } = useLogic()

  const {
    navigate,
    loading,
    setLoggedInAs,
    setNameCliente,
    setNameSafra,
    setCodCultura,
    nomeLab,
    atualizarArea,
  } = useAccess()

  const history = useHistory();

  //STATES
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedWidth, setCollapsedWidth] = useState(80)
  const [clienteSelecionado, setClienteSelecionado] = useState('')
  const [current_index, setCurrent_index] = useState<number[]>([-1, 0])
  const [current_index_sub, setCurrent_index_sub] = useState<number[]>([-1, 0])

  const [consultoria, setConsultoria] = useState([])
  const [navigateFilter, setNavigateFilter] = useState([])
  const [menuSelect, setMenuSelect] = useState([])

  const [openKeys, setOpenKeys] = useState([])

  const [versionDrawer, setVersionDrawer] = useState(false)

  const [loadingMenu, setLoadingMenu] = useState(false)
  const [loadingSafra, setLoadingSafra] = useState(false)
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [modal_global_search, setModal_global_search] = useState<boolean>(false);

  const acessoLocal = localStorage.getItem('tipo-acesso')
  const config = localConfig()

  const toggle = (e) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    setCollapsed(!collapsed)
  }

  function originPositionTileSub(new_index: number) {
    let last = current_index_sub[0]

    setCurrent_index_sub([new_index, last])
  }

  function originPositionTile(new_index: number) {
    let last = current_index[0]

    setCurrent_index([new_index, last])
  }

  const [form] = Form.useForm()

  const handleRefresh = async (data, event) => {
    message.loading('Carregando Aguarde ...')
    setNameCliente(event.data.name)

    if (event.data.type === 'CLIENTE' || event.data.type !== 'CONTA') {
      localStorage.setItem('cod-cliente', data)
      setLoggedInAs({
        type: 'CONTA_CLIENTE',
        codCliente: data,
        codConta: Number(localStorage.getItem('conta')),
      })
    } else {
      localStorage.setItem('cod-cliente', '')
      setLoggedInAs({
        type: 'CONTA',
        codCliente: null,
        codConta: event.data.value,
      })
    }
    const climaType: any = event.data.type === 'CONTA_CLIENTE' ? 1 : 0
    handleClima(data, climaType ? 1 : 0)

    localStorage.setItem('cod-cliente', data)

    form.setFieldsValue({ safra: null })
    localStorage.removeItem('codSafra')
    setDadosEstatisticas([])

    setClienteSelecionado(data)
    listSafras()

    history.push('/home')
    setMenuSelect(['/home'])

    setCodCliente(data)

    message.destroy()
  }
  
  const handleSafraSelected = (data, element: any = {}) => {
    setNameSafra(element.children)
    form.setFieldsValue({ safra: data })
    localStorage.setItem('codSafra', data)
    localStorage.setItem('nomeSafra', element?.children ?? '')
    listTotalPropriedade(data)

    setSafraSelecionada(data)

    const codCultura = lisSafra.find((rec) => rec.cod === data)?.cod_cultura
    localStorage.setItem('codCultura', JSON.stringify(codCultura))

    setCodCultura(codCultura)
    if (rota !== '/talhao/safra') {
      if (rota === null) {
        history.push('/home')
      } else {
        history.push(rota)
      }
    }
  }

  const handleClima = async (cod, tipo) => {
    let response
    let city

    if (tipo === 1) {
      response = await getCliente(cod, '', '', 'propriedades')
      city = response.data.itens[0]?.cidade
    } else {
      response = await getConsultoria()
      city = response.data.itens[0].cidade[0]
    }
    const clima = await getClimaCodCity(city?.nome)

    // @ts-ignore
    // clima.data.city.uf = city.uf_sigla

    setDataClima(clima?.data)
  }

  function sairLab() {
    history.push('/logout')
  }

  function handleTipo() {
    if (config.acesso === 'conta') {
      return 0
    }

    if (acessoLocal === 'conta' && config.codCliente !== '') {
      return 1
    }

    return 1
  }

  const listSafras = async () => {
    setLoadingSafra(true)
    setLisSafra([])

    const response = await getSafra({ order: 'data_inicio' })
    const dataSafra = response.data.itens

    setLisSafra(
      orderBy(
        dataSafra,
        (rec) => {
          return dayjs(rec.data_final).unix()
        },
        'desc'
      )
    )
    setLoadingSafra(false)
  }

  const listTotalPropriedade = async (cod) => {
    const response = await getSafraEstatisticas()
    const filter = response.data.itens.filter((item) => item.cod === Number(cod))
    const validar = filter?.[0]?.total_propriedades > 0
    setShowDetalhes(validar)
    setDadosEstatisticas(filter)
  }

  const onOpenChange = (keys) => {
    setOpenKeys([keys[keys.length - 1]])
  }

  const loadScriptGoogleMaps = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_TOKEN_GOOGLE_MAPS,
    libraries: ['places', 'drawing', 'visualization', 'geometry'],
    language: 'pt-BR',
  })

  const selectClient = (
    <Col className='selectCliente' span={24} style={{ backgroundColor: '#fff' }}>
      <Row>
        <Form
          form={form}
          layout='inline'
          style={
            responsive
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  marginLeft: '5px',
                  width: '240px',
                  height: '120px',
                  flexDirection: 'column',
                }
              : {}
          }
        >
          {localStorage.getItem('tipo-acesso') === 'cliente' ? (
            <h3 style={{ display: 'inline', marginTop: '9px' }}>
              {localStorage.getItem('nome-cliente')}
            </h3>
          ) : null}

          {acessoLocal !== 'lab' ? (
            <Space wrap>
              <Form.Item style={{ height: '60px' }}>
                <Select
                  onChange={handleRefresh}
                  value={clienteSelecionado}
                  optionFilterProp='children'
                  popupMatchSelectWidth={500}
                  showSearch
                  style={{
                    minWidth: '20vw',
                    width: '240px',
                    // @ts-ignore
                    display:
                      localStorage.getItem('tipo-acesso') === 'cliente' ||
                      itens[0]?.acessoConta?.contas?.length <= 0
                        ? 'none'
                        : 'block',
                  }}
                  size='large'
                >
                  <OptGroup label='Clientes'>
                    {dataCliente.map((data) => {
                      // console.log("data: ", dataCliente.length)
                      return (
                        <Option
                          key={data.cod}
                          value={data.cod}
                          data={{ type: 'CONTA_CLIENTE', name: data.nome, value: data.cod }}
                        >
                          <Avatar
                            size='default'
                            src={data.logo}
                            style={{
                              background: 'var(--primary-color)',
                              color: 'white',
                              fontWeight: 500,
                              fontSize: 18,
                            }}
                          >
                            {String(data.nome)?.[0]}
                          </Avatar>
                          <Divider type='vertical' />

                          {data.nome}
                        </Option>
                      )
                    })}
                  </OptGroup>
                  <OptGroup label='Consultoria'>
                    {consultoria.map((data) => {
                      return (
                      <Option
                        value=''
                        key='conta'
                        data={{ type: 'CONTA', name: data?.nome, value: data?.cod }}
                      >
                        <Avatar
                          size='default'
                          // src={data.logo}
                          style={{
                            background: 'var(--primary-color)',
                            color: 'white',
                            fontWeight: 500,
                            fontSize: 18,
                          }}
                        >
                          {String(data.nome)?.[0]}
                        </Avatar>
                        <Divider type='vertical' /> {data?.nome}
                      </Option>
                    )})}
                  </OptGroup>
                </Select>
              </Form.Item>
              {/* <Form.Item hidden={itens[0]?.acessoConta.contas.length <= 0} name='safra'> */}
              <div>
                <Form.Item name='safra'>
                  <Select
                    onChange={handleSafraSelected}
                    value={safraSelecionada}
                    loading={loadingSafra}
                    optionFilterProp='filter'
                    popupMatchSelectWidth={false}
                    showSearch
                    size='large'
                    style={{
                      minWidth: '150px',
                      width: responsive && '240px',
                    }}
                    placeholder='Safra'
                    options={lisSafra.map((rec) => {
                      return {
                        key: rec.cod,
                        value: rec.cod,
                        label: (
                          <>
                            <div style={{ fontWeight: 500, color: 'var(--text-color)' }}>
                              {rec.nome}
                            </div>
                            <small
                              style={{
                                color: 'var(--primary-color)',
                                fontSize: 10,
                                fontWeight: 500,
                                marginTop: -5,
                              }}
                            >
                              Vigência {dayjs(rec.data_final).format('DD/MM/YYYY')}
                            </small>
                          </>
                        ),
                        filter: rec.nome,
                      }
                    })}
                  />
                </Form.Item>

                <div style={{ width: '90%', height: '20px' }}>
                  {showDetalhes === true ? (
                    <>
                      {dadosEstatisticas.map((item) => {
                        return (
                          <div
                            key={item.cod}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              color: '#a3a3a3',
                              fontSize: '12px',
                            }}
                          >
                            <p style={{ fontSize: '11px', marginTop: '2px' }}>
                              Talhões: {item.total_talhoes}
                            </p>
                            <p style={{ fontSize: '11px', marginTop: '2px' }}>
                              Área: {item.total_area_talhoes} ha
                            </p>
                          </div>
                        )
                      })}
                    </>
                  ) : null}
                </div>
              </div>

              {/* <Form.Item >
              
            </Form.Item> */}
            </Space>
          ) : null}
        </Form>
      </Row>
    </Col>
  )

  useEffect(() => {
    if (safraSelecionada !== null) {
      const nameSafra = lisSafra.find((rec) => rec.cod === safraSelecionada)?.nome
      handleSafraSelected(safraSelecionada, { children: nameSafra })
    }
  }, [safraSelecionada])

  useEffect(() => {
    const fetch = async () => {
      setLoadingMenu(true)
      const config = localConfig()

      setTypeAccess(config.acesso)

      if (config.conta !== null) {
        const dados = itens[0]?.acessoConta?.contas.find(
          (data) => data.cod === Number(config.conta)
        )
        setDataCliente(dados?.clientes === undefined ? [] : dados?.clientes)
      }

      setConsultoria(dataConta)
      listSafras()

      handleClima(
        config.acesso === 'conta' && config.codCliente === '' ? config.conta : config.codCliente,
        handleTipo()
      )
      setLoadingMenu(false)
    }

    

    const link = window.location
    setLocation(link)

    fetch()
  }, [])

  useEffect(() => {
    const filterNavigation = navigate.map(menu => {
      if (menu.submenu) {
        return {
          ...menu,
          submenu: menu.submenu.filter(sub => !sub.visible) // Filtra submenus onde 'hidden' é false
        };
      }
      return menu; // Retorna o menu como está se não houver submenu
    });

    setNavigateFilter(filterNavigation)
  }, [navigate])

  useEffect(() => {

    listTotalPropriedade(localStorage.getItem('codSafra'))
  }, [atualizarArea])

  //caso usuário estja logado como cliente, oculta a opção do menu
  function ocultarQuandoForCLiente(rota: string) {
    if (localConfig().codCliente !== '' && rota === '/cliente') {
      return 'none'
    }

    return undefined
  }

  return (
    <Container collapsed={collapsed} className='contyainer-reff'>
      {!loadScriptGoogleMaps.isLoaded ? (
        <div className='loader'>
          <div className='logo-loader'>
            <img width='100' src={LogoSM} alt='Logo' />
            <br />
            <Spin size='large' />
          </div>
        </div>
      ) : (
        <Layout>
          <div className='area-menu'>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger-responsive',
              hidden: !responsive,
              onClick: toggle,
            })}
            {acessoLocal !== 'lab' ? (
              <Sider
                style={{
                  // overflow: 'auto',
                  height: '100%',
                  left: 0,
                  borderRight: 'thin solid #7FBB5A !important',
                }}
                // className={`corThema time_${time}h`}
                className={`corThema time_${new Date().getHours()}h`}
                theme='light'
                trigger={null}
                collapsible
                collapsed={collapsed}
                width='250'
                breakpoint='md'
                collapsedWidth={collapsedWidth}
                onBreakpoint={(broken) => {
                  setResponsive(!!broken)
                  return broken ? setCollapsedWidth(0) : setCollapsedWidth(80)
                }}
                onCollapse={(collapsed2, type) => {
                  return collapsed2 ? setCollapsed(true) : setCollapsed(false)
                }}
              >
                <LogoSideBar collapsed={collapsed} />

                {loadingMenu ? (
                  <Carregando
                    justifyContent='center'
                    height={720}
                    size={'large'}
                    legenda='Validando permissões'
                    animation
                    isWhite
                  />
                ) : (
                  <>
                    <Menu
                      // className='corThema'
                      style={{
                        width: '100%',
                        height: 'calc(100vh - 117px)',
                        overflow: 'auto',
                        padding: 0,
                        background: 'transparent',
                        scrollbarWidth: 'none',
                        marginTop: 5,
                        zIndex: 2,
                      }}
                      theme='dark'
                      mode='inline'
                      defaultSelectedKeys={['1']}
                      onClick={(data) => setMenuSelect([data.key])}
                      selectedKeys={menuSelect}
                      onOpenChange={onOpenChange}
                      openKeys={openKeys}
                    >
                      {/* <button style={{minHeight: 40, color:'green'}} onClick={()=>setTime(time=>time+1)}>Aumentar</button>
                    <button style={{minHeight: 40, color:'blue'}} onClick={()=>setTime(6)}>{time}</button>
                    <button style={{minHeight: 40, color:'red'}} onClick={()=>setTime(time=>time-1)}>Diminuir</button> */}
                      {responsive && selectClient}

                      {loading ? (
                        <Carregando
                          justifyContent='center'
                          height={620}
                          size={'large'}
                          legenda='Validando permissões'
                          animation
                          isWhite
                        />
                      ) : (
                        navigateFilter.map((menu, main_index) =>
                          menu.submenu.length > 0 ? (
                            <SubMenu
                              className='sidebar-submenu'
                              style={{ display: menu.hidden ? 'none' : '' }}
                              icon={menu.icon ? <Icons icon={menu.icon} /> : null}
                              onTitleClick={() => (menu.path ? history.push(menu.path) : null)}
                              key={menu.key}
                              title={menu.title}
                            >
                              {menu.submenu.map((subMenuFirst, index) => {
                                return subMenuFirst.submenu.length > 0 ? (
                                  <SubMenu
                                    style={{
                                      display: subMenuFirst.hidden ? 'none' : '',
                                    }}
                                    icon={
                                      subMenuFirst.icon ? <Icons icon={subMenuFirst?.icon} /> : null
                                    }
                                    onTitleClick={() => history.push(subMenuFirst.path)}
                                    key={subMenuFirst.key}
                                    title={subMenuFirst.title}
                                  >
                                    {subMenuFirst.submenu.map((subMenuSecond) =>
                                      subMenuSecond.submenu.length > 0 ? (
                                        <SubMenu
                                          style={{
                                            display: subMenuSecond.hidden ? 'none' : '',
                                          }}
                                          icon={
                                            subMenuSecond.icon ? (
                                              <Icons icon={subMenuSecond?.icon} />
                                            ) : null
                                          }
                                          key={subMenuSecond.key}
                                          title={subMenuSecond.title}
                                        >
                                          {subMenuSecond.submenu.map((subMenuTree) =>
                                            subMenuTree.submenu.length > 0 ? (
                                              <SubMenu
                                                style={{
                                                  display: subMenuTree.hidden ? 'none' : '',
                                                }}
                                                icon={
                                                  subMenuTree.icon ? (
                                                    <Icons icon={subMenuTree?.icon} />
                                                  ) : null
                                                }
                                                key={subMenuTree.key}
                                                title={subMenuSecond.title}
                                              >
                                                {subMenuTree.submenu.map((subMenuFor) =>
                                                  subMenuFor.submenu.length > 0 ? (
                                                    <SubMenu
                                                      style={{
                                                        display: subMenuFor.hidden ? 'none' : '',
                                                      }}
                                                      icon={
                                                        subMenuFor.icon ? (
                                                          <Icons icon={subMenuFor?.icon} />
                                                        ) : null
                                                      }
                                                      key={subMenuFor.key}
                                                      title={subMenuFor.title}
                                                      className='sidebar-menu-item'
                                                    />
                                                  ) : (
                                                    <Menu.Item
                                                      onClick={responsive ? toggle : null}
                                                      key={subMenuFor.key}
                                                      icon={
                                                        subMenuFor.icon ? (
                                                          <Icons icon={subMenuFor?.icon} />
                                                        ) : null
                                                      }
                                                      className='sidebar-menu-item'
                                                      style={{ paddingLeft: 0 }}
                                                    >
                                                      <span>{subMenuFor.title}</span>
                                                      <Link to={subMenuFor.path} />
                                                    </Menu.Item>
                                                  )
                                                )}
                                              </SubMenu>
                                            ) : (
                                              <Menu.Item
                                                onClick={responsive ? toggle : null}
                                                key={subMenuTree.key}
                                                icon={
                                                  subMenuTree.icon ? (
                                                    <Icons icon={subMenuTree?.icon} />
                                                  ) : null
                                                }
                                                className='sidebar-menu-item'
                                                style={{ paddingLeft: 0 }}
                                              >
                                                <span>{subMenuTree + '777'}</span>
                                                <Link to={subMenuTree.path} />
                                              </Menu.Item>
                                            )
                                          )}
                                        </SubMenu>
                                      ) : (
                                        <Menu.Item
                                          onClick={responsive ? toggle : null}
                                          key={subMenuSecond.key}
                                          icon={
                                            subMenuSecond.icon ? (
                                              <Icons icon={subMenuSecond?.icon} />
                                            ) : null
                                          }
                                          className='sidebar-menu-item'
                                          style={{ paddingLeft: 0 }}
                                        >
                                          <span>{subMenuSecond.title}</span>
                                          <Link to={subMenuSecond.path} />
                                        </Menu.Item>
                                      )
                                    )}
                                  </SubMenu>
                                ) : (
                                  <Menu.Item
                                    onClick={responsive ? toggle : null}
                                    key={subMenuFirst.key}
                                    hidden={subMenuFirst.visible}
                                    className='submenu-item-new'
                                    style={{ paddingLeft: 0, display: subMenuFirst.hidden ? 'none' : undefined }}
                                    icon={
                                      subMenuFirst.icon ? (
                                        <Tooltip
                                          trigger='hover'
                                          placement='right'
                                          title={
                                            subMenuFirst.title.length >= 27
                                              ? subMenuFirst.title
                                              : null
                                          }
                                        >
                                          <Icons icon={subMenuFirst.icon} />
                                        </Tooltip>
                                      ) : null
                                    }
                                  >
                                    <div
                                      className='submenu-wrapper'
                                      role='none'
                                      onClick={() => originPositionTileSub(Number(index))}
                                    >
                                      <div
                                        className='box-stroke'
                                        data-hidden={isLastSubmenu(menu, subMenuFirst?.path)}
                                      >
                                        <div className='curve-stroke' />
                                      </div>

                                      <Tooltip
                                        title={subMenuFirst.title === 'Catálogo de pragas' ? 'Catálogo de pragas e ervas daninhas' : subMenuFirst.title}
                                        destroyTooltipOnHide
                                        placement='rightTop'
                                        mouseEnterDelay={1}
                                        
                                      >
                                        <div className='submenu-content'>
                                          <span>{subMenuFirst.title}</span>
                                          <Link to={subMenuFirst.path} />
                                          <div
                                            className='item-tile'
                                            data-origin-below={
                                              current_index_sub[0] < current_index_sub[1]
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </Menu.Item>
                                )
                              })}
                            </SubMenu>
                          ) : (
                            <Menu.Item
                              onClick={responsive ? toggle : null}
                              hidden={menu.hidden}
                              key={menu.key}
                              icon={menu.icon ? <Icons icon={menu?.icon} /> : null}
                              style={{
                                display: ocultarQuandoForCLiente(menu.path),
                                paddingLeft: 0,
                              }}
                              className='sidebar-menu-item'

                            >
                              <div onClick={() => originPositionTile(main_index)}>
                                {menu?.title}
                                {menu.path ? <Link to={menu.path} /> : null}
                              </div>

                              <div
                                className='item-tile'
                                data-origin-below-menu={current_index[0] < current_index[1]}
                              />
                            </Menu.Item>
                          )
                        )
                      )}
                      {!loading ? (
                        <>
                          <Menu.Item
                            onClick={responsive ? toggle : null}
                            key='teste1'
                            hidden={process.env.NODE_ENV === 'production'}
                            icon={<Icons icon={faSadCry} />}
                            className='sidebar-menu-item'
                          >
                            <span>Teste</span>
                            <Link to={`${APP_PREFIX_PATH}/teste`} />
                          </Menu.Item>

                          <Menu.Item
                            onClick={responsive ? toggle : null}
                            key='Sair'
                            icon={<Icons icon={faPowerOff} />}
                            className='sidebar-menu-item leave'
                          >
                            Sair
                            <Link to={`${APP_PREFIX_PATH}/logout`} />
                          </Menu.Item>
                        </>
                      ) : null}
                      <Menu.Item style={{ pointerEvents: 'none' }}>{'  '}</Menu.Item>

                      <div
                        className='version-list'
                        hidden={responsive}
                        onClick={() => setVersionDrawer(true)}
                      >
                        <span>v{process.env.REACT_APP_VERSION}</span>
                      </div>
                    </Menu>
                    <img
                      src={FolhaTtivos}
                      alt='TTivos'
                      className='fancy_detail'
                      draggable={false}
                    />
                  </>
                )}
              </Sider>
            ) : null}
          </div>
          <Layout className='site-layout' style={{ overflow: 'hidden' }}>
            <Header
              style={{ height: '76px', padding: '0', margin: '0' }}
              hidden={responsive}
              className='site-layout-background'
            >
              <div
                style={{
                  height: '76px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '15px',
                  boxSizing: 'border-box',
                  backgroundColor: acessoLocal === 'lab' ? '#3B5063' : '#fff',
                }}
              >
                {acessoLocal !== 'lab' ? (
                  React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: toggle,
                  })
                ) : (
                  <div className='area-logo-lab'>
                    <img className={'logo-lab'} src={LogoTtivos} alt='' />
                    <h3 className='nome-lab'>{nomeLab}</h3>
                  </div>
                )}
                {!responsive && selectClient}

                {acessoLocal !== 'lab' ? (
                  <div hidden={responsive} className='option-user'>
                    <Tooltip title='Encontrar algo no sistema' placement='bottom'>
                      <button className='btn-ttivos-finder' onClick={()=>{setModal_global_search(true)}}>
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </Tooltip>
                    <button className='btn-app-notificacao'>
                      <Popover trigger='click' content={<>Sem notificações</>}>
                        <Badge size='default' count={0}>
                          <span
                            style={{
                              fontSize: '20px',
                              color: '#7b7b7b',
                            }}
                          >
                            <BellOutlined />
                          </span>
                        </Badge>
                      </Popover>
                    </button>

                    <DropUser />
                  </div>
                ) : (
                  <button className='btn-sair' onClick={sairLab}>
                    <Icons icon={faPowerOff} /> Sair
                  </button>
                )}
              </div>
            </Header>
            <Content style={{ marginTop: responsive && '30px', minHeight: 'calc(100vh - 76px)' }}>
              <AppViews />
            </Content>
          </Layout>
        </Layout>
      )}
      <Drawer
        size='large'
        closeIcon={false}
        open={versionDrawer}
        onClose={() => setVersionDrawer(false)}
        destroyOnClose
      >
        <Timeline />
      </Drawer>

      <GlobalSearch 
        open={modal_global_search}
        onClose={setModal_global_search}
        onClickLink={setMenuSelect}
      />

    </Container>
  )
}

export default memo(MenuLyout);