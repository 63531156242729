import React, { useEffect, useState } from 'react'

import {
  faAddressCard,
  faBriefcase,
  faCalendarDays,
  faCircleInfo,
  faCity,
  faClipboardList,
  faEdit,
  faEnvelope,
  faFileSignature,
  faFont,
  faHashtag,
  faHouse,
  faHouseUser,
  faIdBadge,
  faList,
  faListDots,
  faLocationDot,
  faMap,
  faMobileScreenButton,
  faMoneyBill,
  faPen,
  faPhone,
  faPlus,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { useGetCnpj } from '../../services/buscarCNPJ'
import { cepMask, cpfMask, fixoMask, phoneMask } from '../../services/Afins'
import { message } from 'antd'
import { Render } from '../CustomUi/render'
import Input from '../CustomUi/input'
import { Select } from '../CustomUi/Select'
import { apenasNumeros, maskCNPJ } from '../../utils'
import { HeaderDrawer } from '../CustomUi/headerGaveta'
import { BodyDrawer } from './styled'
import Tabs from '../Tabs'
import Carregando from '../CustomUi/loading'
import TagInput from '../CustomUi/tags'

interface PropsFormFornecedores {
  loadingForm: boolean
  tab_selecionada_atual: string
  setTab_selecionada_atual: React.Dispatch<React.SetStateAction<string>>
  tipoProduto: string
  setTipoProduto: React.Dispatch<React.SetStateAction<string>>
  dataVigencia: string
  setDataVigencia: React.Dispatch<React.SetStateAction<string>>
  tipoServico: string
  setTipoServico: React.Dispatch<React.SetStateAction<string>>
  valor: string
  setValor: React.Dispatch<React.SetStateAction<string>>
  dataProduto: string[]
  setDataProduto: React.Dispatch<React.SetStateAction<string[]>>
  dataServico: any[]
  setDataServico: React.Dispatch<React.SetStateAction<any[]>>
  uf: string
  setUf: React.Dispatch<React.SetStateAction<string>>
  ufId: number
  setUfId: React.Dispatch<React.SetStateAction<number>>
  cidade: string
  setCidade: React.Dispatch<React.SetStateAction<string>>
  cidadeId: number
  setCidadeId: React.Dispatch<React.SetStateAction<number>>
  cnpj: string
  setCnpj: React.Dispatch<React.SetStateAction<string>>
  listarUF: any[]
  listarCidade: any[]
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  cpf: string
  setCpf: React.Dispatch<React.SetStateAction<string>>
  celular: string
  setCelular: React.Dispatch<React.SetStateAction<string>>
  fixo: string
  setFixo: React.Dispatch<React.SetStateAction<string>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  cep: string
  setCep: React.Dispatch<React.SetStateAction<string>>
  bairro: string
  setBairro: React.Dispatch<React.SetStateAction<string>>
  endereco: string
  setEndereco: React.Dispatch<React.SetStateAction<string>>
  numero: string
  setNumero: React.Dispatch<React.SetStateAction<string>>
  complemento: string
  setComplemento: React.Dispatch<React.SetStateAction<string>>
  razaoSocial: string
  setRazaoSocial: React.Dispatch<React.SetStateAction<string>>
  nomeFantasia: string
  setNomeFantasia: React.Dispatch<React.SetStateAction<string>>
  timeRenderizar: boolean
  setTimeRenderizar: React.Dispatch<React.SetStateAction<boolean>>
  ie: string
  setIe: React.Dispatch<React.SetStateAction<string>>
  update: number
  indexInicial: number
  atualizar: Function
}

const FormFornecedores: React.FC<PropsFormFornecedores> = ({
  loadingForm,
  tab_selecionada_atual,
  setTab_selecionada_atual,
  tipoProduto,
  setTipoProduto,
  dataProduto,
  setDataProduto,
  dataServico,
  setDataServico,
  dataVigencia,
  setDataVigencia,
  tipoServico,
  setTipoServico,
  valor,
  setValor,
  uf,
  setUf,
  ufId,
  setUfId,
  cidade,
  setCidade,
  setCidadeId,
  cnpj,
  setCnpj,
  listarUF,
  listarCidade,
  nome,
  setNome,
  cpf,
  setCpf,
  celular,
  setCelular,
  fixo,
  setFixo,
  email,
  setEmail,
  cep,
  setCep,
  bairro,
  setBairro,
  endereco,
  setEndereco,
  numero,
  setNumero,
  complemento,
  setComplemento,
  razaoSocial,
  setRazaoSocial,
  nomeFantasia,
  setNomeFantasia,
  timeRenderizar,
  setTimeRenderizar,
  ie,
  setIe,
  update,
  indexInicial,
  atualizar,
}) => {
  const [openUf, setOpenUf] = useState(false)
  const [openCidade, setOpenCidade] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataCidadeFilter, setDataCidadeFilter] = useState([])
  const { data_cnpj, refetch } = useGetCnpj(cnpj.replace(/[^\d]/g, ''))
  const [tags, setTags] = useState<string[]>([])

  // function adicionarProdutos() {
  //   setDataProduto((formDataProduto) => [
  //     ...formDataProduto,
  //     {
  //       cod_temp: parseFloat(Date.now().toString()),
  //       produtos: tipoProduto,
  //     },
  //   ])

  //   setTipoProduto('')
  // }

  // async function deletarTags(cod) {
  //   if (typeof cod === 'number') {
  //     let remover = dataProduto.filter((item) => item.cod_temp !== cod)
  //     setDataProduto(remover)
  //     setTipoProduto('')
  //   } else {
  //     Modal.confirm({
  //       title: 'Excluir',
  //       icon: <IconAlert type='confirm' size={6} />,
  //       content: `Deseja realmente remover o Fornecedor ${nome}?`,
  //       okText: 'Confirmar',
  //       cancelText: 'Cancelar',
  //       cancelButtonProps: {
  //         type: 'primary',
  //         danger: true,
  //       },
  //       onOk: async () => {
  //         message.loading('Deletando...', 9999999)
  //         const data = {
  //           tags_remover: [cod],
  //         }
  //         const response = await putFornecedorDelTags(update, data, atualizar)
  //       },
  //     })
  //   }
  // }

  function adicionarServiço() {
    setDataServico((formDataServico) => [
      ...formDataServico,
      {
        cod_temp: Date.now().toString(),
        data: dataVigencia,
        tipo_servico: tipoServico,
        valor: valor,
      },
    ])
    setDataVigencia('')
    setTipoServico('')
    setValor('')
  }

  const handleChangeCidadeUf = async (cod) => {
    const cidadesFiltrar = listarCidade.filter((item) => item.cod_uf === cod)
    setDataCidadeFilter(cidadesFiltrar)
    setLoading(false)
  }

  // function substituirVirgula(texto) {
  //   return texto.replace(/,/g, ';')
  // }

  function maskIE(value) {
    if (!value) return ''
    value = value.replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o primeiro ponto
    value = value.replace(/(\d{3})(\d)/, '$1.$2')
    return value
  }

  function checkCep(cepValue) {
    const cep = cepValue.replace(/\D/g, '')
    setTimeout(() => {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          if (data.bairro !== '' && data.logradouro !== '') {
            setBairro(data.bairro)
            setEndereco(data.logradouro)
            const estadoFiltrado = listarUF?.filter((item) => item.nome === data?.estado)
            setUf(estadoFiltrado[0]?.sigla)
            setUfId(estadoFiltrado[0]?.cod)
            const cidadeFiltrado = listarCidade?.filter((item) => item.nome === data.localidade)
            setCidadeId(cidadeFiltrado[0]?.cod)
            setCidade(data.localidade)
          } else {
            const estadoFiltrado = listarUF?.filter((item) => item.nome === data?.estado)
            setUf(estadoFiltrado[0]?.sigla)
            const cidadeFiltrado = listarCidade?.filter((item) => item.nome === data.localidade)
            setCidadeId(cidadeFiltrado[0]?.cod)
            setCidade(data.localidade)
          }
        })
        .catch(() => {
          message.error({ content: 'Erro ao buscar CEP', duration: 5 })
        })
      message.destroy()
    }, 300)
  }

  function normalizarObjeto(obj) {
    // Mapeamento dos campos do primeiro objeto
    if (obj?.cnpj) {
      return {
        cnpj: obj.cnpj,
        razao_social: obj.razao_social,
        nome_fantasia: obj.nome_fantasia || '',
        cep: obj.endereco?.cep || '',
        telefone: obj.telefone1 || '',
        email: obj.email || '',
        logradouro: obj.endereco?.logradouro || '',
        numero: obj.endereco?.numero || '',
        complemento: obj.endereco?.complemento || '',
        bairro: obj.endereco?.bairro || '',
        municipio: obj.endereco?.municipio || '',
        uf: obj.endereco?.uf || '',
      }
    }

    if (obj?.data?.['CNPJ']) {
      const ddd = obj?.data?.['DDD'] || ''
      const telefone = obj?.data?.['TELEFONE'] || ''
      // console.log(ddd + telefone)
      return {
        cnpj: obj?.data?.['CNPJ'] || '',
        razao_social: obj?.data?.['RAZAO SOCIAL'] || '',
        nome_fantasia: obj?.data?.['NOME FANTASIA'] || '',
        cep: obj?.data?.['CEP'] || '',
        telefone: ddd + '9' + telefone || '',
        email: obj?.data?.['EMAIL'] || '',
        logradouro: obj?.data?.['LOGRADOURO'] || '',
        numero: obj?.data?.['NUMERO'] || '',
        complemento: obj?.data?.['COMPLEMENTO'] || '',
        bairro: obj?.data?.['BAIRRO'] || '',
        municipio: obj?.data?.['MUNICIPIO'] || '',
        uf: obj?.data?.['UF'] || '',
      }
    }
  }

  const dadosCNPJ = normalizarObjeto(data_cnpj)

  useEffect(() => {
    if (
      data_cnpj !== undefined &&
      dadosCNPJ !== undefined &&
      tab_selecionada_atual === 'Pessoa jurídica'
    ) {
      const uf = listarUF.filter((item) => item.sigla === dadosCNPJ?.uf)
      handleChangeCidadeUf(uf[0]?.cod)
      setUf(uf[0]?.sigla)
      setRazaoSocial(dadosCNPJ?.razao_social)
      setNome(dadosCNPJ?.nome_fantasia)
      setCelular(dadosCNPJ?.telefone)
      setEmail(dadosCNPJ?.email)
      setCep(dadosCNPJ?.cep)
      setBairro(dadosCNPJ?.bairro)
      setEndereco(dadosCNPJ?.logradouro)
      setNumero(dadosCNPJ?.numero)
      setComplemento(dadosCNPJ?.complemento)
    }
  }, [data_cnpj])

  useEffect(() => {
    if (dataCidadeFilter.length > 0 && timeRenderizar) {
      const cidadeSemAcento = dataCidadeFilter.map((item) => {
        return {
          ...item,
          nome: item.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        }
      })
      const cidade = cidadeSemAcento.filter(
        (item) => item.nome.toLowerCase() === dadosCNPJ?.municipio.toLowerCase()
      )
      setCidade(cidade[0]?.nome)
    }
  }, [dataCidadeFilter])

  useEffect(() => {
    if (
      cnpj.replace(/[^\d]/g, '').length === 14 &&
      tab_selecionada_atual === 'Pessoa jurídica' &&
      timeRenderizar
    ) {
      message.loading('Buscando CNPJ...', 999999)
      refetch()
    }
  }, [cnpj])

  useEffect(() => {
    if (cep?.length === 9 && timeRenderizar) {
      message.loading('Buscando...', 999999)
      checkCep(cep)
    }
  }, [cep])

  useEffect(() => {
    if (update > 0 && ufId > 0) {
      handleChangeCidadeUf(ufId)
    }
  }, [update, ufId])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimeRenderizar(true)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
      setTimeRenderizar(false)
      console.log('Timeout cancelado!')
    }
  }, [])

  return (
    <>
      <Render condition={loadingForm}>
        <Carregando
          animation
          justifyContent='center'
          height={'100%'}
          legenda='Carregando informações...'
        />
      </Render>
      <Render condition={loadingForm === false}>
        <HeaderDrawer>
          {update > 0 ? 'Atualizar fornecedores' : 'Cadastrar fornecedores'}
        </HeaderDrawer>
        <BodyDrawer>
          <Tabs
            tabs={[
              { descricao: 'Pessoa física', icone: faUser },
              { descricao: 'Pessoa jurídica', icone: faIdBadge },
            ]}
            selecionado={tab_selecionada_atual}
            trackWidth={324}
            indexInicial={indexInicial}
            onChange={setTab_selecionada_atual}
          />

          <Render condition={tab_selecionada_atual === 'Pessoa jurídica'}>
            <Input
              placeholder='Digite o cnpj'
              value={maskCNPJ(cnpj)}
              onChange={setCnpj}
              label='CNPJ'
              icon={faBriefcase}
            />

            <div className='campo__dois__inputs'>
              <Input
                placeholder='Digite a razão social'
                value={razaoSocial}
                onChange={setRazaoSocial}
                label='Razão social'
                icon={faAddressCard}
              />
              <Input
                placeholder='Digite o nome fantasia'
                value={nome}
                onChange={setNome}
                label='Nome fantasia'
                icon={faFileSignature}
              />
            </div>

            <Input
              placeholder='Digite a Inscrição estadual'
              value={maskIE(apenasNumeros(ie))}
              onChange={setIe}
              label='IE'
              icon={faClipboardList}
              maxLength={11}
            />
          </Render>

          <Render condition={tab_selecionada_atual === 'Pessoa física'}>
            <Input
              placeholder='Digite o nome'
              value={nome}
              onChange={setNome}
              label='Nome'
              icon={faPen}
              obrigatorio
            />

            <Input
              placeholder='Digite o cpf'
              value={cpfMask(cpf)}
              onChange={setCpf}
              label='CPF'
              icon={faIdBadge}
            />
          </Render>

          <div className='campo__dois__inputs'>
            <Input
              placeholder='(00) 00000-0000'
              value={phoneMask(celular)}
              onChange={setCelular}
              label='Celular'
              icon={faMobileScreenButton}
              maxLength={15}
            />
            <Input
              placeholder='(00) 0000-0000'
              value={fixoMask(fixo)}
              onChange={setFixo}
              label='Telefone Fixo'
              icon={faPhone}
              maxLength={14}
            />
          </div>

          <Input
            placeholder='Digite o email'
            value={email}
            onChange={setEmail}
            label='Email'
            icon={faEnvelope}
          />

          {/* <Input
            placeholder='Digite os produtos/serviços oferecidos'
            value={tipoProduto}
            onChange={setTipoProduto}
            label='Produtos/Serviços oferecidos'
            icon={faList}
            descrição={true}
            textoDescrição="Este campo será utilizado para registrar os produtos ou serviços oferecidos pelo fornecedor, facilitando a identificação e consulta posterior. Caso os produtos ou serviços tenham nomes compostos, como, por exemplo, 'Consultoria agronômica', precisará ser delimitado utilizando ';' (ponto e vírgula), exemplo: 'Consultoria agronômica; '."
          /> */}
          <TagInput
            tags={dataProduto}
            onTagsChange={setDataProduto}
            label='Produtos/Serviços oferecidos'
            placeholder='Digite os produtos/serviços oferecidos e tecle enter para inserir.'
            descrição={true}
            textoDescrição='Este campo será utilizado para registrar os produtos ou serviços oferecidos pelo fornecedor, facilitando a identificação e consulta posterior. Ao pressionar virgula, ponto e virgula ou enter, registra os produtos/serviços informados'
          />

          <div className='campo__dois__inputs'>
            <Input
              placeholder='Digite o cep'
              value={cepMask(cep)}
              onChange={setCep}
              label='CEP'
              icon={faLocationDot}
              obrigatorio
            />
            <Select
              value={uf}
              onChange={setUf}
              onChangeID={handleChangeCidadeUf}
              open={openUf}
              trigger={setOpenUf}
              items={listarUF}
              descricaoItem='sigla'
              idItem='cod'
              label='UF'
              placeholder='Selecione a uf do estado'
              icon={faMap}
              obrigatorio
            />
          </div>

          <div className='campo__dois__inputs'>
            <Select
              value={cidade}
              onChange={setCidade}
              onChangeID={setCidadeId}
              open={openCidade}
              trigger={setOpenCidade}
              items={dataCidadeFilter}
              descricaoItem={'nome'}
              idItem={'cod'}
              label='Cidade'
              placeholder='Selecione a cidade'
              icon={faCity}
              obrigatorio
            />
            <Input
              placeholder='Informe o bairro'
              value={bairro}
              onChange={setBairro}
              label='Bairro'
              icon={faHouse}
            />
          </div>

          <div className='campo__dois__inputs'>
            <Input
              placeholder='Informe o endereço'
              value={endereco}
              onChange={setEndereco}
              label='Endereço'
              icon={faHouseUser}
            />
            <Input
              placeholder='Informe o numero'
              value={numero}
              onChange={setNumero}
              label='Numero'
              icon={faHashtag}
            />
          </div>

          <Input
            placeholder='Informe o complemento'
            value={complemento}
            onChange={setComplemento}
            label='Complemento'
            icon={faPlus}
          />

          {/* <Box>
            <Inline alignItems='center'>
              <Input
                placeholder='Produtos/Serviços oferecidos'
                value={tipoProduto}
                onChange={setTipoProduto}
                label='Tipo de produtos'
                icon={faList}
              />
              <div style={{ paddingTop: '15px' }}>
                <ButtonCustom
                  onClick={adicionarProdutos}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                ></ButtonCustom>
              </div>
            </Inline>
            <HeaderTable
              itens={[
                { valor: 'Produto', width: 'calc(100% - 70px )' },
                { valor: 'Ações', width: '70px' },
              ]}
            />

            <BodyTable
              style={{
                marginTop: -10,
              }}
            >
              {dataProduto.map((item, index) => {
                return (
                  <RowTable key={gerarKey(index)}>
                    <RowItem width={'calc(100% - 70px)'}>
                      <span>{item.produtos}</span>
                    </RowItem>
                    <RowItem gap={3} width='70px'>
                      <ButtonCustom
                        onClick={() => deletarTags(item.cod_temp ?? item.produtos)}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        type='tertiary'
                        danger
                      ></ButtonCustom>
                    </RowItem>
                  </RowTable>
                )
              })}
            </BodyTable>
          </Box> */}

          {/* <Render condition={update > 0}>
          <Box>
            <TitleH3>Serviços</TitleH3>
            <Inline alignItems='center'>
              <Input
                placeholder='00/00/0000'
                value={dataVigencia}
                onChange={setDataVigencia}
                label='Data vigência'
                icon={faCalendarDays}
                width={'70%'}
              />

              <Input
                placeholder='Informe o serviço'
                value={tipoServico}
                onChange={setTipoServico}
                label='Tipo serviço'
                icon={faListDots}
              />

              <Input
                placeholder='R$0,00'
                value={valor}
                onChange={setValor}
                label='Valor'
                icon={faMoneyBill}
                width={'60%'}
              />
              <div style={{ paddingTop: '15px' }}>
                <ButtonCustom
                  onClick={adicionarServiço}
                  icon={<FontAwesomeIcon icon={faPlus} />}
                ></ButtonCustom>
              </div>
            </Inline>
            <HeaderTable
              itens={[
                { valor: 'Data', width: '100px' },
                { valor: 'Serviço', width: 'calc(100% - 320px )' },
                { valor: 'Valor', width: '120px' },
                { valor: 'Ações', width: '100px' },
              ]}
            />

            <BodyTable
              style={{
                marginTop: -10,
              }}
            >
              {dataServico.map((item, index) => {
                return (
                  <RowTable key={gerarKey(index)}>
                    <RowItem width={'100px'}>
                      <span>{item.data}</span>
                    </RowItem>
                    <RowItem width={'calc(100% - 320px)'}>
                      <span>{item.tipo_servico}</span>
                    </RowItem>
                    <RowItem width={'120px'}>
                      <span>{item.valor}</span>
                    </RowItem>
                    <RowItem gap={3} width='100px'>
                      <ButtonCustom
                        onClick={() => {}}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        type='tertiary'
                        danger
                      ></ButtonCustom>
                      <ButtonCustom
                        onClick={() => {}}
                        icon={<FontAwesomeIcon icon={faEdit} />}
                        type='tertiary'
                      ></ButtonCustom>
                    </RowItem>
                  </RowTable>
                )
              })}
            </BodyTable>
          </Box>
        </Render> */}
        </BodyDrawer>
      </Render>
    </>
  )
}

export default FormFornecedores
