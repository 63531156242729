import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerTabs, ListTabs } from "./styled";
import { useState } from "react";
import { gerarKey, isDisabled } from "./meta";

//PROPS
interface Props {
    label?: string;
    labelFontSize?: number;
    tabs: {icone: any, descricao: string, disabled?: boolean, disabledMsg?: string, task?: Function, width?: number}[];
    selecionado: string | number;
    onChange: Function;
    repetir?: Function;
    indexInicial?: number;
    disableAll?: boolean;
    width?: string | number;
    tab_key?:string
    trackWidth?: number;
    useTabWidth?: boolean;
    apenasIcones?: boolean;
    style?: React.CSSProperties;
}

const Tabs: React.FC<Props> = ({ label, style, labelFontSize, tabs, selecionado, onChange, repetir, 
    indexInicial, disableAll, trackWidth, width, tab_key, useTabWidth, apenasIcones }) => {

    //STATES
    const [indexSelecionado, setIndexSelecionado] = useState<number>(indexInicial ?? 0);

    function obterLarguras() {

        if (apenasIcones) {
            return 60 * indexSelecionado;
        }

        let tabs_anteriores = tabs?.filter((_, index)=> index < indexSelecionado);
        let larguras = tabs_anteriores?.map(item=> item.width);
        let soma: number = larguras.reduce((acumulador, valorAtual) => {
            return acumulador + valorAtual;
          }, 0);
          
        return soma ?? 0;
    }

    function larguraTrack(): number {
        if (apenasIcones) {
            return 60;
        }
        return tabs?.[indexSelecionado??0]?.width ?? trackWidth;
    }

    return (
        <ContainerTabs style={{...style, width: width}}>
            {!!label &&(
                <label className="ContainerTabs__label" style={{fontSize: labelFontSize}}>
                    {label}
                </label>
            )}
            
            <ListTabs trackWidth={useTabWidth ? obterLarguras() : (tabs?.[indexSelecionado - 1]?.width ?? trackWidth) ?? 173} data-disabled={disableAll}>
                {tabs.map(({ icone, descricao, task, disabled, disabledMsg, width }, index)=> {
                    return (
                        <button 
                            key={gerarKey(index)} 
                            type="button" 
                            className="ListTabs__tab" 
                            id={!!tab_key ? `btnTab_${tab_key}_${index}` : `btnTab${index}`}
                            data-active={selecionado === descricao}
                            data-only-icon={(apenasIcones)??false}
                            value={selecionado} 
                            name={descricao}
                            onClick={()=>{setIndexSelecionado(index);onChange(descricao);task?.();repetir?.(descricao)}}
                            disabled={disableAll ? true : Boolean(disabled)}
                            style={{width: width ?? trackWidth, minWidth: width ?? trackWidth}}
                            title={isDisabled(disableAll, disabled) ? (disabledMsg ?? 'Elemento não possui mapa distribuído') : descricao}
                        >
                            <FontAwesomeIcon icon={icone} />
                            <span className="ListTabs__tab__descr">
                                {descricao}
                            </span>
                            
                        </button>
                    )
                })}
                {useTabWidth
                    ?  <div className={`tab__highlight_float ${disableAll?"disabled":''}`} style={{width: larguraTrack()}} data-position={indexSelecionado} data-apenas-icone={apenasIcones??false} />
                    :  <div className={`tab__highlight ${disableAll?"disabled":''}`} style={{width: larguraTrack()}} data-position={indexSelecionado} data-apenas-icone={apenasIcones??false} />
                }
            </ListTabs>
        </ContainerTabs>
    )
};

export default Tabs;