import styled from "styled-components";

export const ContainerPlantadeira = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
`;

export const ContainerColunas = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
`;

export const Label = styled.p`
    margin: 0;
    display: flex; 
    gap: 10px;

        svg {
            color: red;
            margin-top: 3px;

            :is([data-hidden = false]) {
                display: none;
            }
        }
`;