import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm } from "antd";
import { faEdit, faFloppyDisk, faGear, faGears, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { apenasNumeros } from "../../../../../../../../../../../utils";
import { TypeListaEngrenagem } from "../../../../../../../..";
import { useEditarEngrenagem } from "../../../../../../../../../../../services/Patrimonio/PUT";
import { editarEngrenagem, verificaSeEngrenagemJaExiste } from "../../meta";

//COMPONENTS
import Input from "../../../../../../../../../../../Components/CustomUi/input";
import ButtonCustom from "../../../../../../../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../../../../../../../Components/CustomUi/inline";

//PROPS
interface Props {
    cod_patrimonio: number,
    cod_item: number;
    open_editar: boolean;
    setOpen_editar: React.Dispatch<React.SetStateAction<boolean>>;
    item_selecionado: number, 
    setItem_selecionado: React.Dispatch<React.SetStateAction<number>>;
    tipo_engrenagem: 'Motriz' | 'Movida';
    quantidade_atual: string;
    lista_engrenagens_completa: TypeListaEngrenagem[],
    lista_engrenagens: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    is_new: boolean,
}

export const PopOverEditar: React.FC<Props> = ({ cod_item, open_editar, setOpen_editar,
    item_selecionado, setItem_selecionado, tipo_engrenagem, quantidade_atual, lista_engrenagens, 
    setLista_engrenagens, is_new, cod_patrimonio, lista_engrenagens_completa }) => {

    //API
    const { carregando_editar_engrenagem, mudateEditarEngrenagem } = useEditarEngrenagem()

    //STATES
    const [nova_qtd_dentes, setNova_qtd_dentes] = useState<string>('');
    const [engrenagem_ja_existe, setEngrenagem_ja_existe] = useState<boolean>(false);

    useEffect(() => {
        setNova_qtd_dentes(quantidade_atual)
    }, [open_editar]);

    useEffect(() => {
        verificaSeEngrenagemJaExiste(lista_engrenagens, tipo_engrenagem, Number(nova_qtd_dentes), setEngrenagem_ja_existe)
    }, [nova_qtd_dentes, tipo_engrenagem])
    
    return (
        <Popconfirm
            destroyTooltipOnHide
            placement="topRight"
            title={`Editar`}
            open={open_editar && (cod_item === item_selecionado)}
            description={
            <div style={{ display:'flex', flexDirection:'column', gap:23 }}>

                <Input
                    label="Nova quantidade"
                    placeholder="Nova quantidade de dentes"
                    value={apenasNumeros(nova_qtd_dentes)}
                    onChange={setNova_qtd_dentes}
                    icon={tipo_engrenagem==='Motriz' ? faGear : faGears}
                    width={170}
                    allowClear={false}
                    autoFocus
                    error={engrenagem_ja_existe}
                    errorMessage="Quantidade já cadastrada"
                />

                <Inline justifyContent="flex-end">

                    <ButtonCustom
                        onClick={()=>{setOpen_editar(false)}}
                        type="tertiary"
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        danger
                        disabled={carregando_editar_engrenagem}
                    >
                        Cancelar
                    </ButtonCustom>

                    <ButtonCustom 
                        value="Editar"
                        onClick={ is_new ?
                                ()=>{ editarEngrenagem(
                                    lista_engrenagens_completa,
                                    setLista_engrenagens,
                                    cod_item,
                                    nova_qtd_dentes
                                );
                                setOpen_editar(false);
                            } :
                            () => {
                                mudateEditarEngrenagem({
                                    cod_engrenagem: cod_item,
                                    cod_patrimonio: cod_patrimonio,
                                    editarLista: editarEngrenagem,
                                    lista_engrenagens: lista_engrenagens,
                                    lista_engrenagens_completa: lista_engrenagens_completa,
                                    setLista_engrenagens: setLista_engrenagens,
                                    quantidade_dentes: Number(nova_qtd_dentes),
                                    setOpen_editar: setOpen_editar
                                })
                            }
                        }
                        icon={<FontAwesomeIcon icon={faFloppyDisk} /> }
                        
                        loading={carregando_editar_engrenagem}
                        disabled={carregando_editar_engrenagem || engrenagem_ja_existe}
                    >
                        Salvar
                    </ButtonCustom>

                </Inline>
            </div>}
            okButtonProps={{style: { display:'none' }}}
            cancelButtonProps={{style: { display:'none' }}}
        >
            <Button style={{ background:'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}>
                <ButtonCustom 
                    type="tertiary"
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={()=>{setItem_selecionado(cod_item);setOpen_editar(true)}}
                    disabled={carregando_editar_engrenagem}
                /> 
            </Button>
        </Popconfirm>
    )
}