import styled from "styled-components";

export const PainelProps = styled.div`
    position: absolute;
    top: 35px;
    left: 35px;
    width: 350px;
    background: #fff;
    z-index: 2;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    transform: translateY(50px);
    animation: fade 1s forwards;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    will-change: transform;
    overflow: hidden;
    transition: .2s;

    :is([data-shrink = true]) {
        transition: .5s;
        width: 200px;
        height: 70px;
    }

    @keyframes fade {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    div.PainelProps__info {
        display: flex;
        gap: 20px;
    }

`;