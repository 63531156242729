import styled from "styled-components";


export const ContainerGB = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    div.ContainerGB__conteudo {
        width: 100%;
        display: flex;
        gap: 15px;
        /* overflow: hidden; */

        div.ContainerGB__conteudo__datePicker {
            label {
                font-weight: 500;
                font-size: 13px;
                color: var(--text-color, "black");
            }
        }
    }

    div.ContainerGB__footer {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border-top: thin solid var(--border-color);
    };

    div.ContainerGB__loading {
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 570px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: 1s entrance_loading_book cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

        @keyframes entrance_loading_book {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
`;