import { useEffect, useState } from 'react'

//STYLES
import { TabelaMPCalor } from './style'

//APP
import { PropriedadeItens } from '../../../../../services/Propriedades'

//COMPONENTS
import Input from '../../../../../Components/CustomUi/input'
import { Box } from '../../../../../Components/CustomUi/box'
import { TitleH2 } from '../../../../../Components/CustomUi/titleH2'
import { RowTable } from '../../../../../Components/CustomUi/table/rowTable'
import { BodyTable } from '../../../../../Components/CustomUi/table/bodyTable'
import { HeaderTable } from '../../../../../Components/CustomUi/table/headerTable'
import { RowItem } from '../../../../../Components/CustomUi/table/rowItem'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Render } from '../../../../../Components/CustomUi/render'
import Carregando from '../../../../../Components/CustomUi/loading'
import { removerAcentos } from '../../../../../utils'
import { Empty } from 'antd'

//PROPS
interface Props {
  loading: boolean
  list_propriedades: PropriedadeItens[]
  handleClickPropriedade: Function
}

export const TabelaMapaDeCalor: React.FC<Props> = ({ list_propriedades, handleClickPropriedade, loading }) => {

    //STATES
    const [buscar_propriedade, setBuscar_propriedade] = useState('');
    const [new_list_propriedades, setNew_list_propriedades] = useState<PropriedadeItens[]>([]);
    const [fake_loading, setFake_loading] = useState<boolean>(false);
    const [cod_atual, setCod_atual] = useState<number|null>(null);


  //USE EFFECTS
  useEffect(() => {
    setNew_list_propriedades(list_propriedades)
  }, [list_propriedades])

    //FUNCTIONS
    function fakeLoading() {
        setFake_loading(true);

        setTimeout(() => {
            setFake_loading(false);
        }, 1000);
    }
    function searchForMap(buscar_nome: string) {
        const buscaSemAcento = removerAcentos(buscar_nome)
        return function (item) {
            const PROPRIEDADE = removerAcentos(String(item?.['nome']));
            const CIDADE = removerAcentos(String(item?.cidade?.[0]?.nome));
            const ESTADO = removerAcentos(String(item?.cidade?.[0]?.uf_nome));
            const UF = removerAcentos(String(item?.cidade?.[0]?.uf_sigla));

            return String(item.cod)?.toLowerCase().includes(buscaSemAcento.toLowerCase()) ||
                 PROPRIEDADE.toLowerCase().includes(buscaSemAcento.toLowerCase()) ||
                 CIDADE.toLowerCase().includes(buscaSemAcento.toLowerCase()) ||
                 ESTADO.toLowerCase().includes(buscaSemAcento.toLowerCase()) ||
                 UF.toLowerCase().includes(buscaSemAcento.toLowerCase()) || !buscar_nome
        }
    }

  const list_propriedades_filtrada: PropriedadeItens[] = (new_list_propriedades ?? []).filter(
    searchForMap(buscar_propriedade)
  )

  return (
    <TabelaMPCalor>
      <Box>
        <TitleH2>Lista Propriedades</TitleH2>

        <Input
          placeholder='Buscar por propriedade, cidade ou estado'
          value={buscar_propriedade}
          onChange={setBuscar_propriedade}
        />

        <HeaderTable
          itens={[
            { valor: 'Propriedade', width: 'calc(50% - 100px)' },
            { valor: 'Cidade', width: '25%' },
            { valor: 'Estado', width: 'calc(25% - 0.5px)' },
            { valor: 'Ações', width: 100 },
          ]}
        />

                <BodyTable
                    style={{
                        height: [0,0,0].length > 8 ? 'calc(100vh - 340px)' : 'auto',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        marginTop: -10
                    }}
                >
                    <Render condition={!loading}>
                        {(list_propriedades_filtrada ?? []).map(({ cod, nome, cidade })=> {
                            return (
                                <RowTable key={cod} onDoubleClick={()=>{fakeLoading();setCod_atual(cod);handleClickPropriedade(cod)}}>
                                    <RowItem width={'calc(50% - 100px)'} >
                                        {nome}
                                    </RowItem>
                                    <RowItem width={'25%'} >
                                        {cidade?.[0]?.nome}
                                    </RowItem>
                                    <RowItem width={'25%'} >
                                        {cidade?.[0]?.uf_sigla} - {cidade?.[0]?.uf_nome}
                                    </RowItem>
                                    <RowItem width={100} >
                                        <ButtonCustom 
                                            icon={<FontAwesomeIcon icon={faEye} />}
                                            onClick={()=>{fakeLoading();setCod_atual(cod);handleClickPropriedade(cod)}}
                                            loading={fake_loading && cod_atual === cod}
                                            disabled={fake_loading && cod_atual === cod}
                                        >
                                            Ver
                                        </ButtonCustom>
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </Render>

          <Render condition={list_propriedades_filtrada.length === 0 && !loading}>
            {buscar_propriedade === '' ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<> Nenhum registro disponível!</>}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    {' '}
                    <b>'{buscar_propriedade}'</b> não encontrado!
                  </>
                }
              />
            )}
          </Render>

          <Render condition={loading}>
            <Carregando
              animation
              legenda='Carregando mapas de calor...'
              justifyContent='center'
              height={200}
              size='default'
            />
          </Render>
        </BodyTable>
      </Box>
    </TabelaMPCalor>
  )
}
