import styled from "styled-components";

export const SelectMultiWrapper = styled.div`
    width: 100%;
    min-height: 60px;
    position: relative;

    label {
        font-size: 13px;
        font-weight: 500;
        color: var(--text-color);
    }

    span.SelectMultiWrapper__obrigatorio {
        color: var(--text-color-light);
        font-weight: 400;
        font-size: 11px;
    }

    div.SelectMultiWrapper__campo {
        width: 100%;
        min-height: 40px;
        border: thin solid var(--border-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        box-sizing: border-box;
        padding: 10px 10px;
        
        svg.SelectMultiWrapper__campo__icon__talhao {
            width: 30px;
            height: 30px;
        }
    }
    :is([data-disabled = true]) div.SelectMultiWrapper__campo {
        background-color: var(--disabled-color);
        cursor: not-allowed;
    }
    div.SelectMultiWrapper__campo:focus-within {
        border: thin solid var(--primary-color);
        outline: thin solid rgba(93, 165, 125, 0.2);
    }
    div.SelectMultiWrapper__campo__selecionados__item {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: #F4F4F4;
        border-radius: 5px;
        color: var(--text-color);
        white-space: nowrap;
        text-transform: capitalize;
        /* flex: 0 1 auto;  */
    }
    button.__item__remover {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        cursor: pointer;
        opacity: 0.5;
    }
    button.__item__remover:hover {
        opacity: 1;
    }

    .SelectMultiWrapper__campo__icon {
        opacity: 0.4;
    }

    input.SelectMultiWrapper__campo__input {
        /* width: auto; */
        min-width: 200px;
        height: 100%;
        border: none;
        color: var(--text-color);
    }
    input.SelectMultiWrapper__campo__input:disabled {
        background-color: var(--disabled-color);
        cursor: not-allowed;
    }
    input.SelectMultiWrapper__campo__input::placeholder {
        color: rgba(0,0,0,0.25);
    }

    button.SelectMultiWrapper__campo__arrow {
        border: none;
        background-color: transparent;
        cursor: text;
        opacity: 0.5;
        transition: .2s;
        position: absolute;
        right: 10px;
    }  
    :is([data-disabled = true]) button.SelectMultiWrapper__campo__arrow {
        cursor: not-allowed;
    }
    div.SelectMultiWrapper__campo:focus-within button.SelectMultiWrapper__campo__arrow {
        transform: rotate(180deg);
    }  

    div.SelectMultiWrapper__lista {
        position: absolute;
        left: 0;
        /* top: 85px; */
        margin-top: 2px;
        width: 100%;
        max-height: 50vh;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: .2s;
        transition-timing-function: ease-out;
        overflow-y: auto;
        z-index: 999;
    }
    div.SelectMultiWrapper__lista:is([data-open = true]) {
        transform: scaleY(1);
        opacity: 1;
    }

`