import { api } from '../../api';
import { message } from 'antd';
import { localConfig } from '../../../Configs/localConfig';
import { UseMutationResult, useMutation } from 'react-query';
import React from 'react';
import { TypeListaEngrenagem } from '../../../Views/app/patrimonioNovo';

//=================================================================================>
// EDITAR ENGRENAGEM DA PLANTADEIRA (PATRIMONIO) 
//<=================================================================================

interface ParamsEditarEngrenagem {
    cod_engrenagem: number,
    cod_patrimonio: number,
    quantidade_dentes: number,
    editarLista: Function;
    lista_engrenagens: TypeListaEngrenagem[],
    lista_engrenagens_completa: TypeListaEngrenagem[],
    setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>,
    setOpen_editar: React.Dispatch<React.SetStateAction<boolean>>,
}

export const useEditarEngrenagem = (): {
    carregando_editar_engrenagem: boolean;
    error_editar_engrenagem: any,
    mudateEditarEngrenagem: (params: ParamsEditarEngrenagem) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsEditarEngrenagem> = useMutation(
        async (params: ParamsEditarEngrenagem) => {

            const { cod_engrenagem, cod_patrimonio, quantidade_dentes, editarLista, lista_engrenagens, lista_engrenagens_completa, setLista_engrenagens, setOpen_editar } = params;
            
            const config = localConfig();

            // message.loading({ content: 'Excluindo...', key: 'loading' });

            return api.post(`/patrimonio/${cod_patrimonio}?codConta=${config.conta}&codCliente=${config.codCliente}`, {
                engrenagem: [ { cod: cod_engrenagem, quantidade_dentes: quantidade_dentes} ]
            })
                .then((response: any) => {
                    // message.success({ content: 'Talhão excluído com sucesso!', key: 'loading' });
                    editarLista(lista_engrenagens_completa, setLista_engrenagens, cod_engrenagem,  quantidade_dentes);
                    setOpen_editar(false);
                    return response;
                })
                .catch((error: Error) => {
                    //@ts-ignore
                    let error_response = error?.response?.data;
                    // message.destroy('loading');
                    message.error({ content: error_response?.error ?? 'Falha ao editar engrenagem', key: 'loading' });
                    setOpen_editar(false);

                    throw error;
                });
        }
    );

    return { 
        carregando_editar_engrenagem: mutation.isLoading, 
        //@ts-ignore
        error_editar_engrenagem: mutation.error?.response?.data,
        mudateEditarEngrenagem: mutation.mutate 
    };
};
