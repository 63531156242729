import { Drawer } from 'antd'
import React from 'react'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faFloppyDisk, faMoneyBill, faXmark } from '@fortawesome/free-solid-svg-icons'
import Input from '../CustomUi/input'
import { BodyDrawerCentro } from './style'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import Carregando from '../CustomUi/loading'

interface FormCentroProps {
  open: boolean
  onClose: Function
  onFinish: Function
  update: number
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  permissions: ItensPerUserLogged
  loadingEditar: boolean
}

const FormCentroDeCusto: React.FC<FormCentroProps> = ({
  open,
  onClose,
  onFinish,
  update,
  nome,
  setNome,
  loading,
  permissions,
  loadingEditar,
}) => {
  return (
    <Drawer
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center' }}>
          <div
            style={{
              width: 'calc(100% + 42px)',
              height: 35,
              borderBottom: 'thin solid var(--border-color)',
              marginLeft: -22,
            }}
          >
            <ButtonCustom
              onClick={onClose}
              type='tertiary'
              danger
              style={{ marginLeft: 10, marginTop: -10 }}
              icon={<FontAwesomeIcon icon={faXmark} />}
            >
              Fechar
            </ButtonCustom>
          </div>

          <>{update ? 'Atualizar' : 'Cadastrar'}</>
        </div>
      }
      open={open}
      width={500}
      onClose={() => onClose()}
      closable={false}
      bodyStyle={{ padding: 10 }}
      style={{ overflowX: 'hidden' }}
      headerStyle={{ padding: '10px 10px' }}
      destroyOnClose
    >
      <BodyDrawerCentro>
        {loadingEditar && update > 0 ? (
          <Carregando animation justifyContent='center' legenda='Carregando informações...' height={'100%'} />
        ) : (
          <>
            <Input
              label='Nome'
              icon={faMoneyBill}
              placeholder={'Informe o nome do centro de custo'}
              value={nome}
              onChange={setNome}
            />
            <div className='button__footer'>
              <ButtonCustom
                title='Nome'
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                onClick={() => onFinish()}
                loading={loading}
                disabled={loading}
                hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
              >
                {update ? 'Salvar edição' : 'Salvar'}
              </ButtonCustom>
            </div>
          </>
        )}
      </BodyDrawerCentro>
    </Drawer>
  )
}

export default FormCentroDeCusto
