import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'

import { Container } from './styled'
import { Button, Modal, Space, Table } from 'antd'
import { pagination } from '../../../../Components/Default'
import { ItensCalcario, delCalcario, getCalcario } from '../../../../services/recomendacao/calcario'
import Icons from '../../../../Components/Icons'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FornecedorItens, getFornecedor } from '../../../../services/Fornecedores'
import FormCalcario from '../../../../Components/FormCalcario'
import { tryError } from '../../../../services/Afins'
import { Notification } from '../../../../Components/notification'

const PageCalcario: React.FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataCalcario, setDataCalcario] = useState<ItensCalcario[]>([])
  const [dataFornecedor, setDataFornecedor] = useState<FornecedorItens[]>([])
  const [update, setUpdate] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const listCalcario = async () => {
    setLoading(true)
    const resp = await getCalcario({})
    setDataCalcario(resp.data.itens)
    setLoading(false)
  }

  const listFornecedor = async () => {
    const resp = await getFornecedor({codCategoria: 1})
    setDataFornecedor(resp.data.itens)
  }

  const handleClickDelCalcario = (cod: number) => {
    const nameCalcario = dataCalcario.find((rec) => rec.cod === cod).nome
    Modal.confirm({
      title: `Deseja remover o calcário ${nameCalcario}?`,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        try {
          const resp = await delCalcario(cod)

          if (resp.status === 200) {
            Notification({ message: 'Calcario removido', type: 'success' })
            listCalcario()
          }
        } catch (error) {
          tryError(error)
        }
      },
    })
  }

  useEffect(() => {
    listCalcario()
    listFornecedor()
  }, [])

  return (
    <Container>
      <ViewPage
        title='Cadastro calcário'
        rotina={91}
        btnClick={() => handleClickNew()}
        content={
          <FormCalcario
            open={visibleDrawer}
            dataFornecedor={dataFornecedor}
            onClose={setVisibleDrawer}
            dataCalcario={dataCalcario}
            update={update}
            setUpdate={setUpdate}
            refresh={listCalcario}
          />
        }
      >
        <Table
          size='small'
          bordered
          onRow={(data) => {
            return {
              onDoubleClick: () => {
                setUpdate(data.cod)
                setVisibleDrawer(true)
              },
            }
          }}
          loading={loading}
          dataSource={dataCalcario}
          columns={[
            { title: 'Nome', dataIndex: 'nome' },
            { title: 'Nome do fornecedor', dataIndex: 'fonecedor_nome' },
            {
              title: 'Ações',
              dataIndex: 'cod',
              width: 90,
              align: 'center',
              render: (data) => (
                <Space>
                  <Button
                    onClick={() => {
                      setUpdate(data)
                      setVisibleDrawer(true)
                    }}
                    shape='circle'
                    icon={<Icons icon={faPen} />}
                  />
                  <Button
                    danger
                    type='primary'
                    onClick={() => {
                      handleClickDelCalcario(data)
                    }}
                    shape='circle'
                    icon={<Icons icon={faTrash} />}
                  />
                </Space>
              ),
            },
          ]}
          pagination={pagination}
          scroll={{ y: 'calc(100vh - 350px)' }}
        />
      </ViewPage>
    </Container>
  )
}

export default PageCalcario
