import styled from 'styled-components'

export const ContainerTags = styled.div`
  width: 100%;
  height: 100%;

  label {
    font-size: 13px;
    font-weight: 500;
    color: var(--text-color);
    display: inline-flex;
    gap: 5px;
  }

  .box__tags {
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input__add__tags {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .tags__adicionadas{
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    background-color: #F6FFED;
    border: 1px solid #B7EB8F;
    color: #389E0D;
  }

  
`
