
//TYPES
type TypeDadosResumo = {
    layout: any,
    table: { 
        body: {
            alignment: string, 
            fontSize: number, 
            style: string, 
            text: string
        }[][], 
        headerRows: 1, 
        widths: string[]
    }
};
export type TypeEstrutura = {
    planilha: {
        columns: {
            name: string
        }[], 
        rows: (string[] | number[])[]
    }
}

export function gerarEstruturaTabela(dados_resumo: TypeDadosResumo[]): TypeEstrutura[] {

    let lista_nomes_coluna = (dados_resumo??[]).map(item=> item.table.body?.[0]);
    
    const lista_dados_linha = (dados_resumo ?? []).map(item => {
        const bodyEntries = Object.values(item.table.body);

        // Extrair os textos diretamente, mantendo uma estrutura clara
        return (bodyEntries??[]).map(row => row.map(cell => cell.text));
    });
    
    let linha_sem_header = (lista_dados_linha?.[0]??[]).filter((item, index)=> {
        if (index!==0) {
            return item
        }
    })
    
    let estrutura: TypeEstrutura[] = [
        {
            planilha: {
                columns: (lista_nomes_coluna?.[0] ?? []).map(item=> { return { name: item.text } }),
                rows: linha_sem_header
            }
        }
    ];

    return estrutura ?? [];
}