import { Button, Popconfirm } from "antd";
import React from "react";
import { Inline } from "../inline";
import ButtonCustom from "../../ButtonCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TooltipPlacement } from "antd/lib/tooltip";

//PROPS
interface Props {
    /**Controla se popover está aberto ou fechado */
    open: boolean;
    /**`setState` usado para abrir e fechar o popover*/
    trigger: React.Dispatch<React.SetStateAction<boolean>>;
    /**`Função` que será chamada ao abrir o popover */
    onTrigger?: Function;
    /**Título do popover 
     * 
     * [ default: Tem certeza que deseja excluir este item? ]*/
    title?: string;
    /**Mensagem ou descrição do popopver (texto abaixo do título) */
    message: string | React.ReactNode;
    /**Palavras que serão destacadas em `negrito` */
    deleteds?: string[];
    /**Controla o estado de loading */
    loading: boolean;
    /**Se `true` desabilita o botão do popover */
    disabled?: boolean;
    /**Se `true` desabilita o botão principal (botão trigger) */
    disabledTrigger?: boolean;
    /**`Função` chamada ao confirmar exclusão */
    onDelete: Function;
    /**Posição do popover  
     * 
     * [ default: 'topRight' ] */
    placement?: TooltipPlacement;
    /**Tipo de estilo do botão principal (botão trigger) 
     * 
     * [ default:  'tertiary' ] 
    */
    typeButtonTrigger?: 'primary' | 'secondary' | 'tertiary';
    /**Se `true` deixa o botão `cancelar` com a cor vemelha */
    dangerButtonCancel?: boolean;
    /**Texto do botão principal (botão trigger) */
    textButtonTrigger?: string;
    /**Se `true` esconde esconde o botão principal (não renderiza) */
    hidden?: boolean;
    /**Se `true` desmonta o componente de popover */
    destroyTooltipOnHide?: boolean;
}

export const DeleteButton: React.FC<Props> = ({ open, trigger, title, loading, onDelete, onTrigger, hidden,
    placement, disabled, disabledTrigger, typeButtonTrigger, dangerButtonCancel, message, deleteds, textButtonTrigger, 
    destroyTooltipOnHide
 }) => {

    function destacarPalavraMensagem( message, deleteds) {
        if (typeof message !== 'string' || !Array.isArray(deleteds) || deleteds.length === 0) {
            return <span>{message}</span>;
        }
    
        // Escapa palavras para serem usadas em regex
        const escapedWords = deleteds.map(word => String(word).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    
        // Cria uma expressão regular para encontrar as palavras
        const regex = new RegExp(`(${escapedWords.join('|')})`, 'gi');
    
        // Divide a mensagem com base na regex
        const parts = message.split(regex);
    
        // Renderiza cada parte, aplicando <b> nas palavras encontradas
        return (
            <span>
                {parts.map((part, index) =>
                    regex.test(part) ? (
                        <b key={index}>{part}</b>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    }

    if (hidden) {
        return null;
    }

    return (
        <Popconfirm
            // key={gerarKey(index)}
            destroyTooltipOnHide={destroyTooltipOnHide??false}
            placement={placement ?? "topRight"}
            title={title ?? 'Tem certeza que deseja excluir este item?'}
            open={open}
            description={
            <div style={{display:'flex',flexDirection:'column',gap:15}}>
                <span>{destacarPalavraMensagem(message, deleteds)}</span>
                <Inline justifyContent="flex-end">

                    <ButtonCustom
                        onClick={()=>{trigger(false)}}
                        type={"tertiary"}
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        disabled={disabled}
                        danger={dangerButtonCancel ?? false}
                    >
                        Cancelar
                    </ButtonCustom>

                    <ButtonCustom 
                        onClick={onDelete}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        danger
                        loading={loading}
                        disabled={disabled}
                    >
                        Excluir
                    </ButtonCustom>

                </Inline>
            </div>}
            okButtonProps={{style: {display:'none'}}}
            cancelButtonProps={{style: {display:'none'}}}
        >
            <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                <ButtonCustom
                    onClick={()=>{onTrigger?.();trigger(true)}}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    type={typeButtonTrigger ?? "tertiary"}
                    disabled={disabledTrigger}
                    danger
                    // refProp={refDeletarBtn}
                >
                    {textButtonTrigger}
                </ButtonCustom>
            </Button>
        </Popconfirm>
    )
}