import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetCliente {
  itens: [ClienteItensTypes]
}

export interface TypesGetConsultoria {
  itens: [ConsultoriaItensTypes]
}

export interface TypePropriedadesCliente {
  cod: number
  nome: string
  status: number
  cod_cidade: number
  cidade: [
    {
      cod: number
      codigo: number
      nome: string
      uf: string
      cod_uf: number
      status: number
      uf_nome: string
      uf_sigla: string
    }
  ]
}

export interface ClienteItensTypes {
  propriedades?: TypePropriedadesCliente[]
  cidade_cod_uf?: number
  cod?: number
  cod_conta?: number
  nome?: string
  cpf_cnpj?: number
  rg?: number
  uf_rg?: string
  nacionalidade?: string
  tipo?: 'F' | 'J' | 'G'
  sexo?: 'M' | 'F'
  nascimento?: string | any
  cod_escolaridade?: number
  cod_estado_civil?: number
  cod_profissao?: number
  endereco?: number
  cidade?: {
    cod: number
    cod_uf: number
    codigo: number
    nome: string
    status: 1 | 0
    uf: string
    uf_nome: string
    uf_sigla: string
  }
  complemento?: string
  update_user?: string
  create_user?: string
  cep?: string
  cod_cidade?: number
  fone?: string
  fone2?: string
  email?: string
  status?: number
  solicitante?: string
  razao_social?: string
  logo?: string
  consultoria?: number
  linha1?: string
  linha2?: null
  create_time?: string
  update_time?: string
  delete_time?: string
  bairro?: string
  endereco_numero?: string
  inscricao_estadual?: string
}

export interface ConsultoriaItensTypes {
  cod?: number
  nome?: string
  status?: number
  cpf_cnpj?: string
  rg?: string
  uf_rg?: string
  nacionalidade?: string
  tipo?: number
  sexo?: string
  nascimento?: string
  cod_escolaridade?: number
  cod_estado_civil?: number
  cod_profissao?: number
  endereco?: string
  complemento?: string
  cep?: string
  cod_cidade?: number
  fone?: string
  fone2?: string
  email?: string
  razao_social?: string
  linha2?: string
  linha1?: string
  logo?: string
  logo_url?: string
  cidade?: {
    cod?: number
    codigo?: number
    nome?: string
    uf?: string
    cod_uf?: number
    status?: number
    uf_nome?: string
    uf_sigla?: string
  }
}

export const getCliente = async (codCliente, nomeCliente, orderCliente, embed) => {
  const config = localConfig()
  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetCliente> = await api.get('/cliente', {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      cod: codCliente,
      nome: nomeCliente,
      order: orderCliente,
      embed: embed,
      pagination: -1,
    },
  })
  return response
}

export const getConsultoria = async (codConta = null) => {
  const config = localConfig()
  api.defaults.headers.Acesso = codConta !== null ? 'conta' : config.acesso

  const response: AxiosResponse<TypesGetConsultoria> = await api.get(
    `/conta?codConta=${codConta !== null ? codConta : config.conta}`
  )

  return response
}

export const getClienteBook = async (codConta = null, codCliente = null) => {
  const config = localConfig()
  api.defaults.headers.Acesso = codCliente !== null ? 'cliente' : config.acesso

  const response: AxiosResponse<TypeGetCliente> = await api.get(
    `/cliente?codConta=1&codCliente=${codCliente !== null ? codCliente : config.codCliente}`
  )

  return response
}

export const postCliente = async (data) => {
  const conta = localStorage.getItem('conta')
  const response: AxiosResponse<TypeGetCliente> = await api.post(`/cliente?codConta=${conta}`, data)

  return response
}

export const putCliente = async (codCliente, data) => {
  const conta = localStorage.getItem('conta')
  const response: AxiosResponse<TypeGetCliente> = await api.put(
    `/cliente/${codCliente}?codConta=${conta}`,
    data
  )

  return response
}

export const delCliente = async (codConta, codCliente) => {
  const response: AxiosResponse<TypeGetCliente> = await api.delete(
    `/cliente/${codCliente}?codConta=${codConta}`
  )

  return response
}
