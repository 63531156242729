import { Dayjs } from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useEffect, useState } from "react";
import { faBook, faMap, faShapes, faUser } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { ContainerGB } from "./style";

//APP
import { removerAcentos } from "../../../../utils";
import { lista_tab_tipo_config } from "./meta";
import { ItensTipoConsultorTypes } from "../../../../services/consultoriaAgricola/tipoConsultor";
import { MoniNivelItemControleItens } from "../../../../services/monitoramento/nivelControleItens";

//COMPONENTS
import Tabs from "../../../Tabs";
import Input from "../../../CustomUi/input";
import Carregando from "../../../CustomUi/loading";
import DatePicker from "../../../DatePiker";
import ButtonCustom from "../../../ButtonCustom";
import { Gap } from "../../../CustomUi/gap";
import { Box } from "../../../CustomUi/box";
import { Render } from "../../../CustomUi/render";
import { Inline } from "../../../CustomUi/inline";
import { Select } from "../../../CustomUi/Select";
import { TitleH4 } from "../../../CustomUi/titleH4";
import { Warning } from "../../../CustomUi/warning";
import { RowItem } from "../../../CustomUi/table/rowItem";
import { Switcher } from "../../../CustomUi/Switcher";
import { RowTable } from "../../../CustomUi/table/rowTable";
import { BodyTable } from "../../../CustomUi/table/bodyTable";
import { HeaderTable } from "../../../CustomUi/table/headerTable";
import { SelectMulti } from "../../../CustomUi/SelectMulti";
import { orderBy } from "lodash";

//PROPS
interface Props {
    dataAtual: any;
    handleDisableDate: (date: Dayjs) => boolean;
    handleChangeDataBooking: (geraBook: boolean, dateString: any) => Promise<void>;
    codConsultor: string;
    setCodConsultor: React.Dispatch<React.SetStateAction<string|number>>;
    lista_consultores: ItensTipoConsultorTypes[];
    lista_talhoes: any[];
    lista_configuracao: { label: string, value: boolean }[];
    lista_check_pragas: string[];
    nivelControle: MoniNivelItemControleItens[];
    lista_checkados: CheckboxValueType[];
    setCheckedList: React.Dispatch<React.SetStateAction<CheckboxValueType[]>>;
    submitGerarBook: Function;
    tipoAcesso: string;
    handleChangeOrdem: (data: boolean) => void
}

export const GerarBookMapaCalor: React.FC<Props> = ({ handleDisableDate, dataAtual, handleChangeDataBooking, 
    codConsultor, setCodConsultor, lista_consultores, lista_talhoes, lista_configuracao, lista_check_pragas, 
    nivelControle, lista_checkados, setCheckedList, submitGerarBook, tipoAcesso, handleChangeOrdem }) => {

    //STATES --> CONSULTOR
    const [open_consultor, setOpen_consultor] = useState<boolean>(false);
    const [nome_consultor, setNome_consultor] = useState<string>('');
    //STATES --> TALHÃO
    const [open_talhao, setOpen_talhao] = useState<boolean>(false);
    const [talhoes_selecionados, setTalhoes_selecionados] = useState<{descr: string, id: number, key?: number}[]>([]);
    //STATES --> TIPO DE MAPA
    const [open_tipo_mapa, setOpen_tipo_mapa] = useState<boolean>(false);
    const [nome_tipo_mapa, setNome_tipo_mapa] = useState<string>((google?.maps?.MapTypeId?.SATELLITE)?.replace('satellite','Terreno') ?? '');
    //STATES --> CONFIGURAÇÃO
    const [nome_config, setNome_config] = useState<string>('Por talhão');
    //STATES --> BUSCA PRAGA
    const [buscar_praga, setBuscar_praga] = useState<string>('');
    const [selecioar_todos, setSelecioar_todos] = useState<boolean>(true);
    //STATES --> GERAR
    const [loading_gerar_book, setLoading_gerar_book] = useState<boolean>(false);

    //FUNCTIONS
    function selecionarOuDeselecionarPraga(nome_e_codigos_praga: string) {
        let ja_foi_selecionado = (lista_checkados.find(item_praga=> item_praga === nome_e_codigos_praga) !== undefined);

        if (ja_foi_selecionado) {
            let remover_selecionado = lista_checkados.filter(item_praga=> item_praga !== nome_e_codigos_praga);
            setCheckedList(remover_selecionado);
            return;
        } 

        setCheckedList(lista_checkados=> [...lista_checkados, nome_e_codigos_praga]);
    }

    function selecionarDeselecionarTodos() {
        if (lista_checkados.length === lista_check_pragas.length) {
            setSelecioar_todos(false);
            setCheckedList([]);
        }
        if (lista_checkados.length < lista_check_pragas.length) {
            setSelecioar_todos(true);
            setCheckedList(lista_check_pragas);
        }
    }

    function aplicarGerarBook() {
        setLoading_gerar_book(true);
        let lista_cod_talhoes = talhoes_selecionados.map((item=> item.id));

        setTimeout(() => {
            submitGerarBook({
                codTalhao: lista_cod_talhoes ?? [],
                consultorName: nome_consultor,
                dateBook: dataAtual,
                listEspecieVariacao: lista_checkados,
                mapType: nome_tipo_mapa
            });
        }, 300);
        
    }

    function buscarPraga(buscar_nome: string) {
        const palavra_digitada_sem_aceanto = removerAcentos(buscar_nome);
        return function (item) {
            const split_nome = item?.split(', ')?.[0];
            const nome_especie_sem_acentos = removerAcentos(String(split_nome));

            return nome_especie_sem_acentos.toLowerCase().includes(palavra_digitada_sem_aceanto.toLowerCase()) || !buscar_nome
        }
    }

    function validarConsultor() {  
        const tipoAcesso = localStorage.getItem('tipo-acesso');
        if (tipoAcesso === 'conta' && nome_consultor==='') {
            return true;
        }
        return false;
    }

    //EFFECTS
    useEffect(() => {
        if (lista_checkados.length === lista_check_pragas.length) {
            setSelecioar_todos(true);
        }
        if (lista_checkados.length < lista_check_pragas.length) {
            setSelecioar_todos(false);
        }

    }, [lista_checkados, lista_check_pragas]);
    

    return (
        <ContainerGB>
            <Warning type="warning">
                Gerar o book pode levar alguns segundos, dependendo da quantidade de informações!
            </Warning>

            <div className="ContainerGB__conteudo">
                <Box style={{width: 300}} gap={15}>
                    <TitleH4> Filtros do book </TitleH4>
         
                    <Tabs 
                        label="Tipo de visualização"
                        labelFontSize={13}
                        tabs={lista_tab_tipo_config(lista_configuracao, handleChangeOrdem)}
                        selecionado={nome_config}
                        onChange={setNome_config}
                        
                    />

                    <div className="ContainerGB__conteudo__datePicker">
                        <label htmlFor="date-picker">Data do monitoramento</label>
                        <DatePicker
                            style={{ width: '100%' }}
                            disabledDate={handleDisableDate}
                            defaultValue={dataAtual}
                            onChange={(_, date) => handleChangeDataBooking(false, date)}
                            placeholder='Selecione uma data'
                        />
                    </div>
                    
                    <Render condition={tipoAcesso==='conta'}>
                        <Select 
                            label="Consultor responsável"
                            placeholder="Selecione o consultor responsável"
                            descricaoItem="usuario_nome"
                            idItem="cod"
                            items={orderBy((lista_consultores ?? []), 'usuario_nome', ['asc'])}
                            // items={lista_consultores}
                            open={open_consultor}
                            trigger={setOpen_consultor}
                            onChange={setNome_consultor}
                            onChangeID={setCodConsultor}
                            value={nome_consultor}
                            icon={faUser}
                        />
                    </Render>
                    
                    <SelectMulti 
                        label="Talhão"
                        opcional
                        placeholder="Selecione o talhão"
                        descricaoItem="nome"
                        idItem="cod"
                        items={lista_talhoes}
                        open={open_talhao}
                        trigger={setOpen_talhao}
                        // value={nome_talhao}
                        icon={faShapes}
                        hasTalhaoSVG
                        talhaoSvgPath={['coordenadas']}
                        disabled={validarConsultor()}
                        selecteds={talhoes_selecionados}
                        onChange={setTalhoes_selecionados}
                    />
                                       
                    <Select 
                        label="Tipo de mapa"
                        placeholder="Selecione o mapa"
                        descricaoItem="label"
                        items={[
                            { label: 'Terreno', value: google.maps.MapTypeId.SATELLITE },
                            { label: 'Sem terreno', value: google.maps.MapTypeId.TERRAIN }
                        ]}
                        idItem="none"
                        open={open_tipo_mapa}
                        trigger={setOpen_tipo_mapa}
                        onChange={setNome_tipo_mapa}
                        value={nome_tipo_mapa}
                        icon={faMap}
                    />

                    <Gap type="row" value={10} />

                </Box>

                <Box style={{width: 'calc(100% - 315px)'}}>
                    <TitleH4> Pragas que serão exibidas no book </TitleH4>

                    <Inline>
                        <Input 
                            placeholder="Buscar por praga ou variação"
                            value={buscar_praga}
                            onChange={setBuscar_praga}
                            width={340}
                        />

                        <Switcher 
                            label='Selecionar todos'
                            isChecked={selecioar_todos}
                            trigger={()=>{}}
                            task={()=>selecionarDeselecionarTodos()}
                        />
                    </Inline>
                    
                    <HeaderTable 
                        itens={[
                            {valor: 'Exibir', width: 80},
                            {valor: 'Praga', width: 235},
                            {valor: 'Variação', width: 210},
                        ]}
                    />

                    <BodyTable style={{marginTop:-10, overflowY: 'scroll', height: 350}}>
                        {(lista_check_pragas ?? []).filter(buscarPraga(buscar_praga)).map((item)=> {

                            const split_nome_e_codigos = item.split(', ');
                            const cod_especie = Number(split_nome_e_codigos[1]);
                            const cod_variacao_especie = split_nome_e_codigos[2] === 'null' ? null : Number(split_nome_e_codigos[2]);

                            const nivel = nivelControle.find(
                                (item_nivel) => item_nivel?.cod_especie === cod_especie && cod_variacao_especie === item_nivel?.cod_variacao_especie
                            );

                            function isChecked(nome_praga: string): boolean {
                                let ta_na_lista = lista_checkados.find(item_check=> item_check === nome_praga);
                                return ta_na_lista !== undefined;
                            };

                            let opacity: number = isChecked(item) ? 1 : 0.6

                            return (
                                <RowTable key={item} onDoubleClick={()=>selecionarOuDeselecionarPraga(item)}>
                                    <RowItem width={80}>
                                        <Switcher 
                                            isChecked={isChecked(item)}
                                            trigger={()=>{}}
                                            task={()=>selecionarOuDeselecionarPraga(item)}
                                        />
                                    </RowItem>
                                    <RowItem width={235} style={{opacity: opacity}} >
                                        {nivel?.especie_nome}
                                    </RowItem>
                                    <RowItem width={210} style={{opacity: opacity}} >
                                        {cod_variacao_especie!==null && <>{nivel?.variacao_especie_nome}</>}
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </BodyTable>

                </Box>
            </div>

            <div className="ContainerGB__footer">
                <ButtonCustom 
                    icon={<FontAwesomeIcon icon={faBook} />}
                    onClick={()=>{aplicarGerarBook()}}
                    disabled={lista_checkados.length === 0 || validarConsultor() || dataAtual==='' || loading_gerar_book}
                    loading={loading_gerar_book}
                >
                    Gerar book
                </ButtonCustom>
            </div>

            <Render condition={loading_gerar_book}>
                <div className="ContainerGB__loading">
                    <Carregando 
                        animation 
                        legenda="Gerando book de monitoramento..."
                        justifyContent="center"
                    />
                </div>
            </Render>          
            

        </ContainerGB>
    )
};