import React, { useState } from 'react'
import { Empty, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

//APP
import { removerAcentos } from '../../../../../../utils'
import { Box } from '../../../../../../Components/CustomUi/box'
import { TitleH2 } from '../../../../../../Components/CustomUi/titleH2'
import { Inline } from '../../../../../../Components/CustomUi/inline'
import ButtonCustom from '../../../../../../Components/ButtonCustom'
import { HeaderTable } from '../../../../../../Components/CustomUi/table/headerTable'
import { BodyTable } from '../../../../../../Components/CustomUi/table/bodyTable'
import { Render } from '../../../../../../Components/CustomUi/render'
import { RowTable } from '../../../../../../Components/CustomUi/table/rowTable'
import { RowItem } from '../../../../../../Components/CustomUi/table/rowItem'
import Carregando from '../../../../../../Components/CustomUi/loading'
import Input from '../../../../../../Components/CustomUi/input'
import { DeleteButton } from '../../../../../../Components/CustomUi/deleteButton'
import { ItensPerUserLogged } from '../../../../../../services/login/permissaoUsuarioLoado'

//COMPONENTS

//PROPS
interface Props {
  loading: boolean
  list_fornecedores: any[]
  deletar: Function
  abrir?: Function
  editar?: Function
  permissao: ItensPerUserLogged
  setPagination?: React.Dispatch<React.SetStateAction<number>>
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  total: number
  confirmar_exclusao: boolean
  setConfirmar_exclusao: React.Dispatch<React.SetStateAction<boolean>>
  id_item_atual: number
  setId_item_atual: React.Dispatch<React.SetStateAction<number>>
  nome_item_atual: string
  setNome_item_atual: React.Dispatch<React.SetStateAction<string>>
  fake_loading: boolean
  setFake_loading: React.Dispatch<React.SetStateAction<boolean>>
}

export const TabelaFornecedores: React.FC<Props> = ({
  list_fornecedores,
  loading,
  deletar,
  abrir,
  setPagination,
  editar,
  page,
  setPage,
  total,
  confirmar_exclusao,
  setConfirmar_exclusao,
  id_item_atual,
  setId_item_atual,
  nome_item_atual,
  setNome_item_atual,
  fake_loading,
  setFake_loading,
  permissao
}) => {
  const [buscarTabela, setBuscarTabela] = useState('')

  function buscarFornecedores(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const tagSemAcentos = removerAcentos(String(item?.tags))
      const nomeSemAcentos = removerAcentos(String(item?.nome))

      return (
        nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        tagSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        !buscar_nome
      )
    }
  }

  // const fornecedoresFiltrados = list_fornecedores.filter((item) =>
  //   item.tags?.some((produto) => produto.toLowerCase().includes(buscarTabela.toLowerCase()))
  // )

  // console.log('fornecedor', fornecedoresFiltrados)

  // function removerUltimosDigitos(value) {
  //   if (!value) return ''
  //   // Remove qualquer caractere que não seja número
  //   const numericValue = value.replace(/\D/g, '')
  //   // Retorna o valor sem os dois últimos dígitos
  //   return numericValue.slice(0, -2)
  // }

  return (
    <Box style={{ height: 'calc(100vh - 170px)' }}>
      <Inline alignItems='center'>
        <Input
          placeholder='Buscar fornecedores/produtos e serviços'
          value={buscarTabela}
          onChange={setBuscarTabela}
        />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrir}>
          Novo
        </ButtonCustom>
      </Inline>
      <HeaderTable
        itens={[
          { valor: 'Nome fornecedores', width: 'calc(100% - 576px)' },
          { valor: 'Produtos/serviços', width: '400px' },
          { valor: 'Ações', width: '176px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: (list_fornecedores ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(list_fornecedores ?? [])
            .filter(buscarFornecedores(buscarTabela))
            .map(({ cod, nome, tags }) => {
              return (
                <RowTable
                  key={cod}
                  onDoubleClick={() => editar(cod, false)}
                  style={{ height: (tags ?? []).length > 7 && 'auto' }}
                >
                  <RowItem fontSize={13} width={'calc(100% - 570px)'}>
                    {nome}
                  </RowItem>
                  <RowItem
                    fontSize={13}
                    width={'400px'}
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                  >
                    {tags
                      ?.filter((word) => word !== '')
                      .map((item) => {
                        return (
                          <Tag color='green' key={item} style={{ marginRight: 5 }}>
                            {item}
                          </Tag>
                        )
                      })}
                  </RowItem>
                  <RowItem width={'170px'} gap={10}>
                    <DeleteButton
                      open={confirmar_exclusao && id_item_atual === cod}
                      trigger={setConfirmar_exclusao}
                      onTrigger={() => {
                        setId_item_atual(cod)
                        setNome_item_atual(nome)
                      }}
                      loading={fake_loading}
                      message={`o item ${nome} será excluido!`}
                      deleteds={[nome]}
                      onDelete={() => {
                        deletar(cod)
                      }}
                      disabled={fake_loading}
                      disabledTrigger={permissao?.exclusao === 0}
                    />
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => {
                        editar(cod, false)
                      }}
                      disabled={permissao?.alteracao === 0 && permissao?.acesso === 0}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={list_fornecedores.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando fornecedores...'
            justifyContent='center'
            height={400}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
