export const combustiveis = [
  {
    cod: 1,
    nome: 'Gasolina',
  },
  {
    cod: 2,
    nome: 'Etanol',
  },
  {
    cod: 3,
    nome: 'Diesel S-500',
  },
  {
    cod: 4,
    nome: 'Diesel S-10',
  },
  {
    cod: 5,
    nome: 'Flex',
  },
]

export const dataTipoOrigem = [
  { cod: 1, nome: 'Próprio' },
  { cod: 2, nome: 'Locado' },
  { cod: 3, nome: 'Empréstimo' },
  { cod: 4, nome: 'Doação' },
]


