import styled from "styled-components";

export const Aviso = styled.div`
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 7px 10px;
    background: var(--hover-color);
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);

    :is([data-type = 'success']) {
        background: rgba(137, 197, 88, 0.1);
        color: #8AC558;
    }
    :is([data-type = 'danger']) {
        background: rgba(255, 77, 79, 0.1);
        color: #FF4D4F;
    }
    :is([data-type = 'error']) {
        background: rgba(255, 77, 79, 0.1);
        color: #FF4D4F;
    }
    :is([data-type = 'warning']) {
        background: #FFF0D9;
        color: #DF8600;
    }
    :is([data-type = 'info']) {
        background: #bdd1fc;
        color: #2F54EB;
    }

`