import React, { useState } from 'react'
import { Button, Empty, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'

//STYLES

//APP
import { mascaraOdom } from '../../../../../services/Afins'
import { gerarKey, removerAcentos } from '../../../../../utils'

//COMPONENTS
import Input from '../../../../../Components/CustomUi/input'
import Carregando from '../../../../../Components/CustomUi/loading'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { Box } from '../../../../../Components/CustomUi/box'
import { Render } from '../../../../../Components/CustomUi/render'
import { Inline } from '../../../../../Components/CustomUi/inline'
import { TitleH2 } from '../../../../../Components/CustomUi/titleH2'
import { RowItem } from '../../../../../Components/CustomUi/table/rowItem'
import { Switcher } from '../../../../../Components/CustomUi/Switcher'
import { RowTable } from '../../../../../Components/CustomUi/table/rowTable'
import { BodyTable } from '../../../../../Components/CustomUi/table/bodyTable'
import { HeaderTable } from '../../../../../Components/CustomUi/table/headerTable'

//PROPS
interface Props {
  loading: boolean
  inativos: boolean
  setInativos: React.Dispatch<React.SetStateAction<boolean>>
  list_patrimonios: any[]
  handleAlteraStatus: Function
  deletarPatrimonio: Function
  abrir?: Function
  editar?: Function
  setPagination?: React.Dispatch<React.SetStateAction<number>>
  page?: number
  setPage?: React.Dispatch<React.SetStateAction<number>>
  total: number
}

export const TabelaPatrimonios: React.FC<Props> = ({
  list_patrimonios,
  loading,
  inativos,
  setInativos,
  handleAlteraStatus,
  deletarPatrimonio,
  abrir,
  setPagination,
  editar,
  page,
  setPage,
  total,
}) => {

  //STATES
  const [buscarTabela, setBuscarTabela] = useState('');
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false);
  const [id_item_atual, setId_item_atual] = useState<number>(null);
  const [nome_item_atual, setNome_item_atual] = useState<string>('');
  const [fake_loading, setFake_loading] = useState<boolean>(false);

  function buscarPatrimonios(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.nome))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  function removerUltimosDigitos(value) {
    if (!value) return ''
    // Remove qualquer caractere que não seja número
    const numericValue = value.replace(/\D/g, '')
    // Retorna o valor sem os dois últimos dígitos
    return numericValue.slice(0, -2)
  }

  function deletarPatrimonioTemp(cod: number) {
    setFake_loading(true);
    deletarPatrimonio(cod);

    setTimeout(() => {
      setFake_loading(false);
    }, 3000);

  }

  return (
    <Box style={{height: 'calc(100vh - 170px)', border: 'none'}} padding={0} borderless >
      {/* <TitleH2>Lista de patrimônios</TitleH2> */}

      <Inline alignItems='center'>
        <Input placeholder='Buscar patrimônio' value={buscarTabela} onChange={setBuscarTabela} />
        <ButtonCustom icon={<FontAwesomeIcon icon={faPlus} />} onClick={abrir}>
          Novo
        </ButtonCustom>
      </Inline>
      <Switcher isChecked={inativos} trigger={setInativos} label='Ocultar patrimônios inativos' />
      <HeaderTable
        itens={[
          { valor: 'Ativo', width: '80.5px' },
          { valor: 'Frota', width: '70.5px' },
          { valor: 'Nome', width: 'calc(100% - 977px)' },
          { valor: 'Responsável', width: '200px' },
          { valor: 'Hodômetro/Horímetro', width: '200px' },
          { valor: 'Categoria', width: '200px' },
          { valor: 'Ações', width: '220px' },
        ]}
      />

      <BodyTable
        paginate={total}
        onChangeItensPage={setPagination}
        onChangePage={setPage}
        current_page={page}
        setCurrent_page={setPage}
        style={{
          height: 'calc(100vh - 170px)',
          // height: (list_patrimonios ?? []).length < 8 && 'calc(100vh - 170px)',
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'scroll',
          marginTop: -10,
        }}
      >
        <Render condition={!loading}>
          {(list_patrimonios ?? [])
            .filter(buscarPatrimonios(buscarTabela))
            .map(({ cod, nome, frota, responsavel, odom_horimetro, tipo_nome, status }, index) => {
              return (
                <RowTable key={cod} onDoubleClick={() => editar(cod, false)}>
                  <RowItem width={'80px'}>
                    <Switcher isChecked={status} trigger={() => handleAlteraStatus(status, cod)} />
                  </RowItem>
                  <RowItem fontSize={13} width={'70px'}>
                    {frota}
                  </RowItem>
                  <RowItem fontSize={13} width={'calc(100% - 970px)'}>
                    {nome}
                  </RowItem>
                  <RowItem fontSize={13} width={'200px'}>
                    {responsavel?.[0]?.nome}
                  </RowItem>
                  <RowItem width={'200px'}>{`${mascaraOdom(removerUltimosDigitos(odom_horimetro))} ${ odom_horimetro ? 'Km' : odom_horimetro === 'Horímetro' ? 'Horas' : ''}`}</RowItem>
                  <RowItem fontSize={13} width={'200px'}>{tipo_nome}</RowItem>
                  <RowItem width={'220px'} gap={10}>
                    <Popconfirm
                      key={gerarKey(index)}
                      placement="topRight"
                      title={'Tem certeza que deseja deletar este item?'}
                      open={confirmar_exclusao && id_item_atual=== cod}
                      description={
                      <div style={{display:'flex',flexDirection:'column',gap:15}}>
                          <span>o item <b><i>{nome_item_atual}</i></b> será deletado!</span>
                          <Inline justifyContent="flex-end">

                              <ButtonCustom 
                                  onClick={()=>{setConfirmar_exclusao(false)}}
                                  type="tertiary"
                                  icon={<FontAwesomeIcon icon={faXmark} />}
                                  disabled={fake_loading}
                              >
                                  Cancelar
                              </ButtonCustom>

                              <ButtonCustom 
                                  onClick={()=>{deletarPatrimonioTemp(cod)}}
                                  icon={<FontAwesomeIcon icon={faTrash} />}
                                  danger
                                  loading={fake_loading}
                                  disabled={fake_loading}
                              >
                                  Deletar
                              </ButtonCustom>

                          </Inline>
                      </div>}
                      okButtonProps={{style: {display:'none'}}}
                      cancelButtonProps={{style: {display:'none'}}}
                  >
                      <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                          <ButtonCustom
                              onClick={()=>{setId_item_atual(cod);setNome_item_atual(nome);setConfirmar_exclusao(true)}}
                              icon={<FontAwesomeIcon icon={faTrash} />}
                              type="tertiary"
                              danger
                              // refProp={refDeletarBtn}
                          />  
                      </Button>
                  </Popconfirm>
                    
                    <ButtonCustom
                      type='secondary'
                      icon={<FontAwesomeIcon icon={faClone} />}
                      onClick={() => editar(cod, true)}
                      
                    ></ButtonCustom>
                    <ButtonCustom
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={() => {editar(cod, false)}}
                    >
                      Editar
                    </ButtonCustom>
                  </RowItem>
                </RowTable>
              )
            })}
        </Render>

        <Render condition={list_patrimonios.length === 0 && !loading}>
          {buscarTabela === '' ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<> Nenhum registro disponível!</>}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  {' '}
                  <b>'{buscarTabela}'</b> não encontrado!
                </>
              }
            />
          )}
        </Render>

        <Render condition={loading}>
          <Carregando
            animation
            legenda='Carregando patrimônios...'
            justifyContent='center'
            height={400}
            size='default'
          />
        </Render>
      </BodyTable>
    </Box>
  )
}
