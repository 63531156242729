import { faMapLocation, faShapes } from "@fortawesome/free-solid-svg-icons"


export function lista_tab_tipo_config(lista_configuracao: { label: string, value: boolean }[], handleChangeOrdem: Function): {icone: any, descricao: string, disabled?: boolean, disabledMsg?: string, task?: Function, width?: number}[] {
    return lista_configuracao.map(({label, value}, index)=> {
        return {
            descricao: index===0 ? 'Por talhão' : 'Por Propriedade',
            icone: index===0 ? faShapes : faMapLocation,
            width: index===0 ? 115 : 152,
            task: ()=> handleChangeOrdem(value),
        }
    })
};