/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import Icon, { CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import { UploadChangeParam } from 'antd/lib/upload';
import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { Button, Card, Col, Input, InputRef, List, message, Modal, Popconfirm, Progress, Row, Space, Upload } from 'antd';
import { faCheck, faCircleExclamation, faDownload, faPen, faPenToSquare, faPlus as fasPlus, faSync, faTrash, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
import { kml } from '@tmcw/togeojson';

//STYLES
import { ContainerMap } from './styled';

//ASSETS
import Svg from '../talhasvg';
import { IconAlert } from '../iconsAlert';

//APP
import { geralKmlPolygon } from '../gerarKml';
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado';
import { TalhaoCultivaveisItens } from '../../services/TalhaoSafra';
import { delSubArea, postSubArea, putSubArea, TypeSubArea as SubAreaTypes } from '../../services/subArea';
import { calculoAreaCoord, centerMap, customRequest, fileTypeText, getBounds, getBoundsNoArray, parseXml, tryError } from '../../services/Afins';

//COMPONENTS
import Icons from '../Icons';
import DiasDesdeData from '../DiasDesdeData';
import { Render } from '../CustomUi/render';
import { Notification } from '../notification';
import { removerAcentos } from '../../utils';

interface TypesMapa {
  zoom: number
  center:
    | {
        lat: number
        lng: number
      }
    | google.maps.LatLng
  mapContainerStyle?: { [key: string]: string }
}

interface TypesSubArea {
  mapSet?: TypesMapa
  propriedade: number
  onClose: (data: boolean) => void
  listTalhoesRecord: TalhaoCultivaveisItens[]
  listTalhaoSafra: (data: number) => void
  update: number
  permissions: ItensPerUserLogged
  visible: boolean
}

const SubArea: React.FC<TypesSubArea> = ({
  mapSet = {
    zoom: 2,
    center: {
      lat: 30.47664953933721,
      lng: -46.05009982090035,
    },
    mapContainerStyle: { width: '100%', height: '78vh' },
  },
  onClose,
  listTalhoesRecord,
  listTalhaoSafra,
  update,
  visible,
  permissions,
}) => {
  const scrollTalhao = useRef<HTMLDivElement>()

  const url = useRef(null)

  const refInputNameSubTalhao = useRef<InputRef>()

  const listSubArea = useRef<HTMLDivElement>()

  const [flutuantDiv, setFlutuantDiv] = useState(false)
  const [recordInit, setRecordInit] = useState(false)

  const [map, setMap] = useState<google.maps.Map>()

  const [listTalhao, setListTalhao] = useState(listTalhoesRecord)

  const [postProgress, setPostProgress] = useState(0)

  const [talhaoSelect, setTalhaoSelect] = useState(0)

  const [indexTalhaoSelect, setIndexTalhaoSelect] = useState(0)

  const [namesExistente, setNamesExistente] = useState([])

  const [nameSubArea, setNameSubArea] = useState('')

  const [editIndexSubTalhao, setEditIndexSubTalhao] = useState<number>()

  const [progressModal, setProgressModal] = useState(false)

  const [propsMap, setPropsMapa] = useState<TypesMapa>(mapSet)

  const [coord, setCoord] = useState([])
  const [stateSubArea, setStateSubArea] = useState([])
  const [linePolyline, setLinePolyline] = useState<google.maps.LatLng[]>([])

  const [polyline, setPolyline] = useState<google.maps.Polyline>()

  const [marker, setMarker] = useState<google.maps.LatLngLiteral[]>([])

  const [polySubTalhao, setPolySubTalhao] = useState([])

  const [recordMap, setRecordMap] = useState(false)
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const handleUnmountMap = () => {
    //
  }

  const handleClickHiddenFlutuant = (data: boolean) => {
    setFlutuantDiv(!data)
  }

  const handleClickPolygon = (data: google.maps.PolyMouseEvent) => {
    const lines = linePolyline
    const { latLng } = data

    const newPath = [...lines, latLng]

    setLinePolyline(newPath)
    setRecordInit(true)
  }

  const handleMouseUpPolyline = (data: google.maps.PolyMouseEvent, nome) => {
    let line = linePolyline
    const inicio = line[0].toJSON()
    const fim = data.latLng.toJSON()

    for (let i = 0; i < polySubTalhao.length; i += 1) {
      const info = polySubTalhao[i]

      if (info.nome === nome) {
        return true
      }
    }

    if (inicio.lat === fim.lat && inicio.lng === fim.lng) {
      line = [...line, data.latLng]

      let arqui = []
      for (let i = 0; i < line.length; i += 1) {
        const lin = line[i]
        arqui = [...arqui, lin.toJSON()]
      }

      const area = calculoAreaCoord([line])
      const kml = geralKmlPolygon(arqui, removerAcentos(nome))
      const cripto = btoa(kml)

      const arquivo = `data:@file/xml;base64,${cripto}`

      const newArea = {
        cod: null,
        cod_talhao: talhaoSelect,
        nome,
        area,
        arquivo,
        subArea: line,
      }

      onFinish([newArea])

      setPolySubTalhao([newArea, ...polySubTalhao])

      setLinePolyline([])
    }
    return false
  }

  const onFinish = async (data) => {
    try {
      setProgressModal(true)

      let resp = []
      let propriedade = 0
      const subTalhaoPoly = data

      for (let i = 0; i < subTalhaoPoly.length; i += 1) {
        const subTalhao = subTalhaoPoly[i]
        setPostProgress(calcPocentagem(i + 1, subTalhaoPoly.length))

        const dados = {
          cod: subTalhao.cod,
          nome: subTalhao.nome,
          arquivo: subTalhao.arquivo,
          cod_talhao: subTalhao.cod_talhao,
          area: subTalhao.area,
        }

        if (subTalhao.cod === null) {
          const post: AxiosResponse<SubAreaTypes> = await postSubArea(dados)

          subTalhao.cod = post.data.itens[0].cod
          propriedade = post.data.itens[0].cod_propriedade

          resp = [...resp, post]
        } else {
          resp = [...resp, await putSubArea(dados.cod, dados)]
          propriedade = resp[0].data.itens[0].cod_propriedade
        }
      }

      setProgressModal(false)
      setRecordInit(false)
      Notification({
        message: 'Subárea salva com sucesso',
        type: 'success',
        placement: 'top',
      })
      listTalhaoSafra(propriedade)
      return true
    } catch (error) {
      setProgressModal(false)
      tryError(error)
    }
  }

  const calcPocentagem = (qtda, total) => {
    return parseFloat(((qtda * 100) / total).toFixed(2))
  }

  const handleCloseModal = () => {
    if (recordInit) {
      Modal.info({
        title: 'Existem dados que não foram salvos, deseja realmente cancelar?',
        icon: <Icon component={() => <Icons icon={faCircleExclamation} />} />,
        okText: 'Sim',
        cancelText: 'Não',
        okCancel: true,
        onOk: (close) => {
          update = 0
          setEditIndexSubTalhao(null)
          setPolySubTalhao([])
          setLinePolyline([])
          setRecordMap(false)
          setRecordInit(false)
          onClose(false)
          close()
        },
      })
    } else {
      update = 0
      setEditIndexSubTalhao(null)
      setPolySubTalhao([])
      setRecordMap(false)
      onClose(false)
    }
  }

  const handleEditSubarea = (data: any[]) => {
    let newArray = []

    for (let i = 0; i < data.length; i += 1) {
      const item = data[i]

      if (item?.kml) {
        const subArea = item

        let tempCoord = []
        let arqui = []
        for (let ii = 0; ii < subArea.kml.coordenadas.length; ii += 1) {
          const latlng = subArea.kml.coordenadas[ii]
          tempCoord = [
            ...tempCoord,
            new window.google.maps.LatLng({
              lat: latlng.latitude,
              lng: latlng.longitude,
            }),
          ]

          arqui = [
            ...arqui,
            {
              lat: latlng.latitude,
              lng: latlng.longitude,
            },
          ]
        }

        const kml = geralKmlPolygon(arqui, removerAcentos(subArea.nome))
        const cripto = btoa(kml)

        const arquivo = `data:@file/xml;base64,${cripto}`

        newArray = [
          ...newArray,
          {
            cod: subArea.cod,
            cod_talhao: subArea.cod_talhao,
            nome: subArea.nome,
            area: subArea.area,
            arquivo,
            subArea: tempCoord,
          },
        ]
      } else {
        newArray = data
      }
    }
    if (map) {
      map.fitBounds(getBoundsNoArray(newArray[0].subArea))
      setCoord(newArray[0].subArea)
    }
    return newArray
  }

  const handleChangeNameSubArea = () => {
    const name = refInputNameSubTalhao.current.input.value

    setRecordInit(true)

    setNameSubArea(name)
  }

  const handleClickListPolygon = (index, scroll: boolean, dados) => {
    setTalhaoSelect(dados[0].cod)
    setListTalhao(dados)
    setIndexTalhaoSelect(index)

    dados.forEach((data, i) => {
      if (i === index) {
        setPolySubTalhao([...handleEditSubarea(data.sub_area)])
      } else {
        setPolySubTalhao([])
      }
    })
    dados[0].kml.lookAt = {
      lat: dados[0].kml.lookAt.latitude,
      lng: dados[0].kml.lookAt.longitude,
    }

    let poly = []
    dados[0].kml.coordenadas.forEach((data) => {
      poly = [
        ...poly,
        {
          lat: data.latitude,
          lng: data.longitude,
        },
      ]

      return poly
    })

    const center = centerMap(poly)

    setPropsMapa({
      zoom: 15,
      center,
    })
    setCoord(poly)
  }

  const handleClickNomeSubTalhao = (data: google.maps.PolyMouseEvent) => {
    const line = linePolyline
    const inicio = line[0].toJSON()
    const fim = data.latLng.toJSON()
    const final = data.latLng

    if (inicio.lat === fim.lat && inicio.lng === fim.lng) {
      line.push(final)
      if (checkCoord(line, true) <= 0) {
        Modal.confirm({
          title: 'Nome da subárea',
          icon: <Icon component={() => <Icons icon={faPenToSquare} />} />,
          content: <Input required id='id_name_talhao' />,
          okText: 'Salvar',
          cancelText: 'Cancelar',
          cancelButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: (close) => {
            // @ts-ignore
            const { value } = document.getElementById('id_name_talhao')
            if (value.length <= 0) {
              message.error('Nome de subárea inválido')
              return <></>
            }
            setRecordInit(true)
            if (handleMouseUpPolyline(data, value)) {
              message.destroy()
              message.error('Nome da subárea já cadastrado')
            } else {
              setRecordMap(false)
              setRecordInit(true)
              close()
            }
          },
        })
      }
    } else {
      setLinePolyline(polyline.getPath().getArray())
    }
  }

  const handleMouseUpEditSubArea = (
    poly: google.maps.Polygon,
    e: google.maps.MouseEvent,
    index
  ) => {
    const dataPoly = [...polySubTalhao]
    const coord = poly.getPath().getArray()
    const inicial = dataPoly[index].subArea

    const newCoord = coord.map((data) => {
      const latlng = { lat: data.lat(), lng: data.lng() }
      return latlng
    })

    if (poly) {
      const path = poly.getPath();
      const v_index = coord.findIndex((latlng) => latlng.equals(e.latLng));
      if (v_index > -1) {
          path.removeAt(v_index);
      }
  }

    if (checkCoord([e.latLng]) > 0) {
      message.destroy()
      message.warning({
        content: 'Ponto fora do talhão',
        style: { marginTop: '80vh' },
      })

      poly.setPath(inicial)
      setPolySubTalhao(dataPoly)
    } else {
      const kml = geralKmlPolygon(newCoord, removerAcentos(dataPoly[index].nome))
      const cripto = btoa(kml)

      const arquivo = `data:@file/xml;base64,${cripto}`

      dataPoly[index].area = calculoAreaCoord([coord])
      dataPoly[index].subArea = coord
      dataPoly[index].arquivo = arquivo
      setRecordInit(true)

      setPolySubTalhao(dataPoly)
    }
  }
  

  const handleClickPenSupArea = (data, nome) => {
    if (linePolyline.length <= 0) {
      setRecordMap(false)
    }
    if (recordInit || linePolyline.length > 0) {
      Modal.warning({
        title: 'Existe dados que não foram salvos deseja continuar?',
        okText: 'Sim',
        cancelText: 'Não',
        okCancel: true,
        onOk: () => {
          setEditIndexSubTalhao(data)
          setNameSubArea(nome)
          setRecordInit(false)
          setRecordMap(false)
          setLinePolyline([])
        },
      })
    } else {
      setEditIndexSubTalhao(data)
      setNameSubArea(nome)
    }
  }

  const handleClickSaveEditSupArea = (index) => {
    setEditIndexSubTalhao(null)
    const poly = [...polySubTalhao]
    const newName = refInputNameSubTalhao.current.input.value

    poly[index].nome = newName

    onFinish([poly[index]])
    setPolySubTalhao(poly)
  }

  const handleEditPolyline = () => {
    setRecordInit(true)
  }

  const handleNewSubTalhao = () => {
    setRecordMap(!recordMap)
    setLinePolyline(recordMap ? [] : linePolyline)

    setEditIndexSubTalhao(null)
  }

  const handleBaixarKml = (dados, nome) => {
    try {
      url.current.href = dados
      url.current.download = `${nome}.kml`
      url.current.click()
    } catch (error) {
      //
    }
  }

  const handleTrashSubArea = async (index) => {
    const poly = [...polySubTalhao]
    const list = [...listTalhao]

    try {
      if (poly[index].cod !== null) {
        message.loading('Removendo...')
        const resp: AxiosResponse<SubAreaTypes> = await delSubArea(poly[index].cod)

        if (resp.status === 200) {
          const temp = poly.filter((data, i) => i !== index)
          setPolySubTalhao(temp)

          list[indexTalhaoSelect].sub_area = temp

          setListTalhao(list)

          message.destroy()
          listTalhaoSafra(resp.data.itens[0].cod_propriedade)
          Notification({
            message: 'Sucesso',
            descricao: 'Subárea removida com sucesso',
            type: 'success',
            placement: 'top',
          })
          setEditIndexSubTalhao(null)
        }
      } else {
        poly.splice(index, 1)
        setPolySubTalhao(poly)
      }
    } catch (error) {
      message.destroy()
      Notification({
        message: 'Error',
        descricao: error.message,
        type: 'error',
      })
    }
  }



  const onLoadPolyline = (data: google.maps.Polyline) => {
    setPolyline(data)
  }

  const onChange = (e, i) => {
    const dados = [...namesExistente]
    const { value } = e.target
    dados[i].nome = value

    setNamesExistente(dados)
  }

  const onChangeUpload = async (info: UploadChangeParam) => {
    try {
      message.loading('Aguarde...', 99999)
      if (info.file.status === 'done') {
        const domKml = parseXml(await fileTypeText(info.file.originFileObj))

        const dataGeoJson = kml(domKml)
        let tempTalhao = []
        let fileInvalid = []
        let contem = []
        let nameExiste = []
        let names = []

        setNamesExistente([])
        for (let i = 0; i < dataGeoJson.features.length; i += 1) {
          const listKml = dataGeoJson.features[i]
          if (listKml.geometry.type === 'Polygon') {
            let tempCoord = []
            for (let ii = 0; ii < listKml.geometry.coordinates[0].length; ii += 1) {
              const latlng = listKml.geometry.coordinates[0][ii]
              tempCoord = [
                ...tempCoord,
                new window.google.maps.LatLng({
                  lat: latlng[1],
                  lng: latlng[0],
                }),
              ]
            }

            let arq = []
            let checkCo = []
            for (let ii = 0; ii < tempCoord.length; ii += 1) {
              const coord: google.maps.LatLng = tempCoord[ii]
              arq = [...arq, coord.toJSON()]
              checkCo = [...checkCo, coord]
            }
            names = [...names, arq]
            const check = checkCoord(checkCo)

            if (check > 0) {
              Notification({
                message: 'Subáreas não importadas por estarem fora do talhão',
                type: 'error',
                placement: 'bottom',
                descricao: (
                  <>
                    {names.map((data) => (
                      <Icon key='' component={() => <Svg fill='red' coordinates={data} />} />
                    ))}
                  </>
                ),
              })
            } else {
              const area = calculoAreaCoord([tempCoord])

              let name = ''
              if (listKml.properties.NOME) {
                name = removerAcentos(listKml.properties.NOME)
              } else if (listKml.properties.name) {
                name = removerAcentos(listKml.properties.name)
              } else if (listKml.properties.nome) {
                name = removerAcentos(listKml.properties.nome)
              } else {
                name = 'Nome nao localizado'
              }
              const dados = polySubTalhao
              contem = dados.filter((record) => record.nome === name)

              if (contem.length > 0) {
                //
                const kml = geralKmlPolygon(arq, removerAcentos(name))

                const cripto = btoa(kml)

                const arquivo = `data:@file/xml;base64,${cripto}`

                nameExiste = [
                  ...nameExiste,
                  {
                    cod: null,
                    cod_talhao: talhaoSelect,
                    nome: name,
                    area,
                    arquivo,
                    subArea: tempCoord,
                  },
                ]
              } else {
                /**a dunção removerAcentos irá remover acentos e caracteres especiais. Porém ao tratar o nome do talhão "TH Apeú" estrá transformando em "TH APEAs"
                 * por isso criei essa gambiarra apenas para tratar esse nome.
                 * nos demais irá funcionar normalmente
                 */
                let excecao_faz_apeu = removerAcentos(name)?.replace('APEAs','APEU');
                const kml = geralKmlPolygon(arq, (excecao_faz_apeu))

                const cripto = btoa(kml)

                const arquivo = `data:@file/xml;base64,${cripto}`

                tempTalhao = [
                  ...tempTalhao,
                  {
                    cod: null,
                    cod_talhao: talhaoSelect,
                    nome: name,
                    area,
                    arquivo,
                    subArea: tempCoord,
                  },
                ]
              }
            }
          } else {
            fileInvalid = [
              ...fileInvalid,
              {
                nome: listKml.properties.name || listKml.properties.NOME,
                type: listKml.geometry.type,
              },
            ]
          }
        }

        setNamesExistente(nameExiste)

        if (fileInvalid.length > 0) {
          Modal.warning({
            title: 'Arquivo contém dados inválidos',
            icon: <Icon component={() => <Icons icon={faWarning} />} />,
            content: (
              <>{`${fileInvalid.length}`} dados inválidos, o tipo de kml tem que ser Polygon</>
            ),
          })
        }
        if (tempTalhao.length > 0) {
          onFinish(tempTalhao)
        }

        setPolySubTalhao([...polySubTalhao, ...tempTalhao])

        message.destroy()
      }
    } catch (error) {
      tryError(error)
    }
  }

  const checkCoord = (latLng: google.maps.LatLng[], f = false) => {
    const dados = listTalhao[0].kml.coordenadas
    let tempCoord: google.maps.LatLng[] = []
    if (f) {
      tempCoord = latLng
    } else {
      for (let i = 0; i < dados.length; i += 1) {
        const list = dados[i]
        tempCoord = [
          ...tempCoord,
          new google.maps.LatLng({ lat: list.latitude, lng: list.longitude }),
        ]
      }
    }

    const polygono = new google.maps.Polygon({ paths: tempCoord })
    let result = []
    let fora = []
    for (let i = 0; i < latLng.length; i += 1) {
      const coord = latLng[i]
      const resultPath = google.maps.geometry.poly.containsLocation(coord, polygono)

      if (!resultPath) {
        result = [...result, resultPath]
        fora = [...fora, { lat: coord.lat(), lng: coord.lng() }]
      }
    }

    setMarker(fora)
    return result.length
  }

  useEffect(() => {
    try {
      const dados = [...listTalhoesRecord]

      let index = 0
      const filter = dados.filter((data, i) => {
        if (data.cod === update) {
          index = i
        }

        return data.cod === update
      })

      handleClickListPolygon(index, true, filter)

      setListTalhao(filter)
    } catch (error) {
      tryError(error)
    }
  }, [listTalhoesRecord])

  useEffect(() => {
    handleEditSubarea(listTalhao)
  }, [listTalhao])

  useEffect(() => {

    if (visible) {

      const subCheck = listTalhao.map(item => item.sub_area)


      if (subCheck.length > 0) {

        

        if (subCheck[0][0] && subCheck[0][0].create_user !== undefined) {
          setUsuarioQueCriou(subCheck[0][0]?.create_user);
      
          const dateOnlyString = subCheck[0][0]?.create_time?.split(' ')[0];
          const timeOnlyString = subCheck[0][0]?.create_time?.split(' ')[1];
      
          setDataCriacao(dateOnlyString);
          setHorarioDeCriacao(timeOnlyString);
          setLogUser(true);
      } else {
          setLogUser(false);
      }
      

        if (subCheck[0][0] && subCheck[0][0].update_user !== undefined) {
          setUsuarioQueAlterou(subCheck[0][0]?.update_user)
          
          const dateOnlyString = subCheck[0][0]?.update_time?.split(' ')[0]
          const timeOnlyString = subCheck[0][0]?.update_time?.split(' ')[1]

          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, listTalhao, usuarioQueAlterou, usuarioQueCriou, visible, update,horarioDeAtualizacao, horarioDeCriacao])

    
  return (
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {/* @ts-ignore */}
          <GoogleMap
            onUnmount={handleUnmountMap}
            onLoad={setMap}
            options={{
              streetViewControl: false,
              zoomControl: false,
              mapTypeId: 'hybrid',
              mapTypeControl: false,
              fullscreenControl: false,
              draggable: true,
            }}
            zoom={propsMap.zoom}
            mapContainerStyle={{
              width: '100%',
              height: '100vh',
            }}
          >
            <Polygon
              path={coord}
              onClick={handleClickPolygon}
              options={{
                clickable: recordMap,
                strokeColor: 'white',
                fillColor: 'white',
                fillOpacity: 0.3,
                strokeWeight: 4,
                geodesic: false,
              }}
            />
            {/* {marker.map((rec) => (
              // @ts-ignore
              <Marker position={{ ...rec }} />
            ))} */}
            <Polyline
              onMouseUp={handleClickNomeSubTalhao}
              onMouseDown={handleEditPolyline}
              onLoad={onLoadPolyline}
              editable
              draggable
              options={{
                strokeColor: 'orange',
              }}
              path={linePolyline}
            />

            {polySubTalhao.map((data, index) => {
              return (
                <Polygon
                  key={data.cod}
                  path={data.subArea}
                  onLoad={(poly) => {
                    data.polygon = poly
                    return <></>
                  }}
                  onMouseUp={(e) => handleMouseUpEditSubArea(data.polygon, e, index)}
                  options={{
                    editable: editIndexSubTalhao === index,
                    clickable: recordMap,
                    fillColor: editIndexSubTalhao === index ? 'orange' : 'cyan',
                    fillOpacity: 0.3,
                    strokeColor: editIndexSubTalhao === index ? 'orange' : 'cyan',
                    strokeWeight: 3,
                    geodesic: false,
                  }}
                />
              )
            })}
          </GoogleMap>
        </Col>
        <ContainerMap style={{ width: flutuantDiv ? 'auto' : '300px' }}>
          <Card
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <>{flutuantDiv ? '' : 'Talhão'}</>
                {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                  <>
                    <DiasDesdeData
                      dataCriacao={dataAlteracao}
                      onDataFromCreation={handleDataFromCreation}
                    />
                    <Render condition={!!usuarioQueAlterou && !!horarioDeAtualizacao}>
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        atualizado por: {usuarioQueAlterou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeAtualizacao}h`}
                      </small>
                    </Render>
                    
                  </>
                ) : logUser && update > 0 ? (
                  <>
                    {' '}
                    {usuarioQueCriou === ''  ? null : (
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        criado por: {usuarioQueCriou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeCriacao}h`}
                      </small>
                    )}
                  </>
                ) : null}
              </div>
            }
            size='small'
            bodyStyle={{ display: flutuantDiv ? 'none' : '' }}
            extra={
              <Button
                className='btn-hidden'
                onClick={() => handleClickHiddenFlutuant(flutuantDiv)}
                size='small'
                type='text'
                icon={flutuantDiv ? <MenuUnfoldOutlined /> : <CloseOutlined />}
              />
            }
          >
            <>
              {listTalhao.map((data, index) => {
                const key = index + 1

                if (data.select === undefined) {
                  data.select = false
                }
                return (
                  <Space key={data.area} style={{ width: '100%' }} align='center'>
                    <div key={key} ref={scrollTalhao} id={`talhao_${index}`} />
                    <Icon
                      component={() => (
                        <Svg
                          // @ts-ignore
                          coordinates={data.kml.coordenadas}
                          fill='#70ce9f'
                        />
                      )}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {' '}
                      <div style={{ paddingLeft: '15px' }}>
                        <h5 style={{ marginBottom: '0' }}>{data.nome}</h5>

                        <small>{data.area} ha</small>
                      </div>
                    </div>
                  </Space>
                )
              })}
              <div style={{ padding: '5px' }}>
                <Space>
                  <Button
                    size='small'
                    hidden={permissions?.inclusao === 0}
                    className={recordMap ? 'trash-button' : 'success-button'}
                    onClick={handleNewSubTalhao}
                    icon={<Icon component={() => <Icons icon={recordMap ? faXmark : fasPlus} />} />}
                  >
                    Nova Subárea
                  </Button>
                  <Button
                    hidden={!recordMap}
                    size='small'
                    title='Reiniciar'
                    onClick={() => {
                      setLinePolyline([])
                      setRecordInit(false)
                    }}
                    icon={<Icon component={() => <Icons icon={faSync} />} />}
                  />
                </Space>
              </div>
            </>
            <div
              id='list-sub-area'
              ref={listSubArea}
              style={{
                maxHeight: 'calc(100vh - 420px)',
                overflowX: 'auto',
                display: flutuantDiv ? 'none' : '',
              }}
            >
              <List header='Subáreas' size='small'>
                {polySubTalhao.map((data, index) => {
                  let temp = []

                  for (let i = 0; i < data.subArea.length; i += 1) {
                    const coord = data.subArea[i]
                    temp = [...temp, coord.toJSON()]
                  }
                  return (
                    <List.Item
                      key={data.cod}
                      // @ts-ignore
                      extra={<Svg fill='cyan' coordinates={temp} />}
                    >
                      <a ref={url} style={{ display: 'none' }} href=''>
                        Link
                      </a>
                      <Space size='large'>
                        <Space>
                          <Button
                            hidden={permissions?.alteracao === 0}
                            onClick={() =>
                              editIndexSubTalhao !== index
                                ? handleClickPenSupArea(index, data.nome)
                                : handleClickSaveEditSupArea(index)
                            }
                            disabled={data.cod === null}
                            className={editIndexSubTalhao === index ? null : 'info-button'}
                            type='primary'
                            size='small'
                            icon={
                              <Icon
                                component={() => (
                                  <Icons icon={editIndexSubTalhao === index ? faCheck : faPen} />
                                )}
                              />
                            }
                          />
                          <Button
                            disabled={data.cod === null}
                            className='info-button'
                            title='Baixar Kml'
                            onClick={() => handleBaixarKml(data.arquivo, data.nome)}
                            type='primary'
                            size='small'
                            icon={<Icon component={() => <Icons icon={faDownload} />} />}
                          />

                          <Popconfirm
                            title={`Deseja remover a subárea ${data.nome}?`}
                            onConfirm={() => handleTrashSubArea(index)}
                            icon={<IconAlert type='confirm' size={4} />}
                            cancelText='Não'
                            okText='Sim'
                            cancelButtonProps={{
                              type: 'primary',
                              danger: true,
                            }}
                          >
                            <Button
                              hidden={permissions?.exclusao === 0}
                              danger
                              type='primary'
                              size='small'
                              icon={<Icon component={() => <Icons icon={faTrash} />} />}
                            />
                          </Popconfirm>
                        </Space>
                        <div>
                          {editIndexSubTalhao === index ? (
                            <>
                              <Input
                                ref={refInputNameSubTalhao}
                                onChange={handleChangeNameSubArea}
                                value={nameSubArea}
                                style={{ width: '100px', marginBottom: '0' }}
                                size='small'
                                id='input_name_subArea'
                              />
                              <br />
                            </>
                          ) : 
                          
                          /**Ao tratar o nome do talhão "TH Apeú" estrá transformando em "TH APEAs"
                           * por isso criei essa gambiarra apenas para tratar esse nome.
                           * nos demais irá funcionar normalmente
                           */
                          (
                            <h5 style={{ marginBottom: '0' }}>{(data.nome)?.replace('APEAs', 'APEU')}</h5>
                          )}
                          <small>{data.area} ha</small>
                        </div>
                      </Space>
                    </List.Item>
                  )
                })}
              </List>
            </div>
          </Card>
          <div
            title={talhaoSelect <= 0 ? 'Selecione um talhão para iniciar' : null}
            style={{
              padding: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <div
            hidden={flutuantDiv}
            style={{
              padding: '10px',
              width: '300px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {' '}
            <Upload
              {...customRequest}
              disabled={talhaoSelect <= 0}
              accept='.kml'
              onChange={onChangeUpload}
              style={{
                minWidth: '100%',
              }}
              showUploadList={false}
            >
              <Button
                hidden={permissions?.inclusao === 0}
                icon={<Icon component={() => <Icons icon='upload' />} />}
                type='primary'
              >
                Importar KML
              </Button>
            </Upload>
            <Button onClick={handleCloseModal} danger type='primary'>
              Fechar
            </Button>
          </div>
          <div
            hidden={flutuantDiv}
            style={{
              padding: '10px',
              width: '315px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        </ContainerMap>
      </Row>

      <Modal
        title='Salvando...'
        footer={false}
        closeIcon={false}
        closable={false}
        width='387px'
        style={{ top: '10px' }}
        open={progressModal}
      >
        <h5>
          <Progress percent={postProgress} steps={19} strokeColor='#87d068' />
        </h5>
      </Modal>
      <Modal
        open={namesExistente.length > 0}
        onCancel={() => setNamesExistente([])}
        cancelText='Fechar'
        width={390}
        okButtonProps={{ hidden: true }}
        title={
          namesExistente.length <= 1 ? 'Nome da subárea já existe' : 'Subáreas com nome duplicado'
        }
      >
        <List size='small'>
          {namesExistente.map((info, index) => {
            let tempArea = []

            info.subArea.forEach((data) => {
              tempArea = [
                ...tempArea,
                {
                  lng: data.lng(),
                  lat: data.lat(),
                },
              ]

              return tempArea
            })
            return (
              <List.Item>
                <Space>
                  <Icon
                    component={() => (
                      // @ts-ignore
                      <Svg fill='green' coordinates={tempArea} />
                    )}
                  />

                  <Input
                    value={info.nome}
                    onChange={(e) => onChange(e, index)}
                    placeholder='Nome subárea'
                  />
                  <Button
                    type='primary'
                    onClick={() => {
                      const check = polySubTalhao.find((record) => record.nome === info.nome)

                      if (!check) {
                        if (checkCoord(info.subArea) > 0) {
                          message.warning('Subáreas não importadas por estarem fora do talhão')
                        } else {
                          const success = onFinish([info])
                          if (success) {
                            const dados = [...namesExistente]
                            dados.splice(index, 1)
                            setNamesExistente(dados)

                            setPolySubTalhao([...polySubTalhao, info])
                          }
                        }
                      } else {
                        message.error('Nome da subárea já existe')
                      }
                    }}
                  >
                    Salvar
                  </Button>
                </Space>
              </List.Item>
            )
          })}
        </List>
      </Modal>
    </Col>
  )
}

export default SubArea
