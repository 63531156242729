import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Space, Table, Tooltip, message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ViewPage from '../../../../Components/ViewPage'

import { pagination } from '../../../../Components/Default'
import { localConfig } from '../../../../Configs/localConfig'
import { filterTable } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { Container } from './styles'

import { groupBy } from 'lodash'
import Icons from '../../../../Components/Icons'
import MapaFertilidade from '../../../../Components/ordemLaboratorio/mapaFertilidade'
import { useLogic } from '../../../../context/useLogic'
import { OsItensTypes, getPedLaboratorio } from '../../../../services/Laboratorio'
import {
  ItensMapaFertilidadeTypes,
  getMapaFertilidade,
} from '../../../../services/Laboratorio/osResultado'
import { PropriedadeItens } from '../../../../services/Propriedades'
import { ItensColetaTrajetoTypes, getColetaTrajeto } from '../../../../services/coletaTrajeto'
import { ItensColetaUsuariosTypes, getColetaUsuarios } from '../../../../services/coletaUsuarios'
import ShapeTalhao from '../../../../Components/CustomUi/shapeTalhao'
import { TitleH1 } from '../../../../Components/CustomUi/titleH1'
import { TabelaMapasFertilidade } from './componentes/tabela'
import { formatarValor } from '../../../../utils'

interface ProsDataOs {
  os: ItensMapaFertilidadeTypes[]
  talhao: OsItensTypes['talhao_kml']
  propriedade: PropriedadeItens
  areaTalhao: string
  talhaoNome: string
  data: string | Dayjs
  percurso: { [key: string]: ItensColetaTrajetoTypes[] }
  coletaUsuario: ItensColetaUsuariosTypes[]
  nome_cliente: string,
  calcario_propriedade?: string
}

function PageMapaFertilidade() {
  const { checkSafra } = useLogic()

  const history = useHistory()

  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [dataOrderSer, setDataOrderSer] = useState<OsItensTypes[]>([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [nomePropriedade, setNomePropriedade] = useState('')

  const [dataOs, setDataOs] = useState<ProsDataOs>()
  const codSafra = localStorage.getItem('codSafra')

  const listarOS = async () => {
    setLoadingTable(true)
    const resp = await getPedLaboratorio('')

    // const respEquipe = await getEquipeConta('', '', { responsavel_fertilidade: true })
    const dados = resp.data.itens
    const dadosFilter = dados.filter((item) => item.status >= 5)
    const resultado = dadosFilter.reduce(
      (soma, item) => {
        return {
          //@ts-ignore
          area: soma.area + parseFloat(item.talhao_area),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
      },
    ]

    gerarTabela(dadosFilter)
    gerarTabela2(resultadoFinal, dadosFilter.length)
    setNomePropriedade(resp?.data?.itens[0]?.nome_cliente)
    setDataOrderSer(dados)
    setLoadingTable(false)
  }

  const handleClickOs = async (codPedido: number, nome_cliente: string, calcario_propriedade?: string) => {
    setVisibleDrawer(true)
    message.loading('Aguarde...', 999999)

    // const resp = await getMapaFertilidade({ codPedido, elementoUsoMapa: true })
    // const respPer = await getColetaTrajeto({ codPedido })
    // const respUser = await getColetaUsuarios({ codPedido })

    try {
      const [dadosOS, resp, respPer, respUser] = await Promise.all([
        getPedLaboratorio(codPedido),
        getMapaFertilidade({ codPedido, elementoUsoMapa: true }),
        getColetaTrajeto({ codPedido }),
        getColetaUsuarios({ codPedido }),
      ])
  
      const percursoGroup = groupBy(respPer.data.itens, 'ponto')
  
      setDataOs({
        data: dadosOS?.data.itens?.[0].data_coleta,
        talhao: dadosOS?.data.itens?.[0].talhao_kml,
        areaTalhao: dadosOS?.data.itens?.[0].talhao_area,
        os: resp.data.itens,
        propriedade: dadosOS?.data.itens?.[0].propriedade[0],
        talhaoNome: dadosOS?.data.itens?.[0].talhao_nome,
        percurso: percursoGroup,
        coletaUsuario: respUser.data.itens,
        nome_cliente: nome_cliente,
        calcario_propriedade: calcario_propriedade
      })
      setVisibleDrawer(true)
      message.destroy();
    } catch (error) {
      message.destroy();
      setLoadingTable(false);
      console.log("error: ", error)
      message.error('Falha ao obter dados do mapa de fertilidade!', 5000)
    }
    
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.propriedade_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item?.talhao_nome,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },

        {
          text: formatarValor(parseFloat(item?.talhao_area)),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: item?.laboratorio_nome,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: item?.calcario_propriedade,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: dayjs(item?.data).format('DD/MM/YYYY'),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            [
              { text: 'Propriedade', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Talhão', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Laboratório', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Calcário', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Data', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          dontBreakRows: true,
          keepWithHeaderRows: 1,
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]

    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item.totalizado_area,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'Total de mapas de fertilidade',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total área',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
          alignment: 'center',
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]

    setDadosResultado(table)
  }

  useEffect(() => {
    if (!visibleDrawer) {
      setDataOs({
        data: '',
        os: [],
        propriedade: null,
        talhao: null,
        talhaoNome: '',
        areaTalhao: '',
        percurso: {},
        coletaUsuario: [],
        nome_cliente: '',
        calcario_propriedade: ''
      })
    }
  }, [visibleDrawer])

  useEffect(() => {
    listarOS()

    const idDaSafra = checkSafra('/precisao/mapa-fertilidade')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    setLoadingTable(true)
    listarOS()
  }, [codSafra])


  return (
    <Container>
      <ViewPage
        onPermission={setPermissions}
        rotina={82}
        newHide
        title='OS resultado'
        btnClick={() => {}}
        content={<MapaFertilidade open={visibleDrawer} onClose={setVisibleDrawer} data={dataOs} />}
        hiddeTitle
        cardBodyStyle={{padding: 15}}
        cardMainStyle={{height: '87.5vh'}}
      >
        <TitleH1>Mapas de Fertilidade</TitleH1>
        
        <TabelaMapasFertilidade 
            list_mapas={(dataOrderSer ?? []).filter((record) => record.status >= 5)}
            handleClickOs={handleClickOs}
            pointerEvents={permissions?.acesso === 0 ? 'none' : 'auto'}
            loading={loadingTable}
            dados={dadosEstatisticas}
            totais={dadosResultado}
            disable={dataOrderSer.length <= 0}
            titleSecundario={nomePropriedade}
        />

      </ViewPage>
    </Container>
  )
}

export default PageMapaFertilidade
