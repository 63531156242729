import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Modal, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Notification } from '../../../../Components/notification'

import FormLaboratorio from '../../../../Components/laboratorio'

import { delFornecedor, getFornecedor } from '../../../../services/Fornecedores'

import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { IconAlert } from '../../../../Components/iconsAlert'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { Container } from './styled'
import { delLaboratorio } from '../../../../services/Laboratorio'
import { useLogic } from '../../../../context/useLogic'
import { useHistory } from 'react-router'

const OrderSer: React.FC = () => {
  const { checkSafra } = useLogic()
  const history = useHistory()

  const [loadingTable, setLoadingTable] = useState(false)
  const [dataFornecedores, setDataForncedores] = useState([])
  const [visibleForm, setVisibleForm] = useState(false)
  const [btnClick, setBtnClick] = useState(false)
 
  const [updateUpdateFornecedor, setUpdateUpdateFornecedor] = useState<number>()
  const [nomeLaboratorio, setNomeLaboratorio] = useState('')
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [permissionsExtra, setPermissionsExtra] = useState<ItensPerUserLogged[]>([])

  const listarFornecedor = async () => {
    setLoadingTable(true)
    const response = await getFornecedor({codConta: 1 ,codCategoria: 2})
    const nomePro = response.data?.itens[0]?.nome
    setNomeLaboratorio(nomePro)

    setLoadingTable(false)

    setDataForncedores(response.data.itens)
  }

  const handleClickVisuFornecedor = async (data) => {
    setUpdateUpdateFornecedor(data)
    setVisibleForm(true)
  }

  const handleTrashFornecedor = async (data) => {
    const responsePro = await getFornecedor({codFornecedor: data})
    const nomePro = responsePro.data.itens[0].nome

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Laboratório ${nomePro}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delLaboratorio(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Laboratório Excluido',
              type: 'success',
            })

            listarFornecedor()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleVisibleForm = () => {
    setBtnClick(true)
    if (permissionsExtra[0].cod_rotina === 33 && permissionsExtra[0].inclusao === 1) {
      setVisibleForm(true)
    }


    setUpdateUpdateFornecedor(0)
  }

  const colum: ColumnsType<any> = [
    {
      key: 1,
      title: 'Nome Laboratório ',
      dataIndex: 'nome',
    },
    {
      title: 'Ações',
      align: 'center',
      width: '90px',
      dataIndex: 'cod',
      key: 'acao',

      render: (data) => (
        <Space>
          <Button
            shape='circle'
            hidden={permissionsExtra[0].acesso === 0 && permissionsExtra[0].alteracao === 0}
            onClick={() => handleClickVisuFornecedor(data)}
            icon={<FontA icon={solid('pen')} />}
            size='middle'
          />
          <Button
            shape='circle'
            hidden={permissionsExtra[0]?.exclusao === 0}
            onClick={() => handleTrashFornecedor(data)}
            className='trash-button'
            type='primary'
            icon={<FontA icon={solid('trash')} />}
            size='middle'
          />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    listarFornecedor()
  }, [])

  return (
    <Container>
      <ViewPage
        onPermission={setPermissions}
        rotina={53}
        title='Laboratório'
        rotinaExtra={[33]}
        onPermissionsExtra={setPermissionsExtra}
        btnClick={handleVisibleForm}
        newHide={true}
        btnsExta={
          permissionsExtra[0]?.acesso === 1 ? null : (
            <Button type='primary' onClick={handleVisibleForm}>
              Novo
            </Button>
          )
        }


        content={
          <FormLaboratorio
            btnClick={btnClick}
           
            permissions={permissions}
            acessoPermissoes={permissionsExtra[0]}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            setBtnClose={setBtnClick}
            update={updateUpdateFornecedor}
            setUpdate={setUpdateUpdateFornecedor}
            atualizar={listarFornecedor}
          />
        }
      >
        <Table
          loading={loadingTable}
          pagination={pagination}
          dataSource={dataFornecedores}
          scroll={{ y: 'calc(90vh - 275px)' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataFornecedores.length}</b>
            </div>
          )}
          bordered
          size='small'
          columns={colum}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuFornecedor(record.cod)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default OrderSer