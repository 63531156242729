import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetTalhao {
  itens: [TalhaoCultivaveisItens]
}

export interface TalhaoCultivaveisItens {
  cod?: number
  select?: boolean
  cod_conta?: number
  cod_propriedade?: number
  propriedade_nome?: string
  cod_talhao?: number
  cod_safra?: number
  isSubArea?: boolean
  area?: number
  arquivo?: string
  create_user?: string
  create_time?: string
  update_user?: string
  update_time?: string
  nome?: string
  /**
   * @param {status} 1 = Ativo | 0 = Desativado
   */
  status?: 1 | 0
  length?: number
  padrao_pontos?: {
    cod: number
    cod_talhao: number
    latitude: string
    longitude: string
    nome: string
  }[]
  kml?: {
    nome?: string
    lookAt?: {
      latitude?: string
      longitude?: string

      lat?: any
      lng?: any
    }
    coordenadas?: [
      {
        latitude?: any
        longitude?: any

        lat?: any
        lng?: any
      }
    ]
  }
  sub_area?: any[]
}

interface TypesGetTalhaoSafra {
  codSafra?: number
  codPropriedade?: number
  agrupamentoPropriedade?: number
  codCliente?: number
  cod?: number
  embed?: string
  pagination?: number
}

export const getTalhaoCultivavel = async (params: TypesGetTalhaoSafra) => {
  const config = localConfig()

  const { pagination = -1, codSafra = config.codSafra, codCliente } = params

  api.defaults.headers.Acesso = config.acesso ? config.acesso : 'conta'

  const response: AxiosResponse<TypeGetTalhao> = await api.get('/talhao-safra', {
    params: {
      codConta: config.conta,
      codSafra,
      pagination,
      ...params,
      codCliente: config.codCliente ? config.codCliente : codCliente,
    },
  })

  return response
}

export const postTalhaoCultivavel = async (codPro, data) => {

  const config = localConfig()

  const response: AxiosResponse<TypeGetTalhao> = await api.post(
    `/talhao-safra?codConta=${config.conta}&codCliente=${config.codCliente}&codSafra=${config.codSafra}&codPropriedade=${codPro}`,
    data
  )

  return response
}
export const postObservacaoTalhao = async (codPro, data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetTalhao> = await api.post(
    `/talhao-safra/observacao?=${config.conta}&codCliente=${config.codCliente}&codSafra=${config.codSafra}&codPropriedade=${codPro}`,
    data
  )

  return response
}
export const postObservacaoTalhaoSubArea = async (codPro, data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetTalhao> = await api.post(
    `/sub-area-talhao/observacao?=${config.conta}&codCliente=${config.codCliente}&codSafra=${config.codSafra}&codPropriedade=${codPro}`,
    data
  )

  return response
}

export const putTalhaoCultivavel = async (codPro, codTalhao, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetTalhao> = await api.put(
    `/talhao-safra/${codTalhao}?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}&codPropriedade=${codPro}`,
    data
  )

  return response
}

export const getDocTalhaoCultivavel = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get(
    `/talhao-safra/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codCliente=${config.codCliente}&codSafra=${config.codSafra}`
  )

  return response
}

export const getKmlTalhaoCultivavel = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api({
    url: `/talhao-safra/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}`,
    method: 'GET',
    responseType: 'blob',
  })

  return response
}

export const delTalhaoCultivavel = async (codPro, codTalhao) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetTalhao> = await api.delete(
    `/talhao-safra/${codTalhao}?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}&codSafra=${config.codSafra}`
  )

  return response
}
