import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
    faXmark,
} from '@fortawesome/free-solid-svg-icons'

//STYLES
import { PainelProps } from './styles'

//APP
import { formatarValor, leadZero } from '../../../../../utils'
import { TalhaoItens } from '../../../../../services/Talhao'

//COMPONENTS
import ButtonCustom from '../../../../../Components/ButtonCustom';
import GeneratePdfButton from '../../../../../Components/relatorio-component';
import { Switcher } from '../../../../../Components/CustomUi/Switcher';
import { LineDivider } from '../../../../../Components/CustomUi/LineDivider';
import { localConfig } from '../../../../../Configs/localConfig';
import { TabelaTodasPropiedades } from '../tabelaTodasPropriedades';
import { GerarExcel } from '../../../../../Components/GerarExcel';
import { Inline } from '../../../../../Components/CustomUi/inline';
import { gerarEstruturaTabela } from './meta';

//PROPS
interface TypesVisuPropri {
    name?: string
    cod?: number
    cor?: string
    areaPropriedade?: number
    nome_proprietario?: string
    talhoes?: TalhaoItens[]
}
interface Props {
    exibir_area_talhao: boolean
    setExibir_area_talhao: React.Dispatch<React.SetStateAction<boolean>>
    exibir_nome_talhao: boolean
    setExibir_nome_talhao: React.Dispatch<React.SetStateAction<boolean>>
    onClose: Function
    dados_propriedades: TypesVisuPropri[]
    distinguir_cor: boolean
    setDistinguir_cor: React.Dispatch<React.SetStateAction<boolean>>
    ocultarSafra?: boolean
}

export const PainelTodasPropriedades: React.FC<Props> = ({
    exibir_area_talhao,
    setExibir_area_talhao,
    exibir_nome_talhao,
    setExibir_nome_talhao,
    onClose,
    dados_propriedades,
    distinguir_cor,
    setDistinguir_cor,
    ocultarSafra,
}) => {
    const qtd_propriedades: number = dados_propriedades?.length ?? 0
    const apenas_talhoes = dados_propriedades?.map((item) => item.talhoes)
    const qtd_talhoes: number = apenas_talhoes?.reduce((acumulador, array) => acumulador + array.length, 0) ?? 0;
    const unir_dados_talhoes = apenas_talhoes.map(item => item.map(sub => Number(sub.area)));
    let areas_do_talhao = unir_dados_talhoes.reduce((acc, curr) => acc.concat(curr), []);
    let soma_area_dos_talhoes = areas_do_talhao.reduce((acc: number, item: number) => acc + item, 0);


    //@ts-ignore
    const dadosNovos = dados_propriedades.flatMap((item) =>
        item.talhoes.map((th) => ({
            nome: item?.name,
            nome_talhao: th.nome,
            area_talhao: th.area,
        }))
    )

    // console.log("dadosNovos: ", dadosNovos)

    //STATES
    const [shrink, setShrink] = useState<boolean>(false)

    const [dadosEstatisticas, setDadosEstatisticas] = useState([])
    const [dadosResultadoPropri, setDadosResultadoPropri] = useState([])
    const [dadosResultado, setDadosResultado] = useState([])

    function somarValores(dados, totais) {
        const resultado = dados.reduce(
            (soma, item) => {
                //@ts-ignore
                return {
                    //@ts-ignore
                    area: soma.area + parseFloat(item.area_talhao),
                }
            },
            {
                area: 0,
            }
        )

        const resultadoFinal = [
            {
                totalizado_area: formatarValor(resultado?.area),
            },
        ]

        gerarTabela2(resultadoFinal, totais)
    }

    function somarAreaTalhao(dados) {
        const resultado = dados.reduce(
            (soma, item) => {
                //@ts-ignore
                return {
                    //@ts-ignore
                    area: soma.area + parseFloat(item.area),
                }
            },
            {
                area: 0,
            }
        )

        return formatarValor(resultado?.area)
    }

    function gerarTabela(dados) {
        const dadosTh = dados.map((item) => item.talhoes)
        const header = [
            [
                {
                    text: `${dados_propriedades.map((item) => item.name)}`,
                    style: 'tableHeader',
                    alignment: 'center',
                },
            ],
        ]

        const dataPropri = dados_propriedades?.map((item) => {
            return [
                { text: item?.name, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
                { text: item.talhoes.length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },

                {
                    text: somarAreaTalhao(item.talhoes),
                    fontSize: 7,
                    margin: [0, 2, 0, 2],
                    alignment: 'center',
                },
            ]
        })

        const tablePropri = [
            {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                        [
                            { text: 'Propriedade', style: 'tableHeader', fontSize: 9, alignment: 'center' },
                            { text: 'Quantidade de talhões', style: 'tableHeader', fontSize: 9, alignment: 'center' },
                            {
                                text: 'Área total talhões',
                                style: 'tableHeader',
                                fontSize: 9,
                                alignment: 'center',
                            },
                        ],

                        ...dataPropri,
                    ],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? '#f5f5f5' : null
                    },
                },
            },
        ]

        const dataTh = dadosNovos?.map((item) => {
            return [
                { text: item?.nome, fontSize: 6, margin: [0, 2, 0, 2], alignment: 'center' },
                { text: item?.nome_talhao, fontSize: 6, margin: [0, 2, 0, 2], alignment: 'center' },

                {
                    text: formatarValor(item?.area_talhao),
                    fontSize: 6,
                    margin: [0, 2, 0, 2],
                    alignment: 'center',
                },
            ]
        })

        const table = [
            {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                        [
                            { text: 'Propriedade', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                            { text: 'Talhão', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                            { text: 'Área total', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                        ],

                        ...dataTh,
                    ],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? '#f5f5f5' : null
                    },
                },
            },
        ]
        setDadosResultadoPropri(tablePropri)
        setDadosEstatisticas(table)
    }

    function gerarTabela2(dados, length) {
        const data = dados?.map((item) => {
            return [
                { text: length, fontSize: 6, margin: [0, 2, 0, 2], alignment: 'center' },
                { text: dadosNovos.length, fontSize: 6, margin: [0, 2, 0, 2], alignment: 'center' },
                { text: item?.totalizado_area, fontSize: 6, margin: [0, 2, 0, 2], alignment: 'center' },
            ]
        })

        const table = [
            {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'], // Definindo larguras das colunas
                    body: [
                        [
                            {
                                text: 'Total de propriedades',
                                style: 'tableHeader',
                                fontSize: 8,
                                alignment: 'center',
                            },
                            {
                                text: 'Total de talhões',
                                style: 'tableHeader',
                                fontSize: 8,
                                alignment: 'center',
                            },
                            { text: 'Total de Área', style: 'tableHeader', fontSize: 8, alignment: 'center' },
                        ],
                        ...data,
                    ],
                    margin: [0, 0, 0, 0],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? '#f5f5f5' : null
                    },
                },
            },
        ]
        setDadosResultado(table)
    }

    useEffect(() => {
        somarValores(dadosNovos, dados_propriedades.length)
        gerarTabela(dados_propriedades)

        return () => {
            setExibir_nome_talhao(false)
            setExibir_area_talhao(false)
            setDistinguir_cor(false)
        }
    }, [])

    return (
        <PainelProps data-shrink={shrink}>
            <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}>
                <ButtonCustom
                    onClick={() => {
                        onClose()
                    }}
                    type='tertiary'
                    danger
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    style={{ marginLeft: -15, alignSelf: 'flex-start' }}
                >
                    Fechar
                </ButtonCustom>
                <ButtonCustom
                    onClick={() => {
                        setShrink(!shrink)
                    }}
                    type={shrink ? 'primary' : 'tertiary'}
                    icon={
                        <FontAwesomeIcon
                            icon={shrink ? faUpRightAndDownLeftFromCenter : faDownLeftAndUpRightToCenter}
                        />
                    }
                    style={{ marginLeft: -15, alignSelf: 'flex-start' }}
                />
            </div>

            {!ocultarSafra && (
                <>
                    <div className='PainelProps__info'>
                        <ItemInfo title='Safra' value={`${localConfig()?.nomeSafra}`} />
                    </div>
                    <LineDivider />
                </>
            )}

            <div className='PainelProps__info'>
                <ItemInfo description='Soma das áreas dos talhões' title='Área total' value={`${Number(soma_area_dos_talhoes).toFixed(2)} ha`} />
                <ItemInfo description='Quantidade de propriedades' title='Propriedades' value={String(leadZero(qtd_propriedades))} />
                <ItemInfo description='Quantidade de talhões somando todas as propriedades' title='Talhões' value={String(leadZero(qtd_talhoes))} />
            </div>

            <LineDivider />

            <TabelaTodasPropiedades
                dados_propriedades={dados_propriedades}
                distinguir_cor={distinguir_cor}
            />

            <LineDivider />

            <Switcher
                isChecked={exibir_nome_talhao}
                trigger={setExibir_nome_talhao}
                label='Exibir nome dos talhões'
            />
            <Switcher
                isChecked={exibir_area_talhao}
                trigger={setExibir_area_talhao}
                label='Exibir área dos talhões'
            />
            <Switcher
                isChecked={distinguir_cor}
                trigger={setDistinguir_cor}
                label='Distinguir cor em cada propriedade'
            />
            {/* <Switcher 
                isChecked={distinguir_cor_talhao}
                trigger={setDistinguir_cor_talhao}
                label='Distinguir cor em cada talhão'
            /> */}
            
            <LineDivider />

            <Inline>
                <GerarExcel 
                    titulo='Relatório de talhões da safra'
                    titulo_secundario={dados_propriedades?.[0]?.nome_proprietario}
                    nome_do_arquivo='relatório_talhões_da_safra'
                    usuario={(JSON.parse(localStorage.getItem('Itens')))?.[0]?.email?.toLowerCase()}
                    width_coluna={30}

                    tabela={gerarEstruturaTabela(dadosResultadoPropri)}
                    tabela_secundaria={gerarEstruturaTabela(dadosEstatisticas)}
                    tabela_terciaria={gerarEstruturaTabela(dadosResultado)}
                />

                <GeneratePdfButton
                    dados={dadosEstatisticas}
                    title={'Relatório de talhões da safra'}
                    totais={dadosResultado}
                    dadosPropri={dadosResultadoPropri}
                    disable={dados_propriedades.length <= 0}
                    titleSecundario={dados_propriedades?.[0]?.nome_proprietario}
                />

            </Inline>
            

        </PainelProps>
    )
}

// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  box-sizing: border-box;
  padding: 5px;

  p.ItemInfo__item__title {
    margin: 0;
    font-size: 13px;
    color: var(--text-color-light);
    font-weight: 500;
  }
  span.ItemInfo__item__value {
    margin: 0;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 600;
    line-height: 15px;
  }
`

//PROPS
interface PropsItemInfo {
    title: string
    value: string
    description?: string
}

const ItemInfo: React.FC<PropsItemInfo> = ({ title, value, description }) => {
    return (
        <Item title={description}>
            <p className='ItemInfo__item__title'>{title}</p>
            <span
                className='ItemInfo__item__value'
                style={{
                    textTransform: title === 'Propriedade' ? 'capitalize' : 'none',
                    whiteSpace: title === 'Área total' ? 'nowrap' : 'normal',
                }}
            >
                {value}
            </span>
        </Item>
    )
}
