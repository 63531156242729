import styled from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  height: 89vh;
`
export const ContainerMap = styled.div`
  .floating {
    overflow: hidden;

    .floating-safra {
      position: absolute;
      width: 300px;
      padding: 5px;
      padding-bottom: 0;
      margin: 15px;
      border-radius: 5px;
      background-color: white;
      z-index: 10000;
      right: 0;
    }

    
  }
`
export const GoogleContainer = styled.span`
  .gm-style-iw .gm-style-iw-c {
      z-index: 9999 !important;
  }
`