import _ from 'lodash';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Empty, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

//STYLES
import { TabelaFert } from './style';

//APP
import { OsItensTypes } from '../../../../../../services/Laboratorio';
import { useScreenSize } from '../../../../../../utils/tsx';
import { removerAcentos } from '../../../../../../utils';
import { getWidthTableRow } from './meta';

//COMPONENTS
import Input from '../../../../../../Components/CustomUi/input';
import Carregando from '../../../../../../Components/CustomUi/loading';
import ShapeTalhao from '../../../../../../Components/CustomUi/shapeTalhao';
import ButtonCustom from '../../../../../../Components/ButtonCustom';
import GeneratePdfButton from '../../../../../../Components/relatorio-component';
import { Box } from '../../../../../../Components/CustomUi/box';
import { Gap } from '../../../../../../Components/CustomUi/gap';
import { Inline } from '../../../../../../Components/CustomUi/inline';
import { Render } from '../../../../../../Components/CustomUi/render';
import { RowItem } from '../../../../../../Components/CustomUi/table/rowItem';
import { TitleH2 } from '../../../../../../Components/CustomUi/titleH2';
import { RowTable } from '../../../../../../Components/CustomUi/table/rowTable';
import { BodyTable } from '../../../../../../Components/CustomUi/table/bodyTable';
import { HeaderTable } from '../../../../../../Components/CustomUi/table/headerTable';

//PROPS
interface Props {
  list_mapas: OsItensTypes[]
  handleClickOs: Function
  pointerEvents: 'none' | 'auto'
  loading: boolean
  dados?: any[]
  totais?: any[]
  disable?: boolean
  titleSecundario?: string
}

export const TabelaMapasFertilidade: React.FC<Props> = ({
  list_mapas = [],
  handleClickOs,
  pointerEvents,
  loading,
  dados,
  totais,
  titleSecundario,
  disable,
}) => {
  //CONST
  const { currentWidth } = useScreenSize({})

  //STATES
  const [search_prop_or_talhao, setSearch_prop_or_talhao] = useState<string>('')
  const [new_list_map, setNew_list_map] = useState<OsItensTypes[]>(list_mapas)
  const [sorting_date, setSorting_date] = useState<boolean>(false)
  const [current_sort_date, setCurrent_sort_date] = useState<'asc' | 'desc'>('desc')
  const [sorting_talhao, setSorting_talhao] = useState<boolean>(false)
  const [current_sort_talhao, setCurrent_sort_talhao] = useState<'asc' | 'desc'>('desc')

  useEffect(() => {
    setNew_list_map(list_mapas)
  }, [list_mapas])

  function ordenarPorData(array: OsItensTypes[]) {
    setSorting_talhao(false)
    setSorting_date(true)
    if (current_sort_date === 'asc') {
      setCurrent_sort_date('desc')
    } else {
      setCurrent_sort_date('asc')
    }
    //@ts-ignore
    setNew_list_map(_.orderBy(array, [(item) => new Date(item.data)], [current_sort_date]))
  }

  const ordenarPorNomeTalhao = (array: OsItensTypes[]) => {
    setSorting_date(false)
    setSorting_talhao(true)
    if (current_sort_talhao === 'asc') {
      setCurrent_sort_talhao('desc')
    } else {
      setCurrent_sort_talhao('asc')
    }
    setNew_list_map(_.orderBy(array, ['talhao_nome'], [current_sort_talhao]))
  }

  function searchForMap(buscar_nome: string) {
    const ativoSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      let date_string = `${String(item.data)
        ?.substr(0, 10)
        .split('-')
        .reverse()
        .join('/')} ${String(item.data)?.substr(10, 18)}`
      const safraSemAcentos = removerAcentos(String(item?.['safra_nome']))
      const propriedadeSemAcentos = removerAcentos(String(item?.['propriedade_nome']))
      const talhaoSemAcentos = removerAcentos(String(item?.['talhao_nome']))

      return (
        String(item.cod)?.toLowerCase().includes(ativoSemAcentos.toLowerCase()) ||
        safraSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) ||
        propriedadeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) ||
        date_string.toLowerCase().includes(ativoSemAcentos.toLowerCase()) ||
        talhaoSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) ||
        !buscar_nome
      )
    }
  }

  const list_map_filtered = (new_list_map ?? []).filter(searchForMap(search_prop_or_talhao))

  return (
    <TabelaFert>
      <Gap type='column' value={0} />

      <Box borderless padding={0}>
        {/* <TitleH2>Lista de mapas</TitleH2> */}

        <Inline>
          <Input
            placeholder='Buscar por cod, data, safra, propriedade ou talhão'
            value={search_prop_or_talhao}
            onChange={setSearch_prop_or_talhao}
          />
          <div style={{ marginTop: 3 }}>
            <GeneratePdfButton
              dados={dados}
              title={'Relatório de talhões da safra'}
              totais={totais}
              disable={disable}
              titleSecundario={titleSecundario}
            />
          </div>
        </Inline>

        <HeaderTable
          itens={[
            { valor: 'cod', width: 60.5 },
            {
              valor: 'Data',
              width: '10%',
              onSort: () => {
                ordenarPorData(list_mapas)
              },
              typeSort: current_sort_date,
              sorting: sorting_date,
            },
            { valor: 'Safra', width: '20%' },
            { valor: 'Propriedade', width: 'calc(34.95% - 60px)' },
            {
              valor: 'Talhão',
              width: '26.4%',
              onSort: () => {
                ordenarPorNomeTalhao(list_mapas)
              },
              typeSort: current_sort_talhao,
              sorting: sorting_talhao,
            },
            { valor: 'Ações', width: '8.5%' },
          ]}
        />

        <BodyTable
          style={{
            pointerEvents: pointerEvents,
            height: list_mapas.length > 8 ? 'calc(100vh - 260px)' : 'auto',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginTop: -10,
          }}
        >
          <Render condition={!loading}>
            {list_map_filtered?.map(
              ({
                cod,
                data,
                propriedade_nome,
                safra_nome,
                talhao_nome,
                talhao_kml,
                cliente_nome,
                calcario_propriedade,
                status,
              }) => {
                let isOverflow = list_map_filtered.length > 8
                return (
                  <RowTable
                    key={cod}
                    style={{ height: propriedade_nome.length > 100 ? 'auto' : undefined }}
                    onDoubleClick={() => handleClickOs(cod, cliente_nome, calcario_propriedade)}
                  >
                    <RowItem width={getWidthTableRow(currentWidth, isOverflow).cod}>{cod}</RowItem>
                    <RowItem
                      width={getWidthTableRow(currentWidth, isOverflow).data}
                      sorting={sorting_date}
                    >
                      {String(data)?.substr(0, 10).split('-').reverse().join('/')}
                      {String(data)?.substr(10, 18)}
                    </RowItem>
                    <RowItem width={getWidthTableRow(currentWidth, isOverflow).safra}>
                      {safra_nome}
                    </RowItem>
                    <RowItem width={getWidthTableRow(currentWidth, isOverflow).propriedade}>
                      {propriedade_nome}
                    </RowItem>
                    <RowItem
                      width={getWidthTableRow(currentWidth, isOverflow).talhao}
                      sorting={sorting_talhao}
                    >
                      <Tooltip placement='right' title={talhao_nome}>
                        <div
                          style={{
                            maxWidth: '30ch',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 15,
                          }}
                        >
                          <ShapeTalhao
                            //@ts-ignore
                            coordinates={talhao_kml?.coordenadas}
                            fill='var(--primary-color)'
                          />
                          {talhao_nome}
                        </div>
                      </Tooltip>
                    </RowItem>
                    <RowItem width={getWidthTableRow(currentWidth, isOverflow).acoes}>
                      <ButtonCustom
                        onClick={() => handleClickOs(cod, cliente_nome, calcario_propriedade)}
                        icon={<FontAwesomeIcon icon={faEye} />}
                        disabled={status <= 5}
                      >
                        Ver
                      </ButtonCustom>
                    </RowItem>
                  </RowTable>
                )
              }
            )}
          </Render>

          <Render condition={list_map_filtered.length === 0 && !loading}>
            {search_prop_or_talhao === '' ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<> Nenhum registro disponível!</>}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    {' '}
                    <b>'{search_prop_or_talhao}'</b> não encontrado!
                  </>
                }
              />
            )}
          </Render>

          <Render condition={loading}>
            <Carregando
              animation
              legenda='Carregando mapas de fertilidade...'
              justifyContent='center'
              height={200}
              size='default'
            />
          </Render>
        </BodyTable>
      </Box>
    </TabelaFert>
  )
}
