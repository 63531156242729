import { mascaraOdom, removeMaskValor, tirarAdicionarPonto } from '../../../../services/Afins'

interface TypeFormData {
  nome: string
  modelo: string
  descricao: string
  cod_tipo: number
  cod_marca: number
  status: 1
  condicao_aquisicao: number
  valor: string
  km_inicial: ''
  odom_horimetro: string
  data_aquisicao: string
  placa: string
  renavam: string
  ano: string
  numeracao_crv: string
  numeracao_cla: string
  motorizado: number
  tipo_odom: number
  frota: string
  chassi: string
  serie: string
  ano_modelo: string
  cod_responsavel: number
  tipo_combustivel: number
  engrenagem: any[]
  origem_patrimonio: number
  proprietario_origem: string
  numeracao_motor: string
}

const verificarAlteracoes = (original, atualizado) => {
  return original.map((item) => {
    const itemAtualizado = atualizado.find((atual) => (atual.cod ?? atual.cod_temp) === item.cod);
    return {
      ...item,
      quantidade: itemAtualizado ? item.quantidade_dentes !== itemAtualizado.quantidade_dentes : false,
    };
  });
};


export function verficarNaoSalvo(
  update,
  nome,
  modelo,
  obs,
  tipoCategoriaId,
  categoriaId,
  marcaId,
  condicao,
  numeracao_motor,
  valor,
  odom_horimetro,
  dataAquisição,
  placa,
  renavam,
  anoFabricação,
  anoModelo,
  crv,
  cla,
  tipoMedidorId,
  frota,
  motorizado,
  chassi,
  serie,
  responsavelFrotaId,
  tipoCombustivelId,
  tipoCombustivel,
  tipoOrigem,
  tipoOrigemId,
  proprietario_origem,
  arquivos,
  formDataEntrada,
  formDataSaida,
  formDataRecambio,
  setOpenPopover,
  dadosOriginais,
  onClose,
  loading,
  lista_engrenagens
) {
  const engrenagem = dadosOriginais.engrenagem === undefined ? 0 : lista_engrenagens.length
  

  if (update > 0 && loading === false) {
    if (nome !== dadosOriginais.nome) {
      // console.log('TESTE')
      // setOpenPopover(true)
      return null
    } else if (modelo !== dadosOriginais.modelo) {
      // console.log('TESTE1')
      setOpenPopover(true)
      return null
    } else if (obs !== dadosOriginais.descricao) {
      // console.log('TESTE2')
      setOpenPopover(true)
      return null
    } else if (tipoCategoriaId !== dadosOriginais.cod_tipo) {
      // console.log('TESTE3')
      setOpenPopover(true)
      return null
    } else if (categoriaId !== dadosOriginais.cod_categoria) {
      // console.log('TESTE4')
      setOpenPopover(true)
      return null
    } else if (marcaId !== dadosOriginais.cod_marca) {
      // console.log('TESTE5')
      setOpenPopover(true)
      return null
    } else if (condicao !== dadosOriginais.condicao_aquisicao) {
      // console.log('TESTE6')
      setOpenPopover(true)
      return null
    } else if (numeracao_motor !== dadosOriginais.numeracao_motor) {
      // console.log('TESTE6.5')
      setOpenPopover(true)
      return null
    } else if (valor !== dadosOriginais.valor) {
      // console.log('TESTE7')
      setOpenPopover(true)
      return null
    } else if (mascaraOdom(odom_horimetro) !== dadosOriginais.odom_horimetro) {
      // console.log('TESTE8')
      setOpenPopover(true)
      return null
    } else if (dataAquisição !== dadosOriginais.data_aquisicao) {
      // console.log('TESTE9')
      setOpenPopover(true)
      return null
    } else if (placa !== dadosOriginais.placa) {
      // console.log('TESTE10')
      setOpenPopover(true)
      return null
    } else if (renavam !== dadosOriginais.renavam) {
      // console.log('TESTE11')
      setOpenPopover(true)
      return null
    } else if (anoFabricação !== dadosOriginais.ano) {
      // console.log('TESTE12')
      setOpenPopover(true)
      return null
    } else if (anoModelo !== dadosOriginais.ano_modelo) {
      // console.log('TESTE13')
      setOpenPopover(true)
      return null
    } else if (crv !== dadosOriginais.numeracao_crv) {
      // console.log('TESTE14')
      setOpenPopover(true)
      return null
    } else if (cla !== dadosOriginais.numeracao_cla) {
      // console.log('TESTE15')
      setOpenPopover(true)
      return null
    } else if (tipoMedidorId !== dadosOriginais.tipo_odom) {
      // console.log('TESTE16')
      setOpenPopover(true)
      return null
    } else if (frota !== dadosOriginais.frota) {
      // console.log('TESTE17')
      setOpenPopover(true)
      return null
    } else if (motorizado !== dadosOriginais.motorizado) {
      // console.log('TESTE17.5')
      setOpenPopover(true)
      return null
    } else if (chassi !== dadosOriginais.chassi) {
      // console.log('TESTE18')
      setOpenPopover(true)
      return null
    } else if (serie !== dadosOriginais.serie) {
      // console.log('TESTE19')
      setOpenPopover(true)
      return null
    } else if (responsavelFrotaId !== dadosOriginais.cod_responsavel) {
      // console.log('TESTE20')
      setOpenPopover(true)
      return null
    } else if (tipoCombustivel !== dadosOriginais.tipo_combustivel) {
      // console.log('TESTE21')
      setOpenPopover(true)
      return null
    } else if (tipoOrigem !== dadosOriginais.origem_patrimonio) {
      // console.log('TESTE22')
      setOpenPopover(true)
      return null
    } else if (proprietario_origem !== dadosOriginais.proprietario_origem) {
      // console.log('TESTE23')
      setOpenPopover(true)
      return null
    } else if (engrenagem !== lista_engrenagens.length) {
      // console.log('TESTE24')
      setOpenPopover(true)
      return null
    }  else{
      onClose()
    }
  } else {
    if (nome !== '') {
      setOpenPopover(true)
      return null
    } else if (modelo !== '') {
      setOpenPopover(true)
      return null
    } else if (obs !== '') {
      setOpenPopover(true)
      return null
    } else if (tipoCategoriaId !== null) {
      setOpenPopover(true)
      return null
    } else if (categoriaId !== null) {
      setOpenPopover(true)
      return null
    } else if (marcaId !== null) {
      setOpenPopover(true)
      return null
    } else if (condicao !== null) {
      setOpenPopover(true)
      return null
    } else if (numeracao_motor !== null) {
      setOpenPopover(true)
      return null
    } else if (valor !== '') {
      setOpenPopover(true)
      return null
    } else if (odom_horimetro !== '') {
      setOpenPopover(true)
      return null
    } else if (dataAquisição !== '') {
      setOpenPopover(true)
      return null
    } else if (placa !== '') {
      setOpenPopover(true)
      return null
    } else if (renavam !== '') {
      setOpenPopover(true)
      return null
    } else if (anoFabricação !== '') {
      setOpenPopover(true)
      return null
    } else if (anoModelo !== '') {
      setOpenPopover(true)
      return null
    } else if (crv !== '') {
      setOpenPopover(true)
      return null
    } else if (cla !== '') {
      setOpenPopover(true)
      return null
    } else if (tipoMedidorId !== null) {
      setOpenPopover(true)
      return null
    } else if (frota !== '') {
      setOpenPopover(true)
      return null
    } else if (chassi !== '') {
      setOpenPopover(true)
      return null
    } else if (serie !== '') {
      setOpenPopover(true)
      return null
    } else if (responsavelFrotaId !== null) {
      setOpenPopover(true)
      return null
    } else if (tipoCombustivelId !== null) {
      setOpenPopover(true)
      return null
    } else if (tipoOrigemId !== null) {
      setOpenPopover(true)
      return null
    } else if (proprietario_origem !== '') {
      setOpenPopover(true)
      return null
    } else if (arquivos.length > 0) {
      setOpenPopover(true)
      return null
    } else if (formDataEntrada.length > 0) {
      setOpenPopover(true)
      return null
    } else if (formDataSaida.length > 0) {
      setOpenPopover(true)
      return null
    } else if (formDataRecambio.length > 0) {
      setOpenPopover(true)
      return null
    } else {
      onClose()
    }
  }
}

export function limpar(
  setNome,
  setModelo,
  setChassi,
  setSerie,
  setFrota,
  setDataAquisição,
  setValor,
  setAnoModelo,
  setAnoFabricação,
  setObs,
  setOdomHorimetro,
  setPlaca,
  setRenavam,
  setEngrenagem,
  setFormDataEntrada,
  setFormDataSaida,
  setFormDataRecambio,
  setStatus,
  setCrv,
  setCla,
  setCondicao,
  setNumeracao_motor,
  setTab_selecionada_atual,
  setResponsavelFrota,
  setResponsavelFrotaId,
  setCategoria,
  setCategoriaId,
  setTipoCategoria,
  setTipoCategoriaId,
  setTipoMarca,
  setTipoMarcaId,
  setTab_motorizado,
  setTipoMedidor,
  setTipoMedidorId,
  setTipoOrigem,
  setTipoOrigemId,
  setProprietarioOrigem,
  setTipoCombustivel,
  setTipoCombustivelId,
  setDataCategoria,
  setDataMarca,
  setTipoDataCategorias
) {
  setNome('')
  setModelo('')
  setChassi('')
  setSerie('')
  setFrota('')
  setDataAquisição('')
  setValor('')
  setAnoModelo('')
  setAnoFabricação('')
  setObs('')
  setOdomHorimetro('')
  setPlaca('')
  setRenavam('')
  setEngrenagem([])
  setFormDataEntrada([])
  setFormDataSaida([])
  setFormDataRecambio([])
  setStatus(null)
  setCrv('')
  setCla('')
  setCondicao(null);
  setNumeracao_motor('')
  setTab_selecionada_atual('Categoria')
  setResponsavelFrota('')
  setResponsavelFrotaId(null)
  setCategoria('')
  setCategoriaId(null)
  setTipoCategoria('')
  setTipoCategoriaId(null)
  setTipoMarca('')
  setTipoMarcaId(null)
  setTab_motorizado(false)
  setTipoMedidor('')
  setTipoMedidorId(null)
  setTipoOrigem('')
  setTipoOrigemId(null)
  setProprietarioOrigem('')
  setTipoCombustivel('')
  setTipoCombustivelId(null)
  setDataCategoria([])
  setDataMarca([])
  setTipoDataCategorias([])
}

export const verficarDadosEditarApi = (
  update,
  nome,
  modelo,
  obs,
  tipoCategoriaId,
  categoriaId,
  marcaId,
  condicao,
  numeracao_motor,
  valor,
  odom_horimetro,
  dataAquisição,
  placa,
  renavam,
  anoFabricação,
  anoModelo,
  crv,
  cla,
  tipoMedidorId,
  frota,
  motorizado,
  chassi,
  serie,
  responsavelFrotaId,
  tipoCombustivelId,
  tipoCombustivel,
  tipoOrigem,
  tipoOrigemId,
  proprietario_origem,
  lista_engrenagens,
  dadosOriginais
) => {
  const engrenagem = dadosOriginais.engrenagem === undefined ? 0 : dadosOriginais.engrenagem.length

  if (update > 0) {
    let dadosEditados: Partial<TypeFormData> = {}

    if (nome !== dadosOriginais.nome) {
      // console.log('TESTE')
      dadosEditados.nome = nome
    }
    if (modelo !== dadosOriginais.modelo) {
      // console.log('TESTE1')
      dadosEditados.modelo = modelo
    }
    if (obs !== dadosOriginais.descricao) {
      // console.log('TESTE2')
      dadosEditados.descricao = obs
    }
    if (tipoCategoriaId !== dadosOriginais.cod_tipo) {
      // console.log('TESTE3')
      dadosEditados.cod_tipo = tipoCategoriaId
    }
    if (marcaId !== dadosOriginais.cod_marca) {
      // console.log('TESTE5')
      dadosEditados.cod_marca = marcaId
    }
    if (condicao !== dadosOriginais.condicao_aquisicao) {
      // console.log('TESTE6')
      dadosEditados.condicao_aquisicao = condicao === '1' ? 1 : 2
    }
    if (numeracao_motor !== dadosOriginais.condicao_aquisicao) {
      // console.log('TESTE6')
      dadosEditados.numeracao_motor = numeracao_motor
    }
    if (valor !== dadosOriginais.valor) {
      // console.log('TESTE7')
      dadosEditados.valor = tirarAdicionarPonto(valor)
    }
    if (mascaraOdom(odom_horimetro) !== dadosOriginais.odom_horimetro) {
      // console.log('TESTE8')
      dadosEditados.odom_horimetro = removeMaskValor(odom_horimetro)
    }
    if (dataAquisição !== dadosOriginais.data_aquisicao) {
      // console.log('TESTE9')
      dadosEditados.data_aquisicao = dataAquisição
    }
    if (placa !== dadosOriginais.placa) {
      // console.log('TESTE10')
      dadosEditados.placa = placa
    }
    if (renavam !== dadosOriginais.renavam) {
      // console.log('TESTE11')
      dadosEditados.renavam = renavam
    }
    if (anoFabricação !== dadosOriginais.ano) {
      // console.log('TESTE12')
      dadosEditados.ano = anoFabricação
    }
    if (anoModelo !== dadosOriginais.ano_modelo) {
      // console.log('TESTE13')
      dadosEditados.ano_modelo = anoModelo
    }
    if (crv !== dadosOriginais.numeracao_crv) {
      // console.log('TESTE14')
      dadosEditados.numeracao_crv = crv
    }
    if (cla !== dadosOriginais.numeracao_cla) {
      // console.log('TESTE15')
      dadosEditados.numeracao_cla = cla
    }
    if (tipoMedidorId !== dadosOriginais.tipo_odom) {
      // console.log('TESTE16')
      dadosEditados.tipo_odom = tipoMedidorId
    }
    if (frota !== dadosOriginais.frota) {
      // console.log('TESTE17')
      dadosEditados.frota = frota
    }
    if (motorizado !== dadosOriginais.motorizado) {
      // console.log('TESTE18')
      dadosEditados.motorizado = motorizado
    }
    if (chassi !== dadosOriginais.chassi) {
      // console.log('TESTE18')
      dadosEditados.chassi = chassi
    }
    if (serie !== dadosOriginais.serie) {
      // console.log('TESTE19')
      dadosEditados.serie = serie
    }
    if (responsavelFrotaId !== dadosOriginais.cod_responsavel) {
      // console.log('TESTE20')
      dadosEditados.cod_responsavel = responsavelFrotaId
    }
    if (tipoCombustivel !== dadosOriginais.tipo_combustivel) {
      // console.log('TESTE21')
      dadosEditados.tipo_combustivel = tipoCombustivelId
    }
    if (tipoOrigem !== dadosOriginais.origem_patrimonio) {
      // console.log('TESTE22')
      dadosEditados.origem_patrimonio = tipoOrigemId
    }
    if (proprietario_origem !== dadosOriginais.proprietario_origem) {
      // console.log('TESTE23')
      dadosEditados.proprietario_origem = proprietario_origem
    }
    if (engrenagem !== lista_engrenagens.length) {

      let remover_cod = lista_engrenagens.map(item=> {
        if (item.is_new===false || item.is_new === undefined || item.is_new === null) {
          return {...item}
        }
        return {
          posicao: item.posicao,
          tipo: item.tipo,
          categoria: item.categoria,
          quantidade_dentes: item.quantidade_dentes
        }
      })

      dadosEditados.engrenagem = remover_cod
    }
    return dadosEditados
  }
}
