import { TypeListaEngrenagem } from "../../../../.."


export function listaLateralEntradaPadrao(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria!=='Semente' && item.nome_categoria!=='Fertilizante')
}


export function listaRecambio_Semente(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Semente' && item.nome_posicao==='Recâmbio')
}
export function listaLateralEntrada_01_Semente(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Semente' && item.nome_posicao==='Lateral de Saída (1)')
}
export function listaLateralEntrada_02_Semente(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Semente' && item.nome_posicao==='Lateral de Saída (2)')
}


export function listaRecambio_Fertilizante(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Fertilizante' && item.nome_posicao==='Recâmbio')
}
export function listaLateralEntrada_01_Fertilizante(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Fertilizante' && item.nome_posicao==='Lateral de Saída (1)')
}
export function listaLateralEntrada_02_Fertilizante(lista_engrenagem: TypeListaEngrenagem[]): TypeListaEngrenagem[] {
    return (lista_engrenagem??[]).filter(item=> item.nome_categoria==='Fertilizante' && item.nome_posicao==='Lateral de Saída (2)')
}