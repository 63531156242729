import { AxiosResponse } from 'axios'
import { api } from '../api'

import { Dayjs } from 'dayjs'
import base64 from '../../Components/Base64Geral'
import { localConfig } from '../../Configs/localConfig'

export interface TypesEquipe {
  itens: [EquipeItens]
  total: number
}

export interface EquipeItens {
  acessa_sistema?: number
  cliente_nome?: string
  cod?: number | string
  cod_cliente?: number
  cod_conta?: number
  cod_func?: number | string
  cod_perfil_cliente?: number
  cod_perfil_cliente_geral?: number
  cod_perfil_conta?: number
  nome_perfil_conta?: string
  nome_perfil_cliente_geral?: string
  cliente?: string[]
  cod_consultor?: number
  consultor?: boolean
  lider_equipe?: boolean
  conta_nome?: string
  email?: string
  status?: number
  usuario?: UsuarioEquipe[]
  usuario_nome?: string
  acesso_cliente?: number
  assistente_tecnico?: boolean
  tecnico?: boolean
  /**
   * @params {usuario_tipo} 1 = Consultor; 2 = Técnico; 3 = Assistente Técnico
   */
  usuario_tipo?: 1 | 2 | 3
  clientes?: any[]
}

export interface UsuarioEquipe {
  nome?: string
  acesso_admin?: number
  altera_senha?: number
  calca?: number
  calcado?: number
  camisa?: string
  camiseta?: string
  celular?: string
  cep?: string
  endereco_bairro?: string 
	endereco_numero?: string
  endereco_cidade?: number
  endereco_uf?: number | string
  certidao_nascimento?: string
  cid_nasc?: string
  cidade_nascimento?: string
  estado?: string
  cidade?: string
  cnh?: string
  cnh_categoria?: string
  cnh_validade?: string 
  cod?: number
  cod_cargo?: number
  cod_cidade?: number
  cod_conjuge_naturalidade?: number
  cod_escolaridade?: number
  cod_estado_civil?: number
  cod_laboratorio?: number
  cod_profissao?: number
  cod_uf_rg?: number
  uf_nasc?: number
  complemento?: string
  conjuge?: string
  conjuge_cpf?: string
  conjuge_naturalidade?: string
  conjuge_rg?: string
  cpf?: string
  ctps?: string
  ctps_serie?: string
  desligamento?: string
  diploma?: string
  doc_casamento?: string
  doc_certidao?: string
  doc_cnh?: string
  doc_cpf?: string
  doc_ctps?: string
  doc_diploma?: string
  doc_foto_perfil_url?: string
  doc_foto?: string
  doc_foto_perfil?: string
  doc_reservista?: string
  doc_rg?: string
  doc_sus?: string
  doc_titulo?: string
  email?: string
  endereco?: string
  fone?: string
  fone_conjuge?: string
  fone_mae?: string
  fone_pai?: string
  hobby?: string
  hobby2?: string
  mae?: string
  nacionalidade?: string
  nascimento?: string | Dayjs
  num_plano_saude?: string
  observacoes?: string
  pai?: string
  pis?: string
  plano_saude?: string
  reservista?: string
  rg?: string
  sexo?: string
  status?: number
  sus?: string
  tipo_reservista?: string
  tipo_sanguineo?: string
  tipo_uniao?: string
  titulo_eleitor?: string
  uf_rg?: string
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  acesso?: string
  codCategoria?: number
  codPermissao?: number
  nome?: string
  cod?: number | string
  pagination?: number | -1
  page?: number | 15
  permitirConsultor?: boolean
  permitirTecnico?: boolean
  permitirAssistenteTecnico?: boolean
  permitirLiderEquipe?: boolean
  embed?: string
  doc?: string
  atribuirComoTecnico?: boolean
  tecnicoPermitidoConsultor?: number
  assistenteTecnicoPermitidoConsultor?: number
  usuariosPermitidosVincularConsultor?: number
  usuariosPermitidosVincularLiderEquipe?: number
  responsavel_fertilidade?: boolean
  status?: number
  docType?:
    | 'doc_foto'
    | 'doc_certidao'
    | 'doc_pis'
    | 'doc_reservista'
    | 'doc_cpf'
    | 'doc_rg'
    | 'doc_cnh'
    | 'doc_ctps'
    | 'doc_sus'
    | 'doc_titulo'
}

export const getEquipeConta = async (nomePessoa, codPermissao?: number | '', query = null) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.get(
    `equipe?codConta=${config.conta}&nome=${nomePessoa}&codPermissao=${
      codPermissao === undefined ? '' : codPermissao
    }`,
    { params: { ...query, pagination: -1 } }
  )

  return response
}

export const getEquipe = async (params: TypeParamGet) => {
  const { status = 1 } = params
  const config = localConfig()

  params = {
    codConta: config.conta,
    codCliente: config.codCliente,
    status,
    ...params,
  }

  const response: AxiosResponse<TypesEquipe> = await api.get('/equipe', {
    params,
    headers: { Acesso: params.acesso ? params.acesso : config.acesso },
  })

  return response
}

export const getEquipeFiles = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.get(
    `/equipe/${params.cod}/visualizar/${params.docType}/${params.doc}`,
    {
      responseType: 'blob',
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )
  let fileString
  let url

  if (response.status !== 200) {
    fileString = []
    url = ''
  } else {
    fileString = await base64(response.data)

    // @ts-ignore
    url = URL.createObjectURL(response.data)
  }

  return { fileString, url }
}

export const getEquipeFotoPerfil = async (fotoUrl: string) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.get(fotoUrl, {
    responseType: 'blob',
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })
  let fileString
  let url

  if (response.status !== 200) {
    fileString = []
    url = ''
  } else {
    fileString = await base64(response.data)

    // @ts-ignore
    url = URL.createObjectURL(response.data)
  }

  return { fileString, url }
}

export const postEquipe = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.post('/equipe', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export const postAddEquipe = async ({ cod_usuario }) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.post(
    '/equipe/usuario',
    { cod_usuario },
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}

export const putEquipe = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.post(`/equipe/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export const putEquipeS = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.post(`/equipe/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const delEquipe = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesEquipe> = await api.delete(`/equipe/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
